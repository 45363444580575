import React from "react";
import * as crypto from "crypto";
import * as commons from "./common";
import { Route, Switch } from "react-router";
import { Home } from "./components/Home";
import { Layout } from "./components/Layout";
import Reception from "./components/Reception";
import { ApplicationPaths } from "./components/api-authorization/ApiAuthorizationConstants";
import ApiAuthorizationRoutes from "./components/api-authorization/ApiAuthorizationRoutes";
import AuthorizeRoute from "./components/api-authorization/AuthorizeRoute";
import { EndScreen as MobileEndScreen } from "./components/mobile/Exam/EndScreen";
import { QuestionContents as MobileQuestion } from "./components/mobile/Exam/Question";
import { MobileHome } from "./components/mobile/MobileHome";
import { MobileStandbyScreen } from "./components/mobile/MobileStandbyScreen";
import { EndScreen as PcEndScreen } from "./components/pc/Exam/EndScreen";
import { QuestionContents as PcQuestion } from "./components/pc/Exam/Question";
import { PCHome } from "./components/pc/PCHome";
import { PCStandbyScreen } from "./components/pc/PCStandbyScreen";
import "./custom.css";
import { IAppContext } from "./react-app-env";
// import { FetchData } from './components/FetchData';
// import 'url-search-params-polyfill';
import { isMobile } from "react-device-detect";
import { useHistory, useParams } from "react-router-dom";

import { DeviceCheck } from "./components/DeviceCheck";
import { Tutorial } from "./components/Tutorial";
import { Tutorial2 } from "./components/Tutorial/Tutorial2";
import { TutorialAbout } from "./components/Tutorial/About";
import { TutorialEquipment } from "./components/Tutorial/Equipment";
import { TutorialCheck } from "./components/Tutorial/Check";
import { TutorialCheckPC } from "./components/Tutorial/CheckPC";
import { TutorialCheckMobile } from "./components/Tutorial/CheckMobile";
import { TutorialGuide2 } from "./components/Tutorial/Guide";
import { SampleExam } from "./components/Tutorial/SampleExam";
import { TutorialFinish } from "./components/Tutorial/Finish";
import { TutorialCheckMobileSpeed } from "./components/Tutorial/CheckMobileSpeed";
import { TutorialCheckMobileMic } from "./components/Tutorial/CheckMobileMic";
import { TutorialCheckMobileCamera } from "./components/Tutorial/CheckMobileCamera";

import { MobileDeviceCheck } from "./components/mobile/MobileDeviceCheck";
import { MobileEnvironmentMovie } from "./components/mobile/MobileEnvironmentMovie";
import { MobileTakePhoto } from "./components/mobile/MobileTakePhoto";
import { PCDeviceCheck } from "./components/pc/PCDeviceCheck";
import { useSetupSync } from "./sync";
import { TutorialGuide } from "./components/TutorialGuide";

import { LoginInfo } from "./components/LoginInfo/LoginInfo";

export const AppContext = React.createContext(
    {} as {
        appContext: IAppContext;
        setAppContext: (
            action: (currentContext: IAppContext) => Partial<IAppContext>
        ) => void;
    }
);

function AppContextReducer(
    state: IAppContext,
    action: (currentContext: IAppContext) => Partial<IAppContext>
) {
    const updates = action ? action(state) : {};
    return { ...state, ...updates } as IAppContext;
}

export default function App() {
    // ここでは基本的にはAppContextの設定以外はしない
    const [context, dispatch] = React.useReducer(AppContextReducer, {
        backdropIsopen: false,
    });
    return (
        <AppContext.Provider
            value={{
                appContext: context,
                setAppContext: dispatch,
            }}
        >
            {/*    <StylesProvider
      generateClassName={createGenerateClassName({
        disableGlobal: true
      })}>*/}
            <AppRoot />
            {/* </StylesProvider>*/}
        </AppContext.Provider>
    );
}

/**
 * 各画面を定義
 * @returns
 */
function AppRoot() {
    // 全体の同期処理のセットアップ
    useSetupSync();
    return (
        <Switch>
            <AuthorizeRoute exact path="/" component={Home} />
            {/*<AuthorizeRoute exact path='/logininfo' component={LoginInfo} />*/}

            <Route exact path="/tutorial" component={Tutorial} />
            <Route exact path="/tutorial2" component={Tutorial2} />
            <Route exact path="/tutorial2/about" component={TutorialAbout} />
            <Route
                exact
                path="/tutorial2/equipment"
                component={TutorialEquipment}
            />
            <Route exact path="/tutorial2/check" component={TutorialCheck} />
            <Route
                exact
                path="/tutorial2/check/pc"
                component={TutorialCheckPC}
            />
            <Route
                exact
                path="/tutorial2/check/mobile"
                component={TutorialCheckMobile}
            />
            <Route exact path="/tutorial2/guide" component={TutorialGuide2} />
            <Route exact path="/tutorial2/guide/exam" component={SampleExam} />
            <Route exact path="/tutorial2/finish" component={TutorialFinish} />
            <Route
                exact
                path="/tutorial2/check/mobile/speed"
                component={TutorialCheckMobileSpeed}
            />
            <Route
                exact
                path="/tutorial2/check/mobile/mic"
                component={TutorialCheckMobileMic}
            />
            <Route
                exact
                path="/tutorial2/check/mobile/camera"
                component={TutorialCheckMobileCamera}
            />

            <Route exact path="/tutorial-guide" component={TutorialGuide} />
            <Route exact path="/devicecheck" component={DeviceCheck} />
            <Route exact path="/pc/devicecheck" component={PCDeviceCheck} />
            <Route
                exact
                path="/mobile/devicecheck"
                component={MobileDeviceCheck}
            />

            <Route exact path="/tutorial/home" component={Home} />
            <Route exact path="/tutorial/pc/home" component={PCHome} />
            <Route
                exact
                path="/tutorial/pc/standby-screen"
                component={PCStandbyScreen}
            />
            <Route
                exact
                path="/tutorial/pc/exam/question/:order"
                component={PcQuestion}
            />
            <Route
                exact
                path="/tutorial/pc/exam/end-screen"
                component={PcEndScreen}
            />
            <Route exact path="/tutorial/mobile/home" component={MobileHome} />
            <Route
                exact
                path="/tutorial/mobile/standby-screen"
                component={MobileStandbyScreen}
            />
            <Route
                exact
                path="/tutorial/mobile/exam/question/:order"
                component={MobileQuestion}
            />
            <Route
                exact
                path="/tutorial/mobile/exam/end-screen"
                component={MobileEndScreen}
            />

            <AuthorizeRoute exact path="/pc/home" component={PCHome} />
            <AuthorizeRoute
                exact
                path="/pc/standby-screen"
                component={PCStandbyScreen}
            />
            <AuthorizeRoute
                exact
                path="/pc/exam/question/:order"
                component={PcQuestion}
            />
            <AuthorizeRoute
                exact
                path="/pc/exam/end-screen"
                component={PcEndScreen}
            />
            <AuthorizeRoute exact path="/mobile/home" component={MobileHome} />
            <AuthorizeRoute
                exact
                path="/mobile/standby-screen"
                component={MobileStandbyScreen}
            />
            <AuthorizeRoute
                exact
                path="/mobile/exam/question/:order"
                component={MobileQuestion}
            />
            <AuthorizeRoute
                exact
                path="/mobile/exam/end-screen"
                component={MobileEndScreen}
            />
            <AuthorizeRoute
                exact
                path="/mobile/take-photo"
                component={MobileTakePhoto}
            />
            <AuthorizeRoute
                exact
                path="/mobile/env-movie"
                component={MobileEnvironmentMovie}
            />

            {/* 試験受付 */}
            <Route path="/reception" component={Reception} />

            {/* テスト用 */}
            {/* <AuthorizeRoute path='/fetch-data' component={FetchData} /> */}

            {/* リダイレクト */}
            <Route exact path="/:userId" component={HomeRedirect} />

            <Route
                path={ApplicationPaths.ApiAuthorizationPrefix}
                render={(props) => {
                    return (
                        <Layout
                            viewType={isMobile ? "mobile" : "pc"}
                            width={isMobile ? "90%" : "900px"}
                        >
                            <ApiAuthorizationRoutes {...props} />
                        </Layout>
                    );
                }}
            />
        </Switch>
    );
}

/**
 * リダイレクト用コンポーネント
 */
function HomeRedirect() {
    const history = useHistory();
    const common = commons.useCommon();
    const logoutPath = {
        pathname: `${ApplicationPaths.LogOut}`,
        state: { local: true },
    };
    const params = useParams<{ userId: string }>();

    const ALGORITHM = "aes-256-cbc";
    // 鍵
    const KEY = Buffer.from("12345678901234567890123456789012");

    // 受け取った暗号化文字列をバイナリに変換
    const buff = Buffer.from(params.userId, "base64");

    // iv値である、先頭16byteを取り出す
    const iv = buff.slice(0, 16);

    // iv値以降の、暗号化データを取り出す
    const encData = buff.slice(16);

    // 復号器作成
    const decipher = crypto.createDecipheriv(ALGORITHM, KEY, iv);

    // 暗号化データを復号化
    const decData = decipher.update(encData);

    // 末端処理 ＆ バイナリを文字列に戻す
    const userName = Buffer.concat([decData, decipher.final()]).toString(
        "utf8"
    );

    if (userName != "") {
        if (sessionStorage["reception-code-user-name"] != userName) {
            common.logout();
        } else {
            history.push("/");
        }
        sessionStorage["reception-code-user-name"] = userName;
    } else if (params.userId && params.userId.length > 0) {
        sessionStorage["reception-code-user-name"] = params.userId;
        history.push("/");
    } else {
        sessionStorage.removeItem("reception-code-user-name");
        history.push("/");
    }
    return <div />;
}
