
import React from 'react';
import { Layout } from "../Layout";
import { makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import * as common from "../../common";
import { APIResponse, Exam, learner } from "../../react-app-env";
import { DefaultButton, ButtonContainer, DefaultBackButton } from '../StylesUI/CommonLayouts';
import { initialExam } from '../Home';
import { RichEditor } from '../RichEditor/RichEditor'
import { StartScreen } from '../CommonConponents/StartScreen';


const useStyles = makeStyles((theme: Theme) =>
({
  previewQuestionRoot: {
    flexGrow: 1,
    fontSize: 14,
  },
  startScreenRoot:{
    padding: "0 16px"
  },
  centerContainer: {
    display: "flex",
    justifyContent: "center",
    "& button": {
      fontSize: "12px",
      width: "120px",
      minWidth: "100px"
    },
    "& button:hover": {
      backgroundColor: "#003f71",
      outline:0
    },
    "& .backBtn": {
      marginRight: "20px"
    },
    "& .backBtn:hover": {
      backgroundColor: "#003f71",
      color: "white",
      outline:0
    },
    "& span": {
      fontSize: "24px",
    },
    "& h2": {
      color: "#003f71",
      fontSize: "16px",
      fontWeight: "bold",
      textAlign: "center",
      marginTop: "30px"
    }

  },
  mainTextContainer: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    flexDirection: "column",
    fontSize: "12px",
    marginTop: "30px",
    marginBottom: "60px"
  },
  examNameStyle: {
    color: "#003f71",
    fontSize: "20px",
    fontWeight: "bold",
    textAlign: "center",
    marginTop: "16px"
  }
}));





export function MobileStandbyScreen() {

  // -----共通関数の宣言-----
  const {
    go, // 画面遷移 
    api  // API呼び出し
  } = common.useCommon();



  // -----API-----
  async function getExam(args?: any) {
    return api("/api/l-exam", "GET", args)
  }

  function putExam(args?: any) {
    return api("/api/l-exam", "PUT", args)
  }

  function getLearner(args?: any) {
    return api("/api/l-learner", "GET", args)
  }


  //-----汎用関数の定義-------

  //試験開始状態を更新する
  const putStartProcess = () => {
    putExam({ start: true })
      .then((res: any) => {
        if (res.errorCode !== 20000) {
          common.alertError(res.errorTitle, res.errorDetail);
          return;
        }

        go("/mobile/home");

      })
      .catch((err: any) => {
        alert(common.ResponseMessages.Error_PutExam);
      });
  }



  const countDown = (time: number) => {
    let count = time;
    let nowDate = new Date();

    const endDate = new Date(nowDate.getTime() + time * 1000);
    let id = setInterval(() => {
      count--;
      nowDate = new Date();

      if (count <= 60) {
        setCountTime(count);
        setFlags({ ...flags, secondsFlag: true })
      }
      else setCountTime(Math.ceil(count / 60));

      if (nowDate.getTime() >= endDate.getTime()) {
        clearInterval(id);
        //PC、モバイル両方で待機していた場合にputExamの競合を避けるために2秒多く待機(ひとまずの処置)
        setTimeout(() => {
          startedProcess();
        }, 2000)
      }
    }, 1000)
    return id;
  }



  //試験開始時間を過ぎているかどうか(任意の時用)
  const isAfterStartTime = (startTime: string) => {
    const d1 = new Date();
    const d2 = new Date(startTime);
    const timeDifference = d2.getTime() - d1.getTime();
    if (timeDifference < 0) return true
    return false
  }



  //試験開始状態がDB上で更新されていて、PCサイトで試験開始をしていたら画面遷移
  const startedProcess = async () => {

    let examLoopFlag = true;


    while (examLoopFlag) {

      await getExam()
        .then((res: APIResponse<Exam>) => {
          if (res.errorCode !== 20000) {
            common.alertError(res.errorTitle, res.errorDetail);
            return;
          }


          if (res.value.startStatus === 1 || (res.value.termType === 1 && isAfterStartTime(res.value.executeStart))) {

            examLoopFlag = false;

            getLearner()
              .then((learnerRes: APIResponse<learner>) => {
                if (learnerRes.errorCode !== 20000) {
                  common.alertError(learnerRes.errorTitle, learnerRes.errorDetail);
                  return;
                }

                if (learnerRes.value !== null) {
                  //PCサイトで試験開始ボタンを押しているか
                  if (learnerRes.value.executionStartDatetime !== null) {
                    //試験開始画面がある場合は表示する
                    if(res.value.startViewFlag){
                      setIsShowStartScreen(true);
                    }else{
                      go("/mobile/home");
                    }
                  }
                  else {
                    //putStartProcess();
                    alert("PCサイトで試験を開始した後に再接続してください。");
                    go("/");
                  }
                }
              })
              .catch((err: any) => {
                alert(common.ResponseMessages.Error_GetLearner);
              });

          }


        })
        .catch((err: any) => {
          alert(common.ResponseMessages.Error_GetExam);
        });

    }
  }


  //試験開始画面からHome画面へ戻る処理
  const returnHome = ()=>{
    go("/");
  }


  //-----スタイルの宣言-------
  const classNames = useStyles();





  //----stateの定義---------
  const [flags, setFlags] = React.useState({ startFlag: false, secondsFlag: false });
  const [countTime, setCountTime] = React.useState<number>();
  const [examStateValue, setExamStateValue] = React.useState<Exam>(initialExam);
  const [isShowStartScreen, setIsShowStartScreen] = React.useState(false);



  // -----use effefct-----
  React.useEffect(() => {

    let timerId: any;

    getExam()
      .then((res: APIResponse<Exam>) => {
        if (res.errorCode !== 20000) {
          common.alertError(res.errorTitle, res.errorDetail);
          return;
        }
        if(res.value !== null){
          setExamStateValue(res.value)
          timerId = countDown(res.value.startSeconds);
        }

      })
      .catch((err: any) => {
        alert(common.ResponseMessages.Error_GetExam);
      });

    return () => {
      clearInterval(timerId);
    }


  }, [])




  return (
    <Layout viewType="mobile">
      {isShowStartScreen ?

        // <StartScreen isMobile={true} exam={examStateValue} handleStartClick={()=>{go("/mobile/home")}} returnHome={returnHome}/>
        <div></div>
        
        :
        <div>
          <div className={classNames.centerContainer}>

            <h2 style={{ fontSize: "19px", fontWeight: "bold" }}> 試験開始まで残り&nbsp;<span>{countTime}</span>&nbsp;{flags.secondsFlag ? "秒" : "分"}</h2>

          </div>

          <div className={classNames.mainTextContainer}>

            <p>この試験では問題文をPCで表示し、解答をモバイル端末で行います。</p>
            <p>試験問題は開始時間を過ぎると自動的に表示されます。</p>
            <p>試験開始時間を過ぎても試験問題が表示されない場合は</p>
            <p>「画面を更新する」ボタンを押してください。</p>

          </div>

          <ButtonContainer className={classNames.centerContainer}>
            <DefaultBackButton className="backBtn" onClick={() => { go("/") }}>戻る</DefaultBackButton>

            <DefaultButton onClick={() => { window.location.reload() }}>画面を更新する</DefaultButton>
          </ButtonContainer>

        </div>
      }
    </Layout>
  );
}