import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { AtomicBlockUtils, ContentBlock, convertFromRaw, convertToRaw, DraftBlockType, DraftEditorCommand, DraftHandleValue, DraftInlineStyleType, DraftStyleMap, Editor, EditorState, getDefaultKeyBinding, RawDraftContentState, RichUtils } from 'draft-js';
import React from 'react';
import * as common from "../../common";

const SIZE14 = "16px";
const SIZE16 = "20px";

const useStyles = makeStyles((props: RichEditorProps) => ({
    hidden: {
        display: "none"
    },
    "RichEditor-root": {
        background: "#fff",
        border: "1px solid #ddd",
        fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
        fontSize: common.FONT_SIZE.richEditorRoot,
        padding: "15px",
        textAlign: "left", /* add jouno */
        width: "100%", /* add jouno */
    },
    'RichEditor-blockquote': {

    },
    'RichEditor-editor': {
        borderTop: (props: RichEditorProps) => props.readOnly ? undefined : "1px solid #ddd",
        cursor: (props: RichEditorProps) => props.readOnly ? "auto" : "text",
        fontSize: common.FONT_SIZE.richEditorText,
        marginTop: "10px",
        "& .public-DraftEditorPlaceholder-root": {
            margin: "0 -15px -15px",
            padding: "15px"
        },
        "& .public-DraftEditor-content": {
            margin: "0 -15px -15px",
            padding: "15px",
            minHeight: "100px"
        },
        "& $RichEditor-blockquote": {
            borderLeft: "5px solid #eee",
            color: "#666",
            fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
            fontStyle: "italic",
            margin: "16px 0",
            padding: "10px 20px"
        },
        "& .public-DraftStyleDefault-pre": {
            backgroundColor: "rgba(0, 0, 0, 0.05)",
            fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
            fontSize: common.FONT_SIZE.richEditorText,
            padding: "20px"
        }
    },
    'RichEditor-hidePlaceholder': {
        "& .public-DraftEditorPlaceholder-root": {
            display: "none"
        }
    },
    'RichEditor-controls': {
        fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
        fontSize: common.FONT_SIZE.mainText,
        marginBottom: "5px",
        userSelect: "none"
    },
    'RichEditor-styleButton': {
        color: "#999",
        cursor: "pointer",
        marginRight: "16px",
        padding: "2px 0",
        display: "inline-block"
    },
    'RichEditor-activeButton': {
        color: "#5890ff"
    }
}));

/**
 * プロパティ
 */
export interface RichEditorProps {
    defaultValue?: string;
    onChange?: (value: string) => void;
    readOnly?: boolean;
}

function jsonToEditorState(json: string | undefined) {
    if (!json || json.trim() === "") {
        return EditorState.createEmpty();
    }
    try {
        const raw = JSON.parse(json) as RawDraftContentState;
        return EditorState.createWithContent(convertFromRaw(raw));
    } catch (e) {
        return EditorState.createEmpty();
    }
}

/**
 * 内容をプレインテキストに変換する
 * @param jsonContentValue 
 */
export function toPlainText(jsonContentValue : string){
    if (!jsonContentValue || jsonContentValue.trim() === "") {
        return "";
    }
    try {
        const raw = JSON.parse(jsonContentValue) as RawDraftContentState;
        return raw.blocks.map((block) => {
            return (block?.text?.trim()) ?? "";
        }).join("\n").trim();
    } catch (e) {
        return ""
    }

}

export function RichEditor(props: RichEditorProps) {

    const [editorState, setEditorState] = React.useState(() => {
        // 初期stateを生成
        return jsonToEditorState(props.defaultValue)
    });

    // const { value: propValue } = props;
    // React.useEffect(() => {
    //     if (typeof (propValue) !== "undefined") {
    //         const newState = jsonToEditorState(propValue)
    //         setEditorState(newState);
    //     }
    // }, [propValue])

    // UIでの変更時のハンドラ
    const onChange = React.useCallback((newEditorState: EditorState) => {
        const oldJson  = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
        setEditorState(newEditorState);
        if (typeof (props.onChange) === "function") {
            const json = JSON.stringify(convertToRaw(newEditorState.getCurrentContent()));
            if(json !== oldJson){
                props.onChange(json);
            }            
        }
    }, [props, editorState, setEditorState]);

    const classes = useStyles(props);

    const editor = React.useRef<Editor>(null);

    const focus = () => editor?.current?.focus();

    const handleKeyCommand = (command: DraftEditorCommand, editorState: EditorState, eventTimeStamp: number) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            onChange(newState);
            return "handled" as DraftHandleValue;
        }
        return "not-handled" as DraftHandleValue;
    };

    const mapKeyToEditorCommand = (e: React.KeyboardEvent) => {
        if (e.keyCode === 9 /* TAB */) {
            const newEditorState = RichUtils.onTab(
                e,
                editorState,
                4, /* maxDepth */
            );
            if (newEditorState !== editorState) {
                onChange(newEditorState);
            }
            return null;
        }
        return getDefaultKeyBinding(e);
    };

    const toggleBlockType = (blockType: string) => {
        onChange(
            RichUtils.toggleBlockType(
                editorState,
                blockType
            )
        );
    };

    const toggleInlineStyle = (inlineStyle: string) => {
        onChange(
            RichUtils.toggleInlineStyle(
                editorState,
                inlineStyle
            )
        );
    };

    const handleImageChange = (newEditorState: EditorState, entityKey: string) => {
        onChange(AtomicBlockUtils.insertAtomicBlock(
            newEditorState,
            entityKey,
            ' '
        ));
    };

    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = classes['RichEditor-editor'];
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
        if (contentState.getBlockMap().first().getType() !== 'unstyled') {
            className += ' ' + classes['RichEditor-hidePlaceholder'];
        }
    }

    const getBlockStyle = (block: ContentBlock) => {
        switch (block.getType()) {
            case 'blockquote':
                return classes['RichEditor-blockquote'];
            default:
                return null as unknown as string;
        }
    }


    return (
        <div className={classes["RichEditor-root"]}>
            <Grid container direction="row" wrap="wrap" className={
                props.readOnly ? classes.hidden : undefined
            }>
                <Grid item>
                    <BlockStyleControls
                        classes={classes}
                        editorState={editorState}
                        onToggle={toggleBlockType}
                    />
                </Grid>
                <Grid item>
                    <InlineStyleControls
                        classes={classes}
                        editorState={editorState}
                        onToggle={toggleInlineStyle}
                    />
                </Grid>
                <Grid item>
                    <ImageUploadControls
                        classes={classes}
                        editorState={editorState}
                        onChange={handleImageChange}
                    />
                </Grid>
            </Grid>
            <div className={className} onClick={focus}>
                <Editor
                    blockRendererFn={mediaBlockRenderer}
                    blockStyleFn={getBlockStyle}
                    customStyleMap={styleMap}
                    editorState={editorState}
                    handleKeyCommand={handleKeyCommand}
                    keyBindingFn={mapKeyToEditorCommand}
                    onChange={onChange}
                    placeholder={props.readOnly ? "" : "入力してください"}
                    ref={editor}
                    readOnly={props.readOnly}
                />
            </div>
        </div>
    );
}

// Atomicブロックに対して、カスタムレンダリングを指定する
function mediaBlockRenderer(block: ContentBlock) {
    if (block.getType() === 'atomic') {
        return {
            // 自力実装のMediaコンポーネントを表示する
            component: Media,
            editable: false,
        };
    }

    return null;
}

// 上で使用するMediaコンポーネントの実装
function Media(props: any) {
    // 挿入時に作成したEntityを取得
    const entity = props.contentState.getEntity(
        props.block.getEntityAt(0)
    );
    // Entityに保存していたURLを取得
    const { src } = entity.getData();
    const type = entity.getType();

    // typeに対応するReactコンポーネントを作成して返す
    let media;
    if (type === 'audio') {
        media = <audio controls src={src} />;
    } else if (type === 'image') {
        media = <img src={src} alt="" style={{ maxWidth: "98%", maxHeight: "98%" }} />;
    } else if (type === 'video') {
        media = <video controls src={src} />;
    }

    return media;
}

///////////////

interface IStyleButtonProps {
    key: string;
    label: string;
    style: string;
    onToggle: (style: string) => void;
    active: boolean;
    classes: Record<string, string>;
}
interface IStyleControls {
    editorState: EditorState;
    onToggle: (style: string) => void;
    classes: Record<string, string>;
}

function StyleButton(props: IStyleButtonProps) {
    let className = props.classes['RichEditor-styleButton'];
    if (props.active) {
        className += ' ' + props.classes['RichEditor-activeButton'];
    }
    const onToggle = (e: React.MouseEvent) => {
        e.preventDefault();
        props.onToggle(props.style);
    }
    return (
        <span className={className} onMouseDown={onToggle}>
            {props.label}
        </span>
    );
}

/////////////////////////

// インラインスタイルの上書き
// 参考　https://draftjs.org/docs/advanced-topics-inline-styles/
// 'BOLD' | 'CODE' | 'ITALIC' | 'STRIKETHROUGH' | 'UNDERLINE
const styleMap = {
    CODE: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
        fontSize: SIZE16,
        padding: 2,
    },
    // 参考　https://itsakura.com/css-font-style
    ITALIC: {
        fontStyle: "italic",
        fontFamily: "'Century', serif"
    }
} as DraftStyleMap;

// type CoreDraftBlockType =
//     | 'unstyled'
//     | 'paragraph'
//     | 'header-one'
//     | 'header-two'
//     | 'header-three'
//     | 'header-four'
//     | 'header-five'
//     | 'header-six'
//     | 'unordered-list-item'
//     | 'ordered-list-item'
//     | 'blockquote'
//     | 'code-block'
//     | 'atomic';

// type CustomBlockType = string;

// type DraftBlockType = CoreDraftBlockType | CustomBlockType;

const BLOCK_TYPES: { label: string, style: DraftBlockType }[] = [
    { label: 'H1', style: 'header-one' },
    { label: 'H2', style: 'header-two' },
    { label: 'H3', style: 'header-three' },
    { label: 'H4', style: 'header-four' },
    { label: 'H5', style: 'header-five' },
    { label: 'H6', style: 'header-six' },
    { label: '引用', style: 'blockquote' },
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' },
    { label: 'コード', style: 'code-block' },
];

function BlockStyleControls(props: IStyleControls) {
    const { editorState } = props;
    const selection = editorState.getSelection();
    const blockType: DraftBlockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

    return (
        <div className={props.classes["RichEditor-controls"]}>
            <div>段落スタイル</div>
            {BLOCK_TYPES.map((type) =>
                <StyleButton
                    key={type.label}
                    classes={props.classes}
                    active={type.style === blockType}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            )}
        </div>
    );
};

////////////////////////

// type DraftInlineStyleType = 'BOLD' | 'CODE' | 'ITALIC' | 'STRIKETHROUGH' | 'UNDERLINE';
var INLINE_STYLES: { label: string, style: DraftInlineStyleType }[] = [
    { label: '太字', style: 'BOLD' },
    { label: '斜体', style: 'ITALIC' },
    { label: '下線', style: 'UNDERLINE' },
    { label: '等幅', style: 'CODE' },
];

function InlineStyleControls(props: IStyleControls) {
    const currentStyle = props.editorState.getCurrentInlineStyle();
    return (
        <div className={props.classes["RichEditor-controls"]}>
            <div>インラインスタイル</div>
            {INLINE_STYLES.map((type) =>
                <StyleButton
                    classes={props.classes}
                    key={type.label}
                    active={currentStyle.has(type.style)}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            )}
        </div>
    );
};

function ImageUploadControls(props: {
    editorState: EditorState;
    onChange: (newState: EditorState, entityKey: string) => void;
    classes: Record<string, string>;
}) {
    const handleImageChange = (e: React.ChangeEvent) => {
        const reader = new FileReader();
        const input = e.nativeEvent.target as HTMLInputElement;
        const files = input.files;
        if (files && files.length > 0) {
            // ファイルをデータURLに変換
            reader.readAsDataURL(files[0]);
            reader.onloadend = (ev) => {
                // input側はクリア
                input.value = null as unknown as string;
                // EditorContentを取得
                const contentState = props.editorState.getCurrentContent();
                // EditorContentにEntityを作成（これが画像ファイルについてのデータ）
                const contentStateWithEntity = contentState.createEntity(
                    "image",
                    'IMMUTABLE',
                    { src: reader.result as string }
                );
                // 作成したEntityの一意キーを取得
                const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
                // 作成したEntityを含む新しいEditorStateを作成
                const newEditorState = EditorState.set(
                    props.editorState,
                    { currentContent: contentStateWithEntity }
                );
                // 新しいAtomicBlockを、作成したEntityに紐付けた形で作成し、
                // 現在の選択位置に挿入
                props.onChange(newEditorState, entityKey);
            };
        }
    };
    return (
        <div className={props.classes["RichEditor-controls"]}>
            <div>画像ブロック</div>
            <div>
                <input
                    id="contained-button-file"
                    className={props.classes["hidden"]}
                    accept="image/*"
                    type="file"
                    onChange={handleImageChange}
                />
                <label htmlFor="contained-button-file">
                    <Button variant="contained" color="primary" component="span">
                        Upload
                    </Button>
                </label>
            </div>
        </div>);
}