import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Layout } from "./Layout";
import { useCommon } from '../common';
import { makeTutorialCommonStyles } from './Tutorial';

const useStyles = makeStyles((theme: Theme) =>
({
    main: {
        padding: "20px",
        width: "1000px" /* 横幅は適当です。 */
    },
    guideCon: {
        display: "flex",
        margin: "0 0 40px",
        "&  > div": {
            boxSizing: "border-box"
        },
    },
    guideImg: {
        position: "relative",
        width: "600px", /* 横幅は適当です。 */
        "& img": {
            width: "calc(100% - 2px)",
            border: "solid 1px #cccccc"
        },
    },
    guideText: {
        width: "400px" /* 横幅は適当です。 */
    },
    guideBalloon: {
        position: "relative",
        padding: "40px",
        margin: "20px 0 20px 40px",
        borderRadius: "20px",
        backgroundColor: "#00468b",
        color: "#ffffff",
        fontSize: "16px",
        "&::before": {
            content: "\"\"",
            position: "absolute",
            top: "50%",
            left: "-30px",
            marginTop: "-15px",
            border: "15px solid transparent",
            borderRight: "15px solid #00468b"
        }
    }
}));

const useStyles2 = makeTutorialCommonStyles();

export function TutorialGuide() {
    const classNames = useStyles();
    const classNames2 = useStyles2();
    const cmn = useCommon();
    const {
        go, // 画面遷移
    } = cmn;
    return (
        <Layout
            viewType={isMobile ? "mobile" : "pc"}
            width={isMobile ? "90%" : "1000px"}>
            <div className={classNames2.headerNav + " " + classNames2.imgBtn}>
                <button onClick={() => { go("/tutorial") }}>
                    <span>
                        <img alt="チュートリアルのトップへ戻る"
                            src="/static/images/ico_return.png" />
                    </span>
                    チュートリアルのトップへ戻る
                </button>
            </div>
            <div className={classNames.main}>

                <div className={classNames.guideCon}>
                    <div className={classNames.guideImg}>
                        <img alt="guide" src="/static/images/MPC01-1.png" />
                    </div>
                    <div className={classNames.guideText}>
                        <div className={classNames.guideBalloon}>試験を開始する前に、こちらのQRコードをスマートフォンで読取った後、ご本人写真の撮影、受験環境の周辺撮影を実施してください。</div>
                    </div>
                </div>

                <div className={classNames.guideCon}>
                    <div className={classNames.guideImg}>
                        <img alt="guide" src="/static/images/MPC01-2.png" />
                    </div>
                    <div className={classNames.guideText}>
                        <div className={classNames.guideBalloon}>本人撮影、周辺撮影が終了した後、試験の注意事項に関する動画を視聴してください。視聴確認をチェックした後、「試験開始画面へ」ボタンを押下して試験を開始してください。</div>
                    </div>
                </div>

                <div className={classNames.guideCon}>
                    <div className={classNames.guideImg}>
                        <img alt="guide" src="/static/images/MPC02.png" />
                    </div>
                    <div className={classNames.guideText}>
                        <div className={classNames.guideBalloon}>これは試験開始直前の画面になります。「開始する」ボタンを押下して試験を開始してください。<br />
                            ※試験によっては、この画面は表示されない場合があります。</div>
                    </div>
                </div>

                <div className={classNames.guideCon}>
                    <div className={classNames.guideImg}>
                        <img alt="guide" src="/static/images/MPC03.png" />
                    </div>
                    <div className={classNames.guideText}>
                        <div className={classNames.guideBalloon}>問題文がPCの画面に表示されます。<br />
                            解答はスマートフォン画面から入力してください。</div>
                    </div>
                </div>

                <div className={classNames.guideCon}>
                    <div className={classNames.guideImg}>
                        <img alt="guide" src="/static/images/MPC04.png" />
                    </div>
                    <div className={classNames.guideText}>
                        <div className={classNames.guideBalloon}>PCに表示される問題文と、スマートフォンに表示される選択肢は同期されます。どちらで操作しても、相対する問題文と選択肢が表示されるようになります。</div>
                    </div>
                </div>

                <div className={classNames.guideCon}>
                    <div className={classNames.guideImg}>
                        <img alt="guide" src="/static/images/MPC05.png" />
                    </div>
                    <div className={classNames.guideText}>
                        <div className={classNames.guideBalloon}>試験にもよりますが、いくつかの問題がより上位の問題に属している場合があります。<br />
                            この場合、上位問題に対する解答は存在せず、属する個々の問題に対して解答を実施します。</div>
                    </div>
                </div>

            </div>
            <div className={classNames2.headerNav + " " + classNames2.imgBtn}>
                <button onClick={() => { go("/tutorial") }}>
                    <span>
                        <img alt="チュートリアルのトップへ戻る"
                            src="/static/images/ico_return.png" />
                    </span>
                    チュートリアルのトップへ戻る
                </button>
            </div>
        </Layout>
    );
}