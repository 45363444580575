import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Slide, { SlideProps } from '@material-ui/core/Slide/Slide';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import authService from './AuthorizeService';
import * as common from "../../common";
import {
    isMobile
  } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
    loginMenu: {
        columnGap: "10px",
        alignItems: "center",
        fontSize: common.FONT_SIZE.formText
    },
    nameBox: {
        display: "flex",
        columnGap: "10px",
        alignItems: "center",
        fontSize: common.FONT_SIZE.formText
    },
    menuBox: {

    },
    personIcon: {
        fontWeight: "bold",
        fontSize: "5rem"
    },
    menuIcon: {
        fontWeight: "bold",
        fontSize: isMobile ? "5vw" : "32px" ,
        //fontSize: isMobile ? "8vw" : "30px" ,
    },
    navBtn: {
        width: isMobile ? "18.6vw" : "70px",
        height: isMobile ? "18.67vw" : "70px",
        minHeight:"70px",
        minWidth:"70px",
        backgroundColor: "#003f71",
        cursor: "pointer",
        color: "#ffffff",
        borderRadius: "unset",
        outline: "none",
        "&:focus": {
            outline: "none"
        }
    },
    menuItem: {
        fontSize: isMobile ? common.FONT_SIZE.responsive_16px : "1.6rem",
        fontWeight: "bold"
        }
}));

// const registerPath = `${ApplicationPaths.Register}`;
const loginPath = `${ApplicationPaths.Login}`;
const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };

export function LoginMenu(props: { isMobile?: boolean ,shouldShowUserName: boolean, isTutorial?: boolean, allowResync?: boolean, handleResync?: any }) {
    const classes = useStyles();
    const [state, setState] = React.useState({
        isAuthenticated: false,
        userName: ""
    });
    React.useEffect(() => {
        let mounted = true;
        async function populateState() {
            const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
            if (mounted) {
                setState({
                    isAuthenticated: isAuthenticated,
                    userName: user && user.name
                });
            }
        }
        const subscription = authService.subscribe(() => populateState());
        populateState();
        return () => {
            mounted = false;
            authService.unsubscribe(subscription);
        };
    }, []);

    const [anchorEl, setAnchorEl] = React.useState(null as null | HTMLElement);
    const menuIcon = <MenuIcon className={classes.menuIcon} />;
    const openingMenuIcon = <CloseIcon className={classes.menuIcon} />;
    const [menuBtnIcon, setMenuBtnIcon] = React.useState(menuIcon);

    // メニューを開く
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setMenuBtnIcon(anchorEl ? menuIcon : openingMenuIcon);
    };

    // メニューを閉じる
    const handleClose = () => {
        setAnchorEl(null);
        setMenuBtnIcon(menuIcon);
    };

    let menuItems: JSX.Element[];
    if (state.isAuthenticated) {
        menuItems = [
            <MenuItem key={0} className={classes.menuItem} hidden={props.isMobile} disabled={!props.allowResync}>
                <Link onClick={() => { handleClose(); props.handleResync(); }} component={RouterLink} color="inherit"
                    to="#!">再同期</Link>
            </MenuItem>,
            <MenuItem key={1} className={classes.menuItem} hidden={props.isMobile}>
                <Link onClick={() => document.documentElement.requestFullscreen()} component={RouterLink} color="inherit"
                    to="#!">全画面</Link>
            </MenuItem>,
            <MenuItem key={2} className={classes.menuItem}>
                <Link onClick={handleClose} component={RouterLink} color="inherit"
                    to={logoutPath}>ログアウト</Link>
            </MenuItem>
        ];
    } else {
        menuItems = [
            <MenuItem key={0} className={classes.menuItem} hidden={props.isMobile} disabled={!props.allowResync}>
                <Link onClick={() => { handleClose(); props.handleResync(); }} component={RouterLink} color="inherit"
                    to="#!">再同期</Link>
            </MenuItem>,
            <MenuItem key={1} className={classes.menuItem} hidden={props.isMobile}>
                <Link onClick={() => document.documentElement.requestFullscreen()} component={RouterLink} color="inherit"
                    to="#!">全画面</Link>
            </MenuItem>,
            <MenuItem key={2} className={classes.menuItem} disabled={props.isTutorial}>
                <Link onClick={handleClose} component={RouterLink} color="inherit" to={loginPath}>ログイン</Link>
            </MenuItem>
        ];
    }

    return (
        <Grid className={classes.loginMenu} container>
            { (!props.isMobile) && state.isAuthenticated && state.userName && props.shouldShowUserName ?
                <Grid item className={classes.nameBox}>
                    <PersonIcon className={classes.personIcon} />
                    {state.userName}
                </Grid> : undefined
            }
            <Grid item className={classes.menuBox}>
                <IconButton onClick={handleClick} className={classes.navBtn} aria-label="menu">
                    {menuBtnIcon}
                </IconButton>
                <Menu
                    getContentAnchorEl={null}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    TransitionProps={{
                        direction: "left"
                    } as SlideProps}
                    TransitionComponent={Slide}
                >
                    {
                        menuItems
                    }
                </Menu>
            </Grid>
        </Grid>
    );
}