
import React from 'react';

import { Box } from '@material-ui/core';
import { RichEditor } from '../../RichEditor/RichEditor'
import Button from '@material-ui/core/Button';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import * as common from "../../../common";
import { Exam, hiddenFlags } from "../../../react-app-env";
import { DefaultButton, ButtonContainer, DefaultBackButton } from '../../StylesUI/CommonLayouts';


const useStyles = makeStyles((theme: Theme) =>
({
  endScreenRoot: {
    padding: "0 4.27vw"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: common.FONT_SIZE.responsive_20px,
    "& Button": {
      padding: "1.8vw",
      fontSize: common.FONT_SIZE.responsive_12px,
      width: "45%"
    },
    "& button:hover": {
      backgroundColor: "#003f71",
      outline:0
    },
    "& .backBtn:hover": {
      backgroundColor: "#003f71",
      color: "white",
      outline:0
    },
    "& svg":{
        fontSize:common.FONT_SIZE.responsive_14px
    }
  },
  textBoxStyle: {
    "& h3": {
      fontSize: common.FONT_SIZE.responsive_20px,
      color: "#003f71",
      fontWeight: "bold",
      textAlign: "center",
      marginTop: common.FONT_SIZE.responsive_16px,
      marginBottom: common.FONT_SIZE.responsive_20px
    },
    "& span":{
      fontSize:common.FONT_SIZE.responsive_18px
    }
  },
  warningText:{
    fontWeight: "bold",
    color:"red",
    fontSize:common.FONT_SIZE.responsive_16px,
    margin: "0 auto",
    textAlign: "center"
  }

}));



export function EndScreen(props: { isTested: boolean, isFinishedTest: boolean, exam: Exam, hiddenFlags: hiddenFlags, handleReturnClick: any, handleEndClickOnEndScreen: any, handleEndClickAfterTested: any }) {

  //-----スタイルの宣言-------
  const classNames = useStyles();

  return (

    <div className={classNames.endScreenRoot}>

      <Box marginBottom={2} className={classNames.textBoxStyle}>
        <h3>{props.exam.name}</h3>
      </Box>

      <Box marginBottom={4} className={classNames.textBoxStyle}>
        <RichEditor defaultValue={props.exam.endBodyHtml} readOnly={true} />
      </Box>

      <Box marginBottom={4}>
        <p className={classNames.warningText}>※一度解答を終了すると再解答は出来ません</p>
      </Box>

      <ButtonContainer className={classNames.buttonContainer}>

        <DefaultBackButton className="backBtn" hidden={props.hiddenFlags.return || props.isFinishedTest} style={{ marginRight: "8px" }} onClick={props.handleReturnClick}><ArrowBackIosIcon /><span>&nbsp;解答へ戻る</span></DefaultBackButton>

        <DefaultButton onClick={props.isTested ? props.handleEndClickAfterTested : props.handleEndClickOnEndScreen}><CheckCircleOutlineIcon /><span>&nbsp;試験を終了する</span></DefaultButton>

      </ButtonContainer>

    </div>

  );
}