import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as common from "../../common";
import { checkSpeed } from '../../common';
import { APIResponse, ICheckInfo, ISasData } from "../../react-app-env";
import { IMovieBlobData } from './Exam/RecordingScreen';

const min_vol = 1;

export default function useMobileDeviceCheck() {
    const cmn = common.useCommon();
    const {
        go, // 画面遷移 
        api,  // API呼び出し
        backgroud_api,
        withLoading,
    } = cmn;
    const [info, setInfo] = React.useState<ICheckInfo>(() => {
        return {
            checkid: uuidv4(),
            type: "mobile",
            ua: navigator.userAgent,
            js: true,
            checked_cam: false,
            checked_mic: false,
            checked_screen: false,
            checked_speed: false
        };
    });

    const ONE_MB = 1024 * 1024;
    async function getSasData() {
        const res: APIResponse<ISasData> = await backgroud_api("/api/l-devicecheck", "GET");
        return res.value;
    }
    const sp_download_mb = (info.sp_download ?? 0) / ONE_MB;
    const sp_upload_mb = (info.sp_upload ?? 0) / ONE_MB;
    const [up_progress_val, set_up_progress_val] = React.useState(0);
    const [dl_progress_val, set_dl_progress_val] = React.useState(0);

    const intervalIdRef = React.useRef(0);
    const volRef = React.useRef(0);
    const micDeviceIdRef = React.useRef("");
    const [micChecking, setMicChecking] = React.useState(false);

    // recorder ref
    const mrRef = React.useRef<MediaRecorder | null>();
    const [camChecking, setCamChecking] = React.useState(false);

    async function micCheckBtnHandler() {
        if (micChecking) {
            window.clearInterval(intervalIdRef.current);
            setMicChecking(false);
            const devices = await navigator.mediaDevices.enumerateDevices();
            const d = devices.find((x) => { return x.deviceId == micDeviceIdRef.current });
            setInfo(_info => {
                return {
                    ..._info,
                    mic: volRef.current >= min_vol ? d?.label : "",
                    checked_mic: true
                } as ICheckInfo;
            });
        } else {
            setMicChecking(true);
            common.checkMicInput((id, deviceId, vol) => {
                intervalIdRef.current = id;
                volRef.current = vol;
                micDeviceIdRef.current = deviceId;
            });
        }
    }

    React.useEffect(() => {
        return () => {
            window.clearInterval(intervalIdRef.current);
        };
    }, []);

    return {
        isValidSpeed: sp_upload_mb >= 0.512 && sp_download_mb >= 0.768,
        isSpeedChecked: info.checked_speed,
        sp_download_mb: sp_download_mb.toFixed(2),
        sp_upload_mb: sp_upload_mb.toFixed(2),
        up_progress_val,
        dl_progress_val,
        micChecking,
        micLabel: info.mic,
        isValidMic: info.mic && info.mic.length > 0,
        isMicChecked: info.checked_mic,
        camChecking,
        isValidCam: info.cam && info.cam.length > 0,
        camLabel: info.cam,
        isCamChecked: info.checked_cam,
        onReturnDeviceCheck: () => { go("/devicecheck"); },
        onSaveDeviceCheckResult: async () => {
            try {
                await withLoading(common.saveDeviceCheckResult(cmn, info));
                alert("検証結果を保存しました。\n以上でモバイル端末の検証は完了です。");
            } catch (err) {
                common.alertError("保存に失敗しました。", "時間を置いて再度実行してください。");
            }
        },
        onStartSpeedCheck: async () => {
            set_dl_progress_val(0);
            set_up_progress_val(0);
            setInfo(_info => {
                return {
                    ..._info,
                    checked_speed: false
                };
            });
            const { speedInfo, clientIP } = await withLoading(
                checkSpeed(getSasData,
                    (up_progress) => {
                        const { loadedBytes, allBytes } = up_progress;
                        set_up_progress_val(loadedBytes / allBytes * 100);
                    }, (dl_progress) => {
                        const { loadedBytes, allBytes } = dl_progress;
                        set_dl_progress_val(loadedBytes / allBytes * 100);
                    })
            );
            if (!speedInfo) {
                // なんらかのエラー
                return;
            }
            setInfo(_info => {
                const newInfo: ICheckInfo = {
                    ..._info,
                    ip: clientIP,
                    sp_upload: speedInfo.sp_upload_az,
                    sp_download: speedInfo.sp_download_az,
                    checked_speed: true
                };
                // console.log(newInfo);
                return newInfo;
            });
        },
        onStartMicCheck: () => {
            if (micChecking || window.confirm(
                "マイクを有効にし、数秒間マイクに対して声を出してから\n終了させてください。")) {
                micCheckBtnHandler();
            }
        },
        recordingCallback: (
            mr: MediaRecorder | null | undefined,
            data?: IMovieBlobData,
            mime?: string,
            ext?: string) => {
            mrRef.current = mr;
            if (data) {
                setInfo(x => {
                    const dev = mr?.stream.getVideoTracks()[0];
                    return {
                        ...x,
                        cam: dev?.label,
                        checked_cam: true
                    };
                })
            }
        },
        onStartCamCheck: async () => {
            if (camChecking) {
                if (mrRef.current?.state == "recording") {
                    mrRef.current?.stop();
                }
                setCamChecking(false);
            } else {
                setCamChecking(true);
            }
        }
    };
}