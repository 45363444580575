import React from "react";
import { isMobile } from 'react-device-detect';
import { makeStyles, Theme } from '@material-ui/core/styles';
import * as common from "../../common";
import { Layout } from "../Layout";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { DefaultButton } from '../StylesUI/CommonLayouts';
import { initFakeData } from '../../fakeapi';

const useStyles = makeStyles((theme: Theme) =>
({
  mobileButtonContainer: {
    paddingTop: common.FONT_SIZE.responsive_24px,
    textAlign: "center",
    "& button": {
      padding: "0.4vw 4vw",
      fontSize: common.FONT_SIZE.responsive_16px,
      minWidth: "50vw",
      borderRadius: "5.3vw"
    },
    "& button:hover": {
      backgroundColor: "#003f71",
    }
  }
}));

export function SampleExam() {

  const {
    go,
    setAppContext
  } = common.useCommon();

  const classNames = useStyles();

  const [isMobileFlag, setIsMobileFlag] = React.useState<boolean>(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);


  React.useEffect(() => {
    setIsMobileFlag(isMobile);

    // fake mode 開始
    let token = "";
    const param_token = searchParams.get("token");

    if (isMobile) {
      // mobile側ならPC側から渡ったパラメータ
      token = param_token ?? "";
    } else {
      // PC側ならsync_token生成
      token = uuidv4();
    }
    setAppContext(c => {
      // 接続を切り替える
      let signalr_conn: any;
      if (c.signalr_conn?.baseUrl.endsWith("/synchub")) {
        try {
          c.signalr_conn.stop();
        } catch (err) {

        }
        signalr_conn = undefined;
      } else {
        signalr_conn = c.signalr_conn;
      }
      return {
        ...c,
        fakeapi_mode: true,
        fake_data: initFakeData(),
        fake_synctoken: token,
        signalr_conn
      };
    });
    if ((isMobile)) {
      // fake mode 設定のためモバイルではボタン押下時に遷移する
    }
    else {
      go("/tutorial/home");
    }

  }, []);

  return (
    <Layout viewType={isMobileFlag ? "mobile" : "pc"} width={isMobileFlag ? "90%" : "900px"} isTutorial={true}>
      <div className={classNames.mobileButtonContainer}>
        <DefaultButton hidden={!isMobileFlag} onClick={() => { go("/tutorial/home") }}>
          サンプル試験を開始する
        </DefaultButton>
      </div>
    </Layout>
  )
}