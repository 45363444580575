import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import React, { useContext } from 'react';
import ReactLoading from 'react-loading';
import * as common from "../../common";
import { ExtCode, IUseCommon, InLogsCode } from '../../common';
import { APIResponse, Choice, Exam, ExamQuestions, ExpansionExamQuestions, Figure, hiddenFlags, IActiveState, IInLogs, ITimeState, learner, PostQuestionArgs, Question } from "../../react-app-env";
import { initialExam } from '../Home';
import { Layout } from "../Layout";
import { ButtonContainer, DefaultBackButton, DefaultButton } from '../StylesUI/CommonLayouts';
import { CustomModal } from "../StylesUI/CustomModal";
import { EndScreen } from './Exam/EndScreen';
import { QuestionContents } from './Exam/Question';
import { RecordingScreen } from './Exam/RecordingScreen';
import { isMobile } from 'react-device-detect';
import { sendSync, useSync } from '../../sync';
import { QueueSharp } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
({
    mobileHomeRoot: {
        fontSize: common.FONT_SIZE.responsive_14px
    },
    sideBarTextStyle: {
        padding: "2.1vw",
        paddingLeft: common.FONT_SIZE.responsive_16px,
        fontSize: common.FONT_SIZE.responsive_14px
    },
    activePage: {
        background: "#7fb911",
        color: "white"
    },
    notActivePage: {
        "& > :hover": {
            background: "rgba(127,185,17,.3)",
        }
    },
    readPage: {
        background: "#FF0000"
    },
    answeredPage: {
        background: "rgba(100, 149, 237, 1)"
    },
    readOnlyPage: {
        background: "#808080",
    },
    rowContainer: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        marginBottom: common.FONT_SIZE.responsive_14px,
        "& Button": {
            display: "inline-block",
            verticalAlign: "middle",
            padding: "0.5vw 4.2vw 0.5vw",
            backgroundColor: "#b7cde6",
            boxShadow: "4px 4px 8px rgb(0 0 0 / 15%) inset",
            fontSize: "4vw",
            color: "black",
            height: "16vw",
            borderRadius: "7px"
        },
        "& button:hover": {
            backgroundColor: "#003f71",
            color: "white",
        },
        "& #finishBtn": {
            marginBottom: "20px"
        }
    },
    listStyle: {
        maxHeight: "80vw",
        overflow: "auto",
        border: "0.2px solid",
        width: '100%',
        "& .MuiListItemText-primary": {
            fontSize: common.FONT_SIZE.responsive_12px,
        }
    },
    remainingTime: {
        display: "inline-block",
        fontSize: common.FONT_SIZE.responsive_18px,
        fontWeight: "bold",
        color: "#003f71",
        textShadow: "1px 1px 2px #ffffff, 1px -1px 2px #ffffff, -1px 1px 2px #ffffff, -1px -1px 2px #ffffff"
    },
    remainingTimeBox: {
        display: "inline-block",
        verticalAlign: "middle",
        padding: "0.5vw 4.2vw 0.5vw",
        marginBottom: "5px",
        backgroundColor: "#b7cde6",
        boxShadow: " 4px 4px 8px rgb(0 0 0 / 15%) inset",
        fontSize: common.FONT_SIZE.responsive_12px,
        color: "#003f71",
        height: "8vw",
        borderRadius: "22px"
    },
    headerBox: {
        background: "radial-gradient(ellipse at center bottom, rgba(0,137,190,1) 0%,rgba(5,49,113,1) 100%)",
        padding: "2.6vw"
    },
    mobileButtonContainer: {
        display: "flex",
        justifyContent: "center",
        "& button": {
            fontSize: common.FONT_SIZE.responsive_14px,
            minWidth: "53vw",
            borderRadius: "20px"
        },
        "& .backBtn:hover": {
            backgroundColor: "#003f71",
            color: "white"
        }
    },
    mobileModalBtnContainer: {
        display: "flex",
        justifyContent: "center",
        "& button": {
            fontSize: common.FONT_SIZE.responsive_14px,
            width: "26vw",
            borderRadius: "10px"
        },
        "& .backBtn:hover": {
            backgroundColor: "#003f71",
            color: "white"
        }
    },
    modalText: {
        "& #simple-modal-title": {
            fontWeight: "bold",
            fontSize: common.FONT_SIZE.responsive_16px,
            margin: "1rem auto 0",
            textAlign: "center"
        },
        "& #warning": {
            fontWeight: "bold",
            color: "red",
            fontSize: common.FONT_SIZE.responsive_14px,
            margin: "1rem auto 3rem",
            textAlign: "center"
        }
    },
    loadingStyle: {
        margin: "auto"
    },
    finishedModalText: {
        "& .modaltext": {
            fontSize: common.FONT_SIZE.responsive_14px,
            fontWeight: "bold",
            margin: "1rem auto",
            textAlign: "center"
        }
    }

}));

interface IModalState {
    // 開いているか
    isOpen: boolean;
    // どのモーダルダイアログを開くか
    modalType: "leave" | "return_leave" | "end" | "timeout";
    // OKボタンで実行する関数
    onOKFunc?: Function;
}

interface IMobileHomeState {
    examStateValue: Exam;
    questionsArray: ExpansionExamQuestions[];
}

const MobileHomeContext = React.createContext<{
    state: IMobileHomeState;
    activeState: IActiveState;
    timeState: ITimeState;
    isLeave: boolean;
    isTested: boolean;
    isOpenedList: boolean;
}>(undefined as unknown as {
    state: IMobileHomeState;
    activeState: IActiveState;
    timeState: ITimeState;
    isLeave: boolean;
    isTested: boolean;
    isOpenedList: boolean;
});

// -----API-----

function go(c: IUseCommon, path: string) {
    c.go(path);
}

async function getExam(c: IUseCommon, args?: any) {
    return c.api<any, APIResponse<Exam>>("/api/l-exam", "GET", args);
}

//任意開始タイプの試験で、試験開始、終了状態を更新する
async function putExam(c: IUseCommon, args: { end: boolean, encUserName: string }) {
    return c.api<any, APIResponse<void>>("/api/l-exam", "PUT", args);
}

//任意開始タイプの試験で、受験者のIPアドレスとUA情報を更新する
async function putLearnerIP(c: IUseCommon, args: { start: boolean, SPStartFlag: boolean }) {
    return c.api<any, APIResponse<void>>("/api/l-exam", "PUT", args);
}

//question一個だけ取得
async function getQuestion(c: IUseCommon, args: { page?: number; id?: number }) {
    return c.api<any, APIResponse<Question>>("/api/l-question", "GET", args);
}

//解答を保存
/*async function postQuestion(c: IUseCommon, args?: PostQuestionArgs) {
    return c.api("/api/l-question", "POST", args);
}*/
async function postQuestion(c: IUseCommon, args?: PostQuestionArgs, bBackground = false) {
    if (bBackground) {
        return c.backgroud_api("/api/l-question", "POST", args);
    }
    return c.api("/api/l-question", "POST", args);
}

async function getLearner(c: IUseCommon, args?: any) {
    return c.api<any, APIResponse<learner>>("/api/l-learner", "GET", args);
}

// 退席状態更新
async function postLeave(c: IUseCommon, leave: boolean) {
    return c.api<any, APIResponse<null>>("/api/l-leave", "POST", { leave });
}

// 不正を登録
async function postCheat(c: IUseCommon, args?: any) {
    //return c.api("/api/l-cheat_log", "POST", args);
    return c.backgroud_api("/api/l-cheat_log", "POST", args);
}

// 内部ログを登録
async function postInLogs(c: IUseCommon, logs: IInLogs[]) {
    //return c.api("/api/l-in_logs", "POST", logs);
    return c.backgroud_api("/api/l-in_logs", "POST", logs);
}

/**
 * モバイル用試験ページ
 */
export function MobileHome() {

    // -----共通関数の宣言-----
    const c = common.useCommon();

    //-----スタイルの宣言-------
    const classNames = useStyles();

    // 既定値生成
    const {
        initialExamQuestion,
        initialExamQuestions,
        initialQuestion
    } = React.useMemo(() => { return getInitialValues(); }, []);

    // -----state-----
    /**
     * 受験中の試験情報を表すステート
     */
    const [state, setState] = React.useState<IMobileHomeState>({
        examStateValue: initialExam,
        questionsArray: initialExamQuestions
    });

    /**
     * 表示中の問題を表すステート
     */
    const [activeState, setActiveState] = React.useState<IActiveState>({
        question: initialQuestion,
        contents: "",
        itemKey: 0
    });

    /***
     * 残り時間を表すステート
     */
    const [timeState, setTimeState] = React.useState<ITimeState>({
        timeUpdateTime: 0,
        remainingTimeMS: 0,
        remainingTime: 0,
    });

    //試験を受験済みかどうか
    const [isTested, setIsTested] = React.useState<boolean>(false);
    // 退席中かどうか
    const [isLeave, setIsLeave] = React.useState<boolean>(false);
    // 問題一覧が開いているか
    const [isOpenedList, setIsOpenedList] = React.useState<boolean>(false);

    // 問題変更中等の処理中フラグ
    const [isProcessing, setIsProcessing] = React.useState<boolean>(false);

    /**
     * 包含するコンポーネントと共有する値を設定
     */
    const contextValue = React.useMemo(() => {
        return {
            state,
            activeState,
            timeState,
            isTested,
            isLeave,
            isOpenedList
        };
    }, [state, activeState, timeState, isTested, isLeave, isOpenedList]);

    // 処理中画面
    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    //ブラウザがスリープ状態から復帰した際に再レンダリングし、カウントダウンを正しい数値にリセット
    const [isHiddenPage, setIsHiddenPage] = React.useState<boolean>(false);

    //モーダル状態
    const [modalState, setModalState] = React.useState<IModalState>({
        // 開いているか
        isOpen: false,
        // どのモーダルダイアログを開くか
        modalType: "end" as "leave" | "return_leave" | "end" | "timeout",
        // OKボタンで実行する関数
        onOKFunc: undefined as Function | undefined
    });

    // useSync処理中
    const [isProcessingUseSync, setIsProcessingUseSync] = React.useState<boolean>(false);



    //問題の既読処理
    const questionReadProcess = async (pageNum: number, examStateValue: Exam) => {
        //表示する問題がセクション開始画面以外で、未読の場合に既読処理をする
        const { initialExamQuestion } = getInitialValues();
        let question: ExamQuestions = initialExamQuestion;
        for (const q of examStateValue.questions) {
            if (q.page === pageNum) {
                question = q;
            }
        }
        if (!question.sectionFlag && question.answerStatus === 0 && !isTested) {
            // セクションでない未読の問題なら既読にする
            // 選択肢は未選択で初期化される
            await setQuestionRead(c, question.id);
        }

    }

    /**
     * サーバーに記録されている既読情報を反映させるために
     * 試験データを取得し直す
     */
    async function updateExamData(c: IUseCommon) {
        try {
            const res = await getExam(c);
            if (res.errorCode !== 20000) {
                common.alertError(res.errorTitle, res.errorDetail);
                return;
            }
            if (res.value !== null) {
                const resExam = res.value;
                setState({
                    ...state,
                    examStateValue: resExam,
                    questionsArray: convertExamQuestions(resExam.questions)
                });
                return resExam;
            }
        } catch (err) {
            alert(common.ResponseMessages.Error_GetExam);
        }
    }

    //アクティブな問題を更新
    async function changeActiveQuestion({
        c, pageNum, examStateValue, contents
    }: {
        c: IUseCommon;
        pageNum: number;
        examStateValue: Exam;
        contents: "Questions" | "end-screen" | ""
    }): Promise<void> {

        try {
            //終了画面に遷移した場合なにもしない
            if (pageNum === examStateValue.pageMax - 1 && examStateValue.endViewFlag) {
                setActiveState(s => {
                    return {
                        ...s,
                        contents,
                        itemKey: pageNum
                    };
                });
                return;
            }

            const page = examStateValue.startViewFlag ? pageNum - 1 : pageNum;

            const resQuestion = await getQuestion(c, { page: pageNum });
            if (resQuestion.errorCode !== 20000) {
                common.alertError(resQuestion.errorTitle, resQuestion.errorDetail);
                return;
            }

            if (resQuestion.value !== null) {

                //表示する問題がセクション開始画面以外で、未読の場合に既読処理をする
                // const { initialExamQuestion } = getInitialValues();
                // let question: ExamQuestions = initialExamQuestion;
                // for (const q of examStateValue.questions) {
                //   if (q.page === pageNum) {
                //     question = q;
                //   }
                // }
                // if (!question.sectionFlag && question.answerStatus === 0 && !isTested) {
                //   // セクションでない未読の問題なら既読にする
                //   // 選択肢は未選択で初期化される
                //   await setQuestionRead(c, question.id);
                // }

                setActiveState(s => {
                    return {
                        question: resQuestion.value,
                        contents,
                        itemKey: pageNum
                    };
                });

                if (resQuestion.value.answerType < 2 && resQuestion.value.choices !== null) {
                    //過去に解答済みの選択肢情報を取り出して記録
                    const choices = resQuestion.value.choices;
                    let newQuestionsArray = convertExamQuestions(examStateValue.questions);

                    for (const choice of choices) {

                        if (choice.selected) {

                            if (!newQuestionsArray.find(q => q.id === resQuestion.value.id)?.selectedChoices.includes(choice.id)) {
                                newQuestionsArray.find(q => q.id === resQuestion.value.id)?.selectedChoices.push(choice.id);
                            }

                        }
                    }

                    setState({
                        examStateValue: examStateValue,
                        questionsArray: newQuestionsArray
                    });

                }

                if (resQuestion.value.answerType > 1 && resQuestion.value.answerText !== null && resQuestion.value.answerText !== "") {
                    //過去に解答済みの入力情報を取り出して記録
                    const initialText = resQuestion.value.answerText;
                    let newQuestionsArray = convertExamQuestions(examStateValue.questions);
                    let newQuestion = newQuestionsArray.find(q => q.id === resQuestion.value.id);

                    if (newQuestion) {
                        newQuestion.answerText = initialText;
                    }

                    setState({
                        examStateValue: examStateValue,
                        questionsArray: newQuestionsArray
                    });

                }

            }
        } catch (err) {
            alert(common.ResponseMessages.Error_GetQuestion);
        }
    }



    // -----Handler-----
    //選択肢クリック時の処理
    const handleChange = async (choiceId: number) => {
        const questions = getQuestionsArray({ activeState, state, choiceId });
        setState({ ...state, questionsArray: questions });

        // 退席時に解答保存されない不具合を修正したため削除
        // モバイルの自動消灯はここでは考慮しない
        /*
            // 保存処理(PCでの途中退席・モバイルの自動消灯考慮)
            const answerTextData = state.questionsArray[state.examStateValue.startViewFlag ? activeState.itemKey - 1 : activeState.itemKey].answerText ?? '';
            const data: PostQuestionArgs = {
                id: activeState.question.id,
                choices: state.questionsArray[state.examStateValue.startViewFlag ? activeState.itemKey - 1 : activeState.itemKey]
                    .selectedChoices.map(id => (
                        { choiceId: id }
                    )),
                answerText: answerTextData
            }
            // 選択肢が未選択,解答テキストが未入力の時では保存しない
            if (data.choices.length !== 0 || data.answerText !== "") {
                await autoSaveAnswer(
                    c,
                    data,
                    false,
                    state.examStateValue.endViewFlag,
                    isTested,
                    activeState.question
                );
            }
        */
    }

    // 解答テキスト入力時の処理
    const handleTextChange = async (inputText: string) => {

        let questions: ExpansionExamQuestions[] = [];

        questions = state.questionsArray.map((question, index) => {
            if (question.id === activeState.question.id) {
                question.answerText = inputText;
                return question
            }
            else return question
        })

        setState({ ...state, questionsArray: questions });

        // 退席時に解答保存されない不具合を修正したため削除
        // モバイルの自動消灯はここでは考慮しない
        /*
          // 保存処理(PCでの途中退席・モバイルの自動消灯考慮)
          const answerTextData = state.questionsArray[state.examStateValue.startViewFlag ? activeState.itemKey - 1 : activeState.itemKey].answerText ?? '';
          const data: PostQuestionArgs = {
              id: activeState.question.id,
              choices: state.questionsArray[state.examStateValue.startViewFlag ? activeState.itemKey - 1 : activeState.itemKey]
                  .selectedChoices.map(id => (
                      { choiceId: id }
                  )),
              answerText: answerTextData
          }
          // 選択肢が未選択,解答テキストが未入力の時では保存しない
          if (data.choices.length !== 0 || data.answerText !== "") {
            await autoSaveAnswer(
                c,
                data,
                false,
                state.examStateValue.endViewFlag,
                isTested,
                activeState.question
            );
          }
        */
    }

    //[次の問題へ]ボタンクリック時の処理
    const handleNextClick = async (data: PostQuestionArgs) => {

        // 内部ログ登録用の配列
        let inLogDatas: IInLogs[] = [makeInLogsData({ errorCode: 20000 }, InLogsCode.action, "「次へ」ボタン")];

        if (!isTested) {
            //試験残り時間が無くなったか判定、その場合の処理
            if (isFinishedProcess(c)) {
                // 同期送信処理
                //sendSyncCurrentState();
                return;
            }
        }

        let pageNum = activeState.itemKey + 1;

        // セクションでなければ回答をいったん保存する
        if (!activeState.question.sectionFlag && (data.choices.length !== 0 || data.answerText !== "")) {
            await autoSaveAnswer(
                c,
                data,
                false,
                state.examStateValue.endViewFlag,
                isTested,
                activeState.question);
        }

        //未既読状態を更新する
        const examValue = await updateExamData(c);
        //const examValue = state.examStateValue;
        if (examValue) {
            let contents = activeState.contents;
            if (examValue.endViewFlag && pageNum === examValue.pageMax - 1) {
                // ここは通らない想定
                contents = "end-screen";
            }
            await changeActiveQuestion({
                c,
                pageNum: pageNum,
                examStateValue: examValue,
                contents
            });
            //await questionReadProcess(pageNum, examValue);
            questionReadProcess(pageNum, examValue);
        }
        // 同期送信処理
        sendSyncCurrentState();

        await inLogDatas.push(makeInLogsData({ errorCode: 20000 }, InLogsCode.send_sync, "newPage: " + pageNum));
        postInLogs(c, inLogDatas);
    }

    //[前の問題へ]ボタンクリック時の処理
    const handleReturnClick = async (data: PostQuestionArgs) => {

        // 内部ログ登録用の配列
        let inLogDatas: IInLogs[] = [makeInLogsData({ errorCode: 20000 }, InLogsCode.action, "「前へ」ボタン")];

        if (!isTested) {
            //試験残り時間が無くなったか判定、その場合の処理
            if (isFinishedProcess(c)) {
                // 同期送信処理
                //sendSyncCurrentState();
                return;
            }
        }

        let pageNum = activeState.itemKey - 1;

        //セクションと終了画面を除き、回答をいったん保存する
        let examValue = state.examStateValue;
        if (!activeState.question.sectionFlag
            &&
            !(state.examStateValue.endViewFlag && activeState.itemKey === state.examStateValue.pageMax - 1) &&
            (data.choices.length !== 0 || data.answerText !== "")
        ) {
            await autoSaveAnswer(
                c,
                data,
                false,
                state.examStateValue.endViewFlag,
                isTested,
                activeState.question
            );

            //未既読状態を更新する
            examValue = await updateExamData(c) as Exam;

        } else if (state.examStateValue.endViewFlag && activeState.itemKey === state.examStateValue.pageMax - 1) {
            // 終了画面の場合も未既読状態を更新する
            examValue = await updateExamData(c) as Exam;
        }

        if (examValue) {
            let contents = activeState.contents;
            if (pageNum === examValue.pageMax - 2) {
                contents = "Questions";
            }
            await changeActiveQuestion({
                c,
                pageNum: pageNum,
                examStateValue: examValue,
                contents
            });
            //await questionReadProcess(pageNum, examValue);
            questionReadProcess(pageNum, examValue);
        }
        // 同期送信処理
        sendSyncCurrentState();

        await inLogDatas.push(makeInLogsData({ errorCode: 20000 }, InLogsCode.send_sync, "newPage: " + pageNum));
        postInLogs(c, inLogDatas);
    }

    //問題リストクリック時の処理
    const handleSideBarClick = async (page: number) => {

        if (!isTested) {
            //試験残り時間が無くなったか判定、その場合の処理
            if (isFinishedProcess(c)) {
                // 同期送信処理
                //sendSyncCurrentState();
                return;
            }
        }

        let pageNum = page;

        //セクション開始画面をスキップする処理
        // const indexKey = state.examStateValue.startViewFlag ? page - 1 : page;
        // if (removeNotStartViewSection(state.questionsArray)[indexKey].sectionFlag) {
        //   pageNum = page + 1;
        // }

        //セクションでなければ保存する
        if (!activeState.question.sectionFlag) {
            const answerTextData = state.questionsArray.find(q => q.id === activeState.question.id)?.answerText ?? '';
            const data: PostQuestionArgs = {
                id: activeState.question.id,
                choices: state.questionsArray.find(q => q.id === activeState.question.id)?.selectedChoices.map(id => (
                    { choiceId: id }
                )) ?? [],
                answerText: answerTextData
            }
            // 選択肢が未選択,解答テキストが未入力の時では保存しない
            if (data.choices.length !== 0 || data.answerText !== "") {
                await autoSaveAnswer(
                    c,
                    data,
                    false,
                    state.examStateValue.endViewFlag,
                    isTested,
                    activeState.question
                );
            }
        }

        //未既読状態を更新する
        const examValue = await updateExamData(c);
        if (examValue) {
            await changeActiveQuestion({
                c,
                pageNum: pageNum,
                examStateValue: examValue,
                // 開始や終了画面には直接飛ばないので、ここは変わらない想定
                contents: activeState.contents
            });
            //await questionReadProcess(pageNum, examValue);
            questionReadProcess(pageNum, examValue);
        }
        // 同期送信処理
        sendSyncCurrentState();
    }

    // 途中退席ボタンクリック
    const handleToggleLeaveSeatButtonClick = () => {
        setModalState({
            isOpen: true,
            modalType: isLeave ? "return_leave" : "leave",
            onOKFunc: async () => {

                // 内部ログ登録用の配列
                let inLogDatas: IInLogs[] = [makeInLogsData({ errorCode: 20000 }, InLogsCode.action, "「途中退席」ボタン")];

                //試験残り時間が無くなったら退席は出来ない
                if (isFinishedProcess(c)) {
                    // 同期送信処理
                    //sendSyncCurrentState();
                    return;
                }

                // 退席の時はいったん回答を保存する
                if (!isLeave && !activeState.question.sectionFlag) {
                    try {
                        // 表示中の問題情報
                        const q = state.questionsArray.find(q => q.id === activeState.question.id);
                        const ro = state.examStateValue.questions.find(x => x.id === activeState.question.id)?.readonlyFlag ?? true;

                        // すでに読み取り専用の場合は保存しない
                        if (q && !ro) {

                            // 既読状態情報を生成
                            const answerTextData = q.answerText ?? '';
                            const data: PostQuestionArgs = {
                                id: activeState.question.id,
                                choices: q.selectedChoices.map(id => (
                                    { choiceId: id }
                                )),
                                answerText: answerTextData
                            };

                            if (data.choices.length !== 0 || data.answerText !== "") {
                                await autoSaveAnswer(
                                    c,
                                    data,
                                    false,
                                    state.examStateValue.endViewFlag,
                                    isTested,
                                    activeState.question
                                );
                            }

                        }
                    } catch (err) {

                    }
                }

                // 退席状態を送信
                const newIsLeave = !isLeave;
                postCheat(c, newIsLeave ? { startLeave: true } : { endLeave: true });
                const res = await postLeave(c, newIsLeave);
                if (res.errorCode === 20000) {
                    setIsLeave(newIsLeave)
                    // 既読状態更新
                    const examData = await updateExamData(c);
                    if (examData) {
                        // examDataだけstate更新する
                        await changeActiveQuestion({
                            c,
                            examStateValue: examData as Exam,
                            pageNum: activeState.question.page,
                            contents: activeState.contents
                        });
                    }
                }
                // 同期送信処理
                sendSyncCurrentState();

                await inLogDatas.push(makeInLogsData({ errorCode: 20000 }, InLogsCode.send_sync, "newIsLeave: " + newIsLeave));
                postInLogs(c, inLogDatas);
            }
        });
    };

    //終了処理の各種ハンドラー

    //終了画面の「試験を終了する」ボタンクリック時の処理
    const handleEndClickOnEndScreen = async (data: PostQuestionArgs) => {
        setModalState(
            {
                isOpen: true,
                modalType: "end",
                onOKFunc: async () => {
                    setIsLoading(true);

                    //試験残り時間が無くなったか判定、その場合の処理
                    if (isFinishedProcess(c)) {
                        return;
                    }
                    await setIsTested((_isTested) => { endProcess(c, _isTested); return true; });
                    // 同期送信処理
                    //sendSyncCurrentState();
                }
            }
        );
    }


    //終了画面無しの時の最終問題画面の終了ボタン
    const handleEndClickNoEndView = async (data: PostQuestionArgs) => {

        setIsLoading(true);

        //試験残り時間が無くなったか判定、その場合の処理
        if (isFinishedProcess(c)) {
            //await endProcess(c, isTested);
            // 同期送信処理
            //sendSyncCurrentState();
            return;
        }


        //セクションの時,選択肢が未選択,解答テキストが未入力の時では保存しない
        if (!activeState.question.sectionFlag && (data.choices.length !== 0 || data.answerText !== "")) {
            await setIsTested((_isTested) => {
                autoSaveAnswer(
                    c,
                    data,
                    true,
                    state.examStateValue.endViewFlag,
                    _isTested,
                    activeState.question
                );
                return true;
            });
        } else {
            await setIsTested((_isTested) => { endProcess(c, _isTested); return true; });
        }
        // 同期送信処理
        //sendSyncCurrentState();
    }


    //終了画面ありの時の最終問題画面の終了ボタン
    const handleEndClickExsistEndView = async (data: PostQuestionArgs) => {

        // 内部ログ登録用の配列
        let inLogDatas: IInLogs[] = [makeInLogsData({ errorCode: 20000 }, InLogsCode.action, "最終問題の「解答終了確認へ」ボタン")];

        //試験残り時間が無くなったか判定、その場合の処理
        if (isFinishedProcess(c)) {
            // 同期送信処理
            //sendSyncCurrentState();
            return;
        }

        //セクションの時と選択肢が未選択,解答テキストが未入力の時を除き、いったん保存する
        if (!activeState.question.sectionFlag && (data.choices.length !== 0 || data.answerText !== "")) {
            await autoSaveAnswer(
                c,
                data,
                false,
                state.examStateValue.endViewFlag,
                isTested,
                activeState.question
            );
        }
        await changeActiveQuestion({
            c,
            pageNum: state.examStateValue.pageMax - 1,
            examStateValue: state.examStateValue,
            contents: "end-screen"
        });

        // 同期送信処理
        sendSyncCurrentState();

        await inLogDatas.push(makeInLogsData({ errorCode: 20000 }, InLogsCode.send_sync, "newPage: " + (state.examStateValue.pageMax - 1)));
        postInLogs(c, inLogDatas);
    }

    //終了画面あるとき、サイドバーの解答終了ボタンクリック時の処理
    const handleEndBySideBarExistEndView = async () => {

        // 内部ログ登録用の配列
        let inLogDatas: IInLogs[] = [makeInLogsData({ errorCode: 20000 }, InLogsCode.action, "サイドバーの「解答終了確認へ」ボタン")];

        //試験残り時間が無くなったか判定、その場合の処理
        if (isFinishedProcess(c)) {
            // 同期送信処理
            //sendSyncCurrentState();
            return;
        }

        const answerTextData = state.questionsArray.find(q => q.id === activeState.question.id)?.answerText ?? '';
        const data: PostQuestionArgs = {
            id: activeState.question.id,
            choices: state.questionsArray.find(q => q.id === activeState.question.id)?.selectedChoices.map(id => (
                { choiceId: id }
            )) ?? [],
            answerText: answerTextData
        };

        //セクションの時,選択肢が未選択,解答テキストが未入力の時を除いて、いったん保存
        if (!activeState.question.sectionFlag && (data.choices.length !== 0 || data.answerText !== "")) {

            await autoSaveAnswer(
                c,
                data,
                false,
                state.examStateValue.endViewFlag,
                isTested,
                activeState.question);
        }

        await changeActiveQuestion({
            c,
            pageNum: state.examStateValue.pageMax - 1,
            examStateValue: state.examStateValue,
            contents: "end-screen"
        });

        // 同期送信処理
        sendSyncCurrentState();

        await inLogDatas.push(makeInLogsData({ errorCode: 20000 }, InLogsCode.send_sync, "newPage: " + (state.examStateValue.pageMax - 1)));
        postInLogs(c, inLogDatas);
    }

    //終了画面無いとき、サイドバーの解答終了ボタンクリック時の処理
    const handleEndBySideBarNoEndView = async () => {

        setIsLoading(true);

        const answerTextData = state.questionsArray.find(q => q.id === activeState.question.id)?.answerText ?? '';
        const data: PostQuestionArgs = {
            id: activeState.question.id,
            choices: state.questionsArray.find(q => q.id === activeState.question.id)?.selectedChoices.map(id => (
                { choiceId: id }
            )) ?? [],
            answerText: answerTextData
        };

        //試験残り時間が無くなったか判定、その場合の処理
        if (isFinishedProcess(c)) {
            //await endProcess(c, isTested);
            // 同期送信処理
            //sendSyncCurrentState();
            return;
        }

        //セクションの時,選択肢が未選択,解答テキストが未入力の時では保存しない
        if (!activeState.question.sectionFlag && (data.choices.length !== 0 || data.answerText !== "")) {
            await setIsTested((_isTested) => {
                autoSaveAnswer(
                    c,
                    data,
                    true,
                    state.examStateValue.endViewFlag,
                    _isTested,
                    activeState.question
                );
                return true;
            });
        } else {
            await setIsTested((_isTested) => { endProcess(c, _isTested); return true; });
        }
        // 同期送信処理
        //sendSyncCurrentState();
    }


    //受験後、試験確認時の終了ボタン
    const handleEndClickAfterTested = () => {
        go(c, "/");
    }

    //QuestionContentsコンポーネントに渡す、試験終了処理関数
    //終了画面なし場合
    function endBtnFunc_NotExistEndView(data: PostQuestionArgs) {
        setModalState(
            {
                isOpen: true,
                modalType: "end",
                onOKFunc: () => handleEndClickNoEndView(data)
            }
        );
    }

    //終了画面ありの場合
    function endBtnFunc_ExistEndView(data: PostQuestionArgs) {
        handleEndClickExsistEndView(data)
    }

    //試験残り時間が無くなったか検知、処理を実行
    function isFinishedProcess(c: IUseCommon) {

        //試験時間過ぎていたら終了画面行くか、HOMEに遷移
        if (timeState.remainingTime === 0) {

            setModalState(
                {
                    isOpen: true,
                    modalType: "timeout",
                    onOKFunc: () => {
                        setIsLoading(true);
                        setIsTested((_isTested) => {
                            endProcess(c, _isTested); return true;
                        });
                    }
                }
            );
            return true;

        }

        return false;
    }

    function swichEndProcessBySideBar() {
        if (isTested) {
            handleEndClickAfterTested();
        } else {
            if (state.examStateValue.endViewFlag) {
                handleEndBySideBarExistEndView();
            } else {
                setModalState({
                    isOpen: true,
                    modalType: "end",
                    onOKFunc: handleEndBySideBarNoEndView
                });
            }
        }
    }

    //拡張機能が有効かどうかを返す関数
    const isValidExtensions = (extensionCode: ExtCode, exam: Exam) => {
        for (const extension of exam.extensions) {
            if (extension.code == extensionCode && extension.parameter == 1) return true;
        }
        return false;
    }

    // ブラウザのフォーカス不正登録
    const detectFocus = (isFocused: boolean) => {
        if (isFocused) {
            postCheat(c, { SPFocus: true });
        }
        else {
            postCheat(c, { SPBlur: true });
        }
    }

    // 内部ログ用データ作成
    const makeInLogsData = (res: any, code: InLogsCode, description?: string) => {

        var result: IInLogs = {
            logdatetime: new Date(),
            category1: "PCSPSync",
            category3: code,
            type: res.errorCode === 20000 ? 0 : 1,
            description: description ? description + " by SP" : "by SP"
        }

        return result;
    }

    // -----use effefct-----


    React.useEffect(() => {
        function handler() {
            if (isMobile) {
                // テストで実際にはモバイルでなくても
                // ここに来ることがあるので、
                // その場合には処理しないように判定する 
                if (document.visibilityState === 'visible') {
                    setIsHiddenPage(false);
                } else {
                    setIsHiddenPage(true);
                }
            }
        }
        document.removeEventListener("visibilitychange", handler);
        document.addEventListener("visibilitychange", handler);
        return () => {
            document.removeEventListener("visibilitychange", handler);
        };
    }, []);

    React.useEffect(() => {

        /**
         * asyncで書くために便宜的に関数化
         * @returns 
         */
        async function initMobileHome() {

            // 試験取得
            let resExamValue: Exam;
            const dtNow = new Date();
            try {
                const res = await getExam(c);
                if (res.errorCode !== 20000) {
                    common.alertError(res.errorTitle, res.errorDetail);
                    return;
                }
                if (!res.value) {
                    return;
                }
                resExamValue = res.value;
                setState({
                    ...state,
                    examStateValue: resExamValue,
                    questionsArray: convertExamQuestions(resExamValue.questions)
                });
            } catch (err) {
                alert(common.ResponseMessages.Error_GetExam);
                return;
            }

            // 受験者取得
            let resLearnerValue: learner;
            try {
                const res = await getLearner(c);
                if (res.errorCode !== 20000) {
                    common.alertError(res.errorTitle, res.errorDetail);
                    return;
                }
                if (!res.value) {
                    return;
                }
                resLearnerValue = res.value;
            } catch (err) {
                alert(common.ResponseMessages.Error_GetLearner);
                return;
            }

            try {

                if (!resExamValue.choiceDeviceFlag) {
                    alert("この試験はモバイル端末では受験出来ません");
                    go(c, "/");
                    return;
                }

                // 試験終了後(2)でなければ、問題表示処理を実施
                if (resExamValue.startStatus != 2) {
                    // ページ初期化前かバックグラウンド復帰時かを判定
                    if (activeState.contents == ""
                        || activeState.question == initialQuestion) {
                        // questions内の一番最初の問題がセクション開始画面(表示する)の場合、
                        // 表示する問題をひとつ次の問題にする
                        // 2024/04/15 PCの問題表示に合わせセクション開始画面を表示するため、上記処理を削除(コメントアウト)
                        const question = resExamValue.questions[0];
                        const nextQuestion = resExamValue.questions[1];
                        let itmeKey = resExamValue.startViewFlag ? 1 : 0;
                        //if (question.sectionFlag && question.page !== nextQuestion.page) {
                        //  itmeKey = itmeKey + 1;
                        //}
                        //開いているページの初期値を設定
                        await changeActiveQuestion({
                            c,
                            pageNum: itmeKey,
                            examStateValue: resExamValue,
                            contents: "Questions"
                        });
                        //await questionReadProcess(itmeKey, resExamValue);
                        questionReadProcess(itmeKey, resExamValue);
                    } else {
                        // バックグラウンドからの復帰時は復帰前のページで初期化
                        await changeActiveQuestion({
                            c,
                            pageNum: activeState.itemKey,
                            examStateValue: resExamValue,
                            contents: activeState.contents
                        });
                        //await questionReadProcess(activeState.itemKey, resExamValue);
                        questionReadProcess(activeState.itemKey, resExamValue);
                    }
                }

                //受験済みかどうか
                if (resLearnerValue.executionEndDatetime !== null
                    || resExamValue.startStatus === 2) {
                    setIsTested(true);
                }

                //試験期間中、もしくは未受験かどうか
                if ((resExamValue.termType === 0 && resExamValue.startStatus !== 2)
                    ||
                    (resExamValue.termType === 1 && resLearnerValue.executionEndDatetime === null)
                ) {

                    // 受験時のIP、UAが未登録の場合DBを更新
                    /*if (resLearnerValue.executionSPIP === null || resLearnerValue.executionSPIP === "") {
                      const res = await putLearnerIP(c, { start: true, SPStartFlag: true });
                      if (res.errorCode !== 20000) {
                        common.alertError(res.errorTitle, res.errorDetail);
                        return;
                      }
                    }*/

                    //画面遷移後すぐにカウントダウン開始     
                    setTimeState({
                        timeUpdateTime: dtNow.getTime(),
                        remainingTimeMS: resExamValue.endSeconds * 1000,
                        remainingTime: resExamValue.endSeconds
                    });

                    // ブラウザのフォーカス関連のイベント
                    window.addEventListener('blur', () => detectFocus(false));
                    window.addEventListener('focus', () => detectFocus(true));
                }
                // 退席中フラグ
                setIsLeave(resLearnerValue.leaveFlag);

                // 試験期間中のとき同期送信(リロード、初期表示時)
                if (resExamValue.startStatus === 1) {
                    sendSyncCurrentState();
                }

                setIsLoading(false);
            } catch (err) {
                // common.alertError(err.errorTitle, err.errorDetail);
            }
        }
        // 初期化関数を実行
        initMobileHome();

        return () => {
            window.removeEventListener('blur', () => detectFocus(false));
            window.removeEventListener('focus', () => detectFocus(true));
        }
    }, [isHiddenPage]);

    /**
     * 同期処理初期化
     */
    useSync("MobileHome", true, async (label, data) => {
        switch (label) {
            case "HOME":
                // {
                //     completeProcess: string
                // }
                return;
            case "MobileHomeEnvironmentMovie":
            case "MobileHomeTakePhoto":
                // {
                //     completeProcess: string
                // }
                return;
            case "MobileHome":
                // {
                //     state: _state,
                //     activeState: _activeState,
                //     isLeave: _isLeave,
                //     isTested: _isTested,
                // }
                //   or
                // {
                //     isTested: true
                // }

                // 念のため、自分からは無視
                return;
            case "PCHome":
                // {
                //     activeState: _activeState,
                //     timeState: _timeState,
                //     isLeave: _isLeave,
                //     isTested: _isTested
                // }
                break;
            case "PCStandByScreen":
                // {
                //     completeProcess: _completeProcess
                // }
                return;
            default:
                console.error("想定外の同期元：" + label);
                break;
        }

        // 内部ログ登録用の配列
        let inLogDatas: IInLogs[] = [makeInLogsData({ errorCode: 20000 }, InLogsCode.get_sync)];

        let alreadyStart = false;
        setIsProcessingUseSync((_isProcessingUseSync) => {
            alreadyStart = _isProcessingUseSync;
            return true;
        });

        // 同期処理中、終了処理中の変更を受け付けない
        if (alreadyStart || isTested) return;

        // PCから退席状態更新したか
        let hasChangedIsLeave = false;

        // PCから終了フラグが送られてきた場合はstateの更新を行わない
        if (!data.isTestedByPC) {
            setIsTested(data.isTested);

            hasChangedIsLeave = isLeave !== data.isLeave;
            setIsLeave(data.isLeave);

            // 残り時間はPC側から来たものに同期するが逆はしない
            setTimeState(data.timeState);
        }

        //解答送信処理をしたかどうかのフラグ
        let isSaved = false;

        // 表示中の問題情報
        //console.log("表示中の問題情報");
        //console.log(state.questionsArray);

        const q = state.questionsArray.find(q => q.id === activeState.question.id);
        const ro = state.examStateValue.questions.find(x => x.id === activeState.question.id)?.readonlyFlag ?? true;
        const postQuestionPage = activeState.itemKey;

        // すでに読み取り専用の場合は保存しない
        if (q && !ro) {
            //解答送信処理
            const answerTextData = q.answerText ?? '';
            const postData: PostQuestionArgs = {
                id: activeState.question.id,
                choices: q.selectedChoices.map(id => (
                    { choiceId: id }
                )),
                answerText: answerTextData
            };

            if (c.appContext.fakeapi_mode) {
                // 模擬試験モードの場合は既読、解答、退席の状態保存処理を行う
                try {
                    await postAnswer(c, postData, false, state.examStateValue.endViewFlag, isTested);
                    await postLeave(c, data.isLeave);
                } catch (err) {
                    alert(common.ResponseMessages.Error_GetQuestion_Answer);
                }
                isSaved = true;

            } else if (!activeState.question.sectionFlag && (postData.choices.length !== 0 || postData.answerText !== "")) {
                //セクションの時,選択肢が未選択,解答テキストが未入力の時を除いて保存
                await autoSaveAnswer(
                    c,
                    postData,
                    false,
                    state.examStateValue.endViewFlag,
                    isTested,
                    activeState.question
                );
                isSaved = true;
            }
        }

        if (data.isTestedByPC) {
            // 終了処理
            setIsLoading(true);
            await setIsTested((_isTested) => { endProcess(c, _isTested); return true; });
            return;
        }

        if (hasChangedIsLeave) {
            // 退席状態を送信
            //const newIsLeave = !isLeave;
            postCheat(c, data.isLeave ? { startLeave: true } : { endLeave: true });
            const res = await postLeave(c, data.isLeave);
            if (res.errorCode !== 20000) {
                common.alertError(res.errorTitle, res.errorDetail);
                return;
            }
        }

        //解答保存後のexamDataを取得して更新
        //モバイル側の問題一覧を非表示にしたので、即時データ更新を無しにしてみた
        const examValue = await updateExamData(c);
        //const examValue = state.examStateValue;

        if (examValue) {
            await changeActiveQuestion({
                c,
                pageNum: data.activeState.itemKey,
                examStateValue: examValue,
                contents: data.activeState.contents
            });
            inLogDatas.push(makeInLogsData({ errorCode: 20000 }, InLogsCode.display_changed));
        }

        //解答保存か退席状態更新していたら同期送信処理
        if (isSaved || hasChangedIsLeave) {
            sendSyncCurrentState();

            await inLogDatas.push(makeInLogsData(
                { errorCode: 20000 },
                InLogsCode.send_sync,
                isSaved ? "saveQuestionPage: " + postQuestionPage : hasChangedIsLeave ? "newIsLeave: " + data.isLeave : undefined
            ));
        }

        setIsProcessingUseSync(false);
        postInLogs(c, inLogDatas);
    });

    /**
     * 同期処理送信処理
     */
    function sendSyncCurrentState() {
        // stateの変更は非同期なので、
        // 更新後の値で同期する
        setState(_state => {
            setActiveState(_activeState => {
                setIsLeave(_isLeave => {
                    setIsTested(_isTested => {
                        sendSync("MobileHome", {
                            state: _state,
                            activeState: _activeState,
                            isLeave: _isLeave,
                            isTested: _isTested,
                        });
                        return _isTested;
                    });
                    return _isLeave;
                });
                return _activeState;
            });
            return _state;
        });
    }

    /**
     * タイマー処理初期化
     */
    common.useInterval(() => { }, () => {
        setTimeState(s => {
            if (!isFinite(s.remainingTime)
                || s.remainingTime <= 0
                || !s.timeUpdateTime) {
                return s;
            }
            const dtNow = new Date();
            const diff = dtNow.getTime() - s.timeUpdateTime;
            const remainingTimeMS = (
                s.remainingTimeMS == undefined ? s.remainingTime * 1000 : s.remainingTimeMS
            ) - diff;
            const remainingTime = Math.floor(remainingTimeMS / 1000);
            if (remainingTime <= 0) {
                setIsLoading(true);
                // 試験時間終了時の選択肢を保存する
                //セクションでなければ保存する
                if (!activeState.question.sectionFlag) {
                    const answerTextData = state.questionsArray.find(q => q.id === activeState.question.id)?.answerText ?? '';
                    const data: PostQuestionArgs = {
                        id: activeState.question.id,
                        choices: state.questionsArray.find(q => q.id === activeState.question.id)?.selectedChoices.map(id => (
                            { choiceId: id }
                        )) ?? [],
                        answerText: answerTextData
                    }
                    // 選択肢が未選択,解答テキストが未入力の時では保存しない
                    if (data.choices.length !== 0 || data.answerText !== "") {
                        setIsTested((_isTested) => {
                            autoSaveAnswer(
                                c,
                                data,
                                true,
                                state.examStateValue.endViewFlag,
                                _isTested,
                                activeState.question
                            );
                            return true;
                        });
                    } else {
                        setIsTested((_isTested) => { endProcess(c, _isTested); return true; });
                    }
                } else {
                    setIsTested((_isTested) => { endProcess(c, _isTested); return true; });
                }
            }
            return {
                timeUpdateTime: dtNow.getTime(),
                remainingTimeMS,
                remainingTime: remainingTime < 0 ? 0 : remainingTime
            };
        });
    }, 500);

    return (
        <Layout viewType="mobile" isTutorial={c.appContext.fakeapi_mode}>
            <MobileHomeContext.Provider value={contextValue}>

                {(/*isTested ||*/ state.examStateValue.startStatus === 2)
                    //試験終了後にアクセスされた場合に表示する画面
                    ? (<Box display="flex" flexDirection="column" justifyContent="center">
                        <Box marginBottom={6} marginTop={6} style={{ fontSize: common.FONT_SIZE.mainText, textAlign: "center" }}>{/*この試験はすでに終了しています。*/}この試験は終了しました</Box>
                        <ButtonContainer className={classNames.mobileButtonContainer}>
                            <DefaultBackButton className="backBtn" onClick={() => { go(c, "/") }}><ArrowBackIosIcon /><span>&nbsp;トップへ戻る</span></DefaultBackButton>
                        </ButtonContainer>
                    </Box>)

                    :

                    <div style={{ display: "flex" }} className={classNames.mobileHomeRoot}>

                        {
                            isLoading

                                ? <ReactLoading
                                    type={"spin"}
                                    color={"#003f71"}
                                    height={'3rem'}
                                    width={'3rem'}
                                    className={classNames.loadingStyle}
                                />

                                :
                                <Grid container justify="center" >

                                    <MobileHomeAcordion
                                        isTested={isTested}
                                        isLeave={isLeave}
                                        handleEndProcessButtonClick={
                                            swichEndProcessBySideBar
                                        }
                                        handleToggleLeaveSeatButtonClick={
                                            handleToggleLeaveSeatButtonClick
                                        }
                                        isValidExtensions={
                                            isValidExtensions
                                        }
                                    />

                                    <MobileHomeQuestionList
                                        isOpenedList={isOpenedList}
                                        handleSideBarClick={handleSideBarClick}
                                    />

                                    <MobileHomeQuestionContents
                                        isLeave={isLeave}
                                        isTested={isTested}
                                        isOpenedList={isOpenedList}
                                        setIsOpenedList={setIsOpenedList}
                                        handleReturnClick={handleReturnClick}
                                        handleEndClickOnEndScreen={handleEndClickOnEndScreen}
                                        handleEndClickAfterTested={handleEndClickAfterTested}
                                        handleNextClick={handleNextClick}
                                        endBtnFunc_NotExistEndView={endBtnFunc_NotExistEndView}
                                        endBtnFunc_ExistEndView={endBtnFunc_ExistEndView}
                                        handleChange={handleChange}
                                        handleTextChange={handleTextChange}
                                    />

                                    <MobileHomeModal
                                        modalState={modalState}
                                        setModalState={setModalState}
                                    />

                                </Grid>

                        }
                    </div>
                }

            </MobileHomeContext.Provider>
        </Layout>
    );
}

function getQuestionsArray({
    activeState, state, choiceId }: {
        activeState: IActiveState;
        state: IMobileHomeState;
        choiceId: number;
    }) {
    let questions: ExpansionExamQuestions[] = [];

    if (activeState.question.answerType === 0) {

        questions = state.questionsArray.map((question, index) => {
            if (question.id === activeState.question.id) {
                question.selectedChoices.splice(0);
                question.selectedChoices.push(choiceId);
                return question;
            }
            else
                return question;
        });

    }

    if (activeState.question.answerType === 1) {

        questions = state.questionsArray.map((question, index) => {

            if (question.id === activeState.question.id) {

                if (question.selectedChoices.filter(s => s === choiceId).length > 0) {

                    const i = question.selectedChoices.findIndex(i => i === choiceId);
                    question.selectedChoices.splice(i, 1);
                    return question;

                } else {
                    question.selectedChoices.push(choiceId);
                    return question;
                }
            }
            else
                return question;
        });

    }
    return questions;
}

function MobileHomeAcordion(props: {
    isTested: boolean;
    isLeave: boolean;
    handleToggleLeaveSeatButtonClick: () => void;
    handleEndProcessButtonClick: () => void;
    isValidExtensions: (param1: ExtCode, param2: Exam) => boolean;
}) {

    const {
        state,
        activeState,
        timeState,
    } = useContext(MobileHomeContext);

    const remainingTime = timeState.remainingTime;
    const activeItemKey = activeState.itemKey;
    const examStateValue = state.examStateValue;

    const classNames = useStyles();
    const isHiddenAcordionFlag
        = (examStateValue.startViewFlag && activeItemKey === 0)
        ||
        (examStateValue.endViewFlag && activeItemKey === examStateValue.pageMax - 1);
    return (
        <Grid item xs={12} className={classNames.headerBox} hidden={isHiddenAcordionFlag}>

            <Grid container  >

                <Grid item xs={7} >
                    <span className={classNames.remainingTimeBox} hidden={props.isTested || examStateValue.startStatus === 2}>
                        残り時間：<span className={classNames.remainingTime} >{dispRemainingTime(remainingTime)}</span>
                    </span>

                    {/* 退席中の録画はしない想定 */}
                    <RecordingScreen
                        hiddenFlag={
                            props.isTested || remainingTime === 0 || props.isLeave || !props.isValidExtensions(ExtCode.record, state.examStateValue)
                        }
                        key={`${props.isTested || remainingTime === 0 || props.isLeave}`}
                        onStartError={(err) => {
                            alert(err);
                        }}
                    />

                </Grid>

                <Grid item xs={5} >

                    <Grid container justify="flex-end" >
                        <ButtonContainer className={classNames.rowContainer} >

                            <DefaultButton
                                id="finishBtn"
                                onClick={props.handleEndProcessButtonClick}
                                hidden={props.isLeave}                          >
                                {/* {props.isTested ? "問題の表示を終了する" : examStateValue.endViewFlag ? "解答終了確認へ" : "解答を終了する"}*/}
                                {props.isTested ? "問題の表示を終了する" : examStateValue.endViewFlag ? "解答終了確認へ" : "試験を終了する"}
                            </DefaultButton>

                            <DefaultButton
                                hidden={props.isTested || remainingTime === 0 || !props.isValidExtensions(ExtCode.leave, state.examStateValue)}
                                onClick={props.handleToggleLeaveSeatButtonClick}>
                                {props.isLeave ? "試験を再開する" : "途中退席する"}
                            </DefaultButton>

                        </ButtonContainer>
                    </Grid>

                </Grid>

            </Grid>

        </Grid>
    );
}

/**
 * 問題リスト
 */
function MobileHomeQuestionList(props: {
    isOpenedList: boolean;
    handleSideBarClick: (page: number) => void;
}) {
    const classNames = useStyles();
    const {
        state,
        activeState,
        timeState,
    } = useContext(MobileHomeContext);
    const activeItemKey = activeState.itemKey;
    const examStateValue = state.examStateValue;
    const questionsArray = state.questionsArray;
    const filteredQuestions = removeNotStartViewSection(questionsArray);
    const isHidden =
        (
            examStateValue.startViewFlag && activeItemKey === 0
        )
        ||
        (
            examStateValue.endViewFlag && activeItemKey === examStateValue.pageMax - 1
        );
    return (
        <div
            style={{ width: "100%" }}
            hidden={isHidden}>
            <List
                hidden={!props.isOpenedList}
                className={classNames.listStyle}>
                {
                    filteredQuestions.map((question, idx) => {
                        // css classを選択
                        let pageClassName = "";
                        if (question.page === activeItemKey) {
                            pageClassName = classNames.activePage;
                        } else {
                            pageClassName =
                                classNames[getQuestionPageStyleName(question, questionsArray)]
                        }
                        // テキストを生成
                        // const headerText = "問"
                        //   + setQuestionHeadnum(question.id, questionsArray)
                        //   + (question.sectionFlag ? "問題文" : "");
                        const headerText = setQuestionHeadnum(question.id, questionsArray)
                        return (
                            <Box
                                key={question.id}
                                className={pageClassName}>
                                <ListItem
                                    key={question.id}
                                    button
                                    className={classNames.sideBarTextStyle}
                                    onClick={() => props.handleSideBarClick(question.page)}>
                                    <Grid container>
                                        <Grid item xs={4}
                                            style={{ fontWeight: "bold" }}
                                            id={question.sectionFlag ? "" : "headNum"}>
                                            {headerText}
                                        </Grid>
                                        <Grid item xs={8} >
                                            {question.subjectText + "..."}
                                        </Grid>
                                    </Grid>
                                </ListItem>
                                <Divider variant="fullWidth" />
                            </Box>);
                    })
                }
            </List>
        </div>
    );
}

function MobileHomeQuestionContents({
    isLeave,
    isTested,
    isOpenedList,
    handleReturnClick,
    handleEndClickOnEndScreen,
    handleEndClickAfterTested,
    handleNextClick,
    endBtnFunc_NotExistEndView,
    endBtnFunc_ExistEndView,
    handleChange,
    handleTextChange,
    setIsOpenedList
}: {
    isLeave: boolean;
    isTested: boolean;
    isOpenedList: boolean;
    handleReturnClick: Function;
    handleEndClickOnEndScreen: Function;
    handleEndClickAfterTested: Function;
    handleNextClick: Function;
    endBtnFunc_NotExistEndView: Function;
    endBtnFunc_ExistEndView: Function;
    handleChange: Function;
    handleTextChange: Function;
    setIsOpenedList: (b: boolean) => any;
}) {
    const classNames = useStyles();
    const {
        state,
        activeState,
        timeState,
    } = useContext(MobileHomeContext);
    let qeustionContents;
    if (isLeave) {
        // 退席中は回答欄を見せない
        qeustionContents = <div>退席中です</div>;
    } else {
        const hiddenFlagsValue: hiddenFlags =
            makeHiddenFlags(state, activeState.itemKey);
        switch (activeState.contents) {
            case "end-screen":
                qeustionContents = state.examStateValue.endViewFlag ? <EndScreen
                    isTested={isTested}
                    isFinishedTest={timeState.remainingTime === 0}
                    exam={state.examStateValue}
                    hiddenFlags={hiddenFlagsValue}
                    handleReturnClick={handleReturnClick}
                    handleEndClickOnEndScreen={handleEndClickOnEndScreen}
                    handleEndClickAfterTested={handleEndClickAfterTested} /> : <QuestionContents
                    activeItemKey={activeState.itemKey}
                    isOpenedList={isOpenedList}
                    remainingTime={timeState.remainingTime}
                    examValue={state.examStateValue}
                    isTested={isTested}
                    selectedChoices={state.questionsArray.find(q => q.id === activeState.question.id)?.selectedChoices ?? []}
                    answerText={state.questionsArray.find(q => q.id === activeState.question.id)?.answerText ?? ''}
                    question={activeState.question}
                    hiddenFlags={hiddenFlagsValue}
                    handleReturnClick={handleReturnClick}
                    handleNextClick={handleNextClick}
                    handleEndClickNoEndView={endBtnFunc_NotExistEndView}
                    handleEndClickExsistEndView={endBtnFunc_ExistEndView}
                    handleEndClickAfterTested={handleEndClickAfterTested}
                    handleChange={handleChange}
                    handleTextChange={handleTextChange}
                    // headerText={"問" + setQuestionHeadnum(activeState.question.id, state.questionsArray) + (activeState.question.sectionFlag ? "問題文" : "")}
                    headerText={setQuestionHeadnum(activeState.question.id, state.questionsArray)}
                    getParentHeadNum={getParentHeadNum}
                    switchOpenList={() => { setIsOpenedList(!isOpenedList); }} />;
                break;
            case "Questions":
                qeustionContents = <QuestionContents
                    activeItemKey={activeState.itemKey}
                    isOpenedList={isOpenedList}
                    remainingTime={timeState.remainingTime}
                    examValue={state.examStateValue}
                    isTested={isTested}
                    selectedChoices={state.questionsArray.find(q => q.id === activeState.question.id)?.selectedChoices ?? []}
                    answerText={state.questionsArray.find(q => q.id === activeState.question.id)?.answerText ?? ''}
                    question={activeState.question}
                    hiddenFlags={hiddenFlagsValue}
                    handleReturnClick={handleReturnClick}
                    handleNextClick={handleNextClick}
                    handleEndClickNoEndView={endBtnFunc_NotExistEndView}
                    handleEndClickExsistEndView={endBtnFunc_ExistEndView}
                    handleEndClickAfterTested={handleEndClickAfterTested}
                    handleChange={handleChange}
                    handleTextChange={handleTextChange}
                    // headerText={"問" + setQuestionHeadnum(activeState.question.id, state.questionsArray) + (activeState.question.sectionFlag ? "問題文" : "")}
                    headerText={setQuestionHeadnum(activeState.question.id, state.questionsArray)}
                    getParentHeadNum={getParentHeadNum}
                    switchOpenList={() => { setIsOpenedList(!isOpenedList); }} />;
                break;
            default:
                qeustionContents = <div></div>;
                break;
        }
    }
    return (
        <Grid item xs={12} >
            {qeustionContents}
        </Grid>);
}

/**
 * モーダルダイアログ
 */
function MobileHomeModal({ modalState, setModalState }: {
    modalState: IModalState;
    setModalState: (s: IModalState) => any;
}) {
    const { finishedModalText, modalText } = useStyles();
    const className =
        modalState.modalType == "timeout" ? finishedModalText : modalText;
    const isNotice =
        modalState.modalType == "timeout" ? true : undefined;
    const onCloseFunc =
        modalState.modalType == "timeout" ? modalState.onOKFunc : undefined;

    let _modalBody: JSX.Element;
    switch (modalState.modalType) {
        case "leave":
            _modalBody = (
                <div className={className}>
                    <p id="simple-modal-title">途中退席してもよろしいですか？</p>
                    <p id="warning">
                        途中退席すると、既に閲覧した問題については、
                        未回答であっても解答することはできなくなります。
                    </p>
                </div>
            );
            break;
        case "return_leave":
            _modalBody = (
                <div className={className}>
                    <p id="simple-modal-title">試験を再開してもよろしいですか？</p>
                </div>
            );
            break;
        case "end":
            _modalBody = (
                <div className={className}>
                    <p id="simple-modal-title">解答を終了してもよろしいですか？</p>
                    <p id="warning">※一度解答を終了すると再解答は出来ません</p>
                </div>
            );
            break;
        case "timeout":
            _modalBody = (
                <div className={className}>
                    <p className="modaltext">残り時間が無くなりました</p>
                    <p className="modaltext">試験を終了します</p>
                </div>
            );
            break;
    }
    return (
        <CustomModal
            isOpen={modalState.isOpen}
            setIsOpenFunc={(isOpen: boolean) => {
                setModalState({ ...modalState, isOpen });
            }}
            onOKFunc={modalState.onOKFunc}
            isNotice={isNotice}
            onCloseFunc={onCloseFunc}
        >
            {_modalBody}
        </CustomModal>);
}

/**
   * 試験終了時用の同期処理送信処理
   */
async function sendSyncEndProcess() {
    // 試験終了フラグのみ送信する
    sendSync("MobileHome", {
        isTested: true
    })
}

/**
 *　試験終了処理 
 */
async function endProcess(c: IUseCommon, isTested: boolean) {

    // 過去に終了処理済みなら何もしない
    if (isTested) return;

    // 試験終了をサーバーに送信
    try {
        const userName = await common.getUser().then((u) => { return u?.userName ? u.userName : "null"; });
        const res = await putExam(c, { end: true, encUserName: common.encodeBase64(userName.toString()) });
        if (res.errorCode !== 20000 && res.errorCode !== 40105) { // 40105: 終了処理が重複した場合も先に進む
            common.alertError(res.errorTitle, res.errorDetail);
            return;
        }
    } catch (err) {
        alert(common.ResponseMessages.Error_PutExam);
    }

    // 受験者情報を取得して終了が反映されているか確認
    try {
        const res = await getLearner(c);
        if (res.errorCode !== 20000) {
            common.alertError(res.errorTitle, res.errorDetail);
            return;
        }
        if (res.value !== null) {
            //終了になっていればトップに遷移
            if (res.value.executionEndDatetime !== null) {
                //同期処理送信処理
                await sendSyncEndProcess()
                go(c, "/");
            }
        }
    } catch (err) {
        alert(common.ResponseMessages.Error_GetLearner);
    }
}

/**
 * 回答を保存する
 */
async function autoSaveAnswer(
    c: IUseCommon,
    data: PostQuestionArgs,
    endFlag: boolean,
    endViewFlag: boolean,
    isTested: boolean,
    activQuestion: Question) {
    //isTested試験終了後か判断し、終了後なら保存しない  
    if (!isTested) {
        try {
            //const resQuestion = await getQuestion(c, { id: data.id });
            // if (resQuestion.errorCode !== 20000) {
            //   common.alertError(resQuestion.errorTitle, resQuestion.errorDetail);
            //   return;
            // }
            //前回の解答と変化がない場合保存しない
            if (hasAnswerDiff(data.choices, activQuestion.choices) || data.answerText !== activQuestion.answerText) {
                await postAnswer(c, data, endFlag, endViewFlag, isTested);
            } else if (endFlag) {
                await endProcess(c, isTested);
            }
        } catch (err) {
            alert(common.ResponseMessages.Error_GetQuestion_Answer);
        }
    } else if (endFlag) {
        await endProcess(c, isTested);
    }
}

/**
 * 回答に変更があるかチェックする
 */
function hasAnswerDiff(newChoices: { choiceId: number }[], beforeChoices: Choice[]) {
    const newChoiceIds = newChoices.map(choice => choice.choiceId);
    const beforeChoiceIds = beforeChoices
        .filter(choice => choice.selected)
        .map(choice => choice.id);
    const sameNum = [...newChoiceIds, ...beforeChoiceIds]
        .filter(choice => {
            return (newChoiceIds.includes(choice) && beforeChoiceIds.includes(choice));
        })
        .length / 2;
    return !(
        newChoiceIds.length === beforeChoiceIds.length
        && sameNum === newChoiceIds.length
    );
}

/**
 * 解答データを保存
 */
async function postAnswer(
    c: IUseCommon,
    data: PostQuestionArgs,
    endFlag: boolean,
    endViewFlag: boolean,
    isTested: boolean): Promise<void> {
    try {
        const res = await postQuestion(c, data);
        if (res.errorCode === 40103) {
            // 読み取り専用
            // とりあえずエラーにしない対応
            return;
        }
        if (res.errorCode !== 20000) {
            common.alertError(res.errorTitle, res.errorDetail);
            return;
        }
        if (endFlag) {
            await endProcess(c, isTested);
        }
    } catch (err) {
        alert(common.ResponseMessages.Error_PostQuestion_Answer);
    }
}

//ExamQuestionsのデータをExpansionExamQuestionsに変換
function convertExamQuestions(data: ExamQuestions[]) {
    if (data === null) {
        return [];
    }
    const resultData: ExpansionExamQuestions[] = data.map(value => ({
        id: value.id,
        page: value.page,
        sectionFlag: value.sectionFlag,
        parentQuestionId: value.parentQuestionId,
        subjectText: value.subjectText,
        answerStatus: value.answerStatus,
        selectedChoices: [],
        answerText: "",
        readonlyFlag: value.readonlyFlag as boolean
    }));
    return resultData;
}

//サイドバーの問題番号振り分け
function setQuestionHeadnum(activeQuestionId: number, questionsArray?: ExpansionExamQuestions[]) {
    let sectionNum = 0;
    let commonNum = 0;
    let headNum = "";
    let sectionHeadNum = "";

    if (!questionsArray) {
        return headNum;
    }

    for (const question of questionsArray) {
        if (question.sectionFlag) {
            sectionNum = sectionNum + 1;

            if (activeQuestionId === question.id) {
                return sectionHeadNum = sectionNum.toString();
            }

        }
        else if (question.parentQuestionId === 0) {
            commonNum = commonNum + 1;

            if (activeQuestionId === question.id) {
                return headNum = "問" + commonNum;
            }

        }
        else {
            commonNum = commonNum + 1;
            if (activeQuestionId === question.id) {
                return headNum = "問" + commonNum;
            }

        }

    }

    return headNum;

}

//子問題の、親セクションの問題番号を取得
function getParentHeadNum(questionArg: Question, questionsArray: ExpansionExamQuestions[]) {
    let sectionNum = 0;
    let headNum = "";

    for (const question of questionsArray) {
        if (question.sectionFlag) {

            sectionNum = sectionNum + 1;

            headNum = sectionNum + " " + question.subjectText;

        }
        else if (question.parentQuestionId === 0) {

            sectionNum = sectionNum + 1;
            if (questionArg.id === question.id) {
                headNum = sectionNum + " " + question.subjectText;
                break;
            }

        }
        else {

            if (questionArg.id === question.id)
                break;

        }

    }

    return headNum;

}

//開始画面のないセクション問題をquestionsから削除
function removeNotStartViewSection(questions: ExpansionExamQuestions[]) {
    let questionArgs = questions.concat();
    let pages: number[] = [];
    for (const question of questionArgs) {
        if (pages.includes(question.page)) {
            const index = pages.indexOf(question.page);
            if (questionArgs[index].sectionFlag) {
                questionArgs.splice(index, 1);
            }
        }

        pages.push(question.page);

    }
    return questionArgs;
}

//残り時間表示関数
function dispRemainingTime(remainingTime: number) {
    const time = remainingTime;
    const allMinutes = Math.floor(time / 60);
    const hours = Math.floor(allMinutes / 60);
    const minutes = (allMinutes - hours * 60);
    const seconds = Math.floor(time - (minutes * 60) - (hours * 60 * 60));
    return hours + ":" + paddZero(minutes) + ":" + paddZero(seconds);
}

function paddZero(argNum: number) {
    let retVal = String(argNum);
    if (argNum < 10)
        retVal = "0" + retVal;
    return retVal;
}



async function setQuestionRead(c: IUseCommon, questionId: number) {
    try {
        const res = await postQuestion(c, {
            id: questionId,
            choices: [],
            answerText: ""
        }, true);
        if (res.errorCode === 40103) {
            // 読み取り専用
            // とりあえずエラーにしない対応
            return;
        }
        if (res.errorCode !== 20000) {
            common.alertError(res.errorTitle, res.errorDetail);
            return;
        }
    } catch (err) {
        alert(common.ResponseMessages.Error_PostQuestion_Answer);
    }
}

function getQuestionPageStyleName(
    question: ExpansionExamQuestions,
    questionsArray: ExpansionExamQuestions[]) {

    let styleName = "" as "answeredPage" | "notActivePage" | "readPage" | "readOnlyPage";

    if (question.readonlyFlag) {
        styleName = "readOnlyPage";
        return styleName
    }

    if (question.sectionFlag) {
        const children = questionsArray
            .filter(q => q.parentQuestionId === question.id);
        let isAnsweredAll = true;
        for (const child of children) {
            if (child.answerStatus !== 2 && !child.readonlyFlag)
                isAnsweredAll = false;
        }
        isAnsweredAll ? styleName = "answeredPage" : styleName = "notActivePage";
        return styleName;
    }

    switch (question.answerStatus) {
        case 0:
            styleName = "notActivePage";
            break;

        case 1:
            styleName = "readPage";
            break;

        case 2:
            styleName = "answeredPage";
            break;
    }
    return styleName;

}

function makeHiddenFlags(state: { examStateValue: Exam; questionsArray: ExpansionExamQuestions[]; }, activeItemKey: number): hiddenFlags {
    const {
        startViewFlag,
        endViewFlag,
        pageMax
    } = state.examStateValue;
    return {
        start: (!startViewFlag)
            || (startViewFlag && activeItemKey !== 0),
        return: (startViewFlag && (activeItemKey === 0 || activeItemKey === 1))
            || (!startViewFlag && activeItemKey === 0),
        next: (startViewFlag && activeItemKey === 0)
            || (activeItemKey === pageMax - 1),
        end: (!endViewFlag && activeItemKey !== pageMax - 1)
            || (endViewFlag && activeItemKey !== pageMax - 2)
    };
}

/**
 * エラーにならないように設定するstateの初期値
 * @returns 
 */
function getInitialValues() {

    const initialChoice = [
        {
            id: 6,
            viewOrder: 1,
            bodyText: "サンプル１",
            selected: false
        },
        {
            id: 5,
            viewOrder: 2,
            bodyText: "サンプル２",
            selected: false
        },
        {
            id: 4,
            viewOrder: 3,
            bodyText: "サンプル３",
            selected: false
        }
    ] as Choice[];

    const initialFigure = {
        id: 999999,
        body: "初期値"
    } as Figure;

    return {
        initialExamQuestions: [
            {
                id: 999999,
                page: 2,
                sectionFlag: true,
                parentQuestionId: 0,
                subjectText: "初期値2",
                answerStatus: 0,
                selectedChoices: [0],
                answerText: "",
                readonlyFlag: false
            },
            {
                id: 888888,
                page: 3,
                sectionFlag: false,
                parentQuestionId: 999999,
                subjectText: "初期値3",
                answerStatus: 0,
                selectedChoices: [0],
                answerText: "",
                readonlyFlag: false
            },
            {
                id: 777777,
                page: 1,
                sectionFlag: false,
                parentQuestionId: 0,
                subjectText: "初期値1",
                answerStatus: 0,
                selectedChoices: [0],
                answerText: "",
                readonlyFlag: false
            }
        ] as ExpansionExamQuestions[]
        , initialQuestion: {
            id: 777777,
            sectionFlag: false,
            parentQuestionId: 999999,
            parentText: "初期値",
            parentHtml: "初期値",
            subjectText: "初期値",
            bodyHtml: "{\"blocks\":[{\"key\":\"bp2bt\",\"text\":\"日本で一番大きい山は？。\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}",
            answerType: 0,
            choiceShuffleFlag: false,
            choiceNumberingType: 999999,
            choices: initialChoice,
            answerText: "",
            figures: [initialFigure],
            page: 1,
            pageMax: 5
        } as Question
        , initialExamQuestion:
            {
                id: 999999,
                page: 2,
                sectionFlag: true,
                parentQuestionId: 0,
                subjectText: "初期値",
                answerStatus: 0
            } as ExamQuestions
    };
}

