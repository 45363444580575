import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as common from "../../common";
import { checkSpeed } from '../../common';
import { APIResponse, ICheckInfo, ISasData, ScreenDetailed } from "../../react-app-env";

export default function usePCDeviceCheck() {
    const cmn = common.useCommon();

    const {
        go, // 画面遷移 
        api,  // API呼び出し
        backgroud_api,
        withLoading,
    } = cmn;

    const [info, setInfo] = React.useState<ICheckInfo>(() => {
        return {
            checkid: uuidv4(),
            type: "pc",
            ua: navigator.userAgent,
            js: true,
            checked_cam: false,
            checked_mic: false,
            checked_screen: false,
            checked_speed: false
        };
    });

    const [screenInfo, setScreenInfo] = React.useState([false, [] as ScreenDetailed[]]);
    const isValidScreen = screenInfo[0] as boolean;
    const screens = screenInfo[1] as ScreenDetailed[];


    const ONE_MB = 1024 * 1024;
    async function getSasData() {
        const res: APIResponse<ISasData> = await backgroud_api("/api/l-devicecheck", "GET");
        return res.value;
    }
    const sp_download_mb = (info.sp_download ?? 0) / ONE_MB;
    const sp_upload_mb = (info.sp_upload ?? 0) / ONE_MB;
    const [up_progress_val, set_up_progress_val] = React.useState(0);
    const [dl_progress_val, set_dl_progress_val] = React.useState(0);

    React.useEffect(() => {

    }, []);

    return {
        isChecking: !info.checked_speed || !info.checked_screen,
        isScreenChecked:info.checked_screen,
        isValidScreen,
        screens,
        isValidSpeed:sp_upload_mb >= 0.512 && sp_download_mb >= 0.768,
        isSpeedChecked: info.checked_speed,
        sp_download_mb: sp_download_mb.toFixed(2),
        sp_upload_mb: sp_upload_mb.toFixed(2),
        up_progress_val,
        dl_progress_val,
        onReturnDeviceCheck: () => { go("/devicecheck"); },
        onSaveDeviceCheckResult: async () => {
            try {
                await withLoading(common.saveDeviceCheckResult(cmn, info));
                alert("検証結果を保存しました。\n以上でPCの検証は完了です。");
            } catch (err) {
                common.alertError("保存に失敗しました。", "時間を置いて再度実行してください。");
            }
        },
        onStartScreenCheck:async () => {
            const x = await withLoading(common.checkScreen());
            setScreenInfo(x);
            if (!x || x.length < 2 || !x[1] || x[1].length < 1) {
                return;
            }
            // 取得したスクリーン情報でDB保存用データを更新
            const screen = x[1][0];
            setInfo(i => {
                return {
                    ...i,
                    checked_screen: true,
                    screen_w: screen.width,
                    screen_h: screen.height
                };
            });
        },
        onStartSpeedCheck:async () => {
            set_dl_progress_val(0);
            set_up_progress_val(0);
            setInfo(_info => {
                return {
                    ..._info,
                    checked_speed: false
                };
            });
            const { speedInfo, clientIP } = await withLoading(
                checkSpeed(getSasData,
                    (up_progress) => {
                        const { loadedBytes, allBytes } = up_progress;
                        set_up_progress_val(loadedBytes / allBytes * 100);
                    }, (dl_progress) => {
                        const { loadedBytes, allBytes } = dl_progress;
                        set_dl_progress_val(loadedBytes / allBytes * 100);
                    })
            );
            if (!speedInfo) {
                // なんらかのエラー
                return;
            }
            setInfo(_info => {
                const newInfo = {
                    ..._info,
                    ip: clientIP,
                    sp_upload: speedInfo.sp_upload_az,
                    sp_download: speedInfo.sp_download_az,
                    checked_speed: true
                };
                console.log(newInfo);
                return newInfo;
            });
        }
    };
}