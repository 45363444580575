
import React from 'react';
import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';




//共通スタイルのボタンを囲むコンテナ
export const ButtonContainer = styled("div")({
  display:"flex",
  justifyContent:"flex-start",
  "& span":{
    display: "inline-block",
    verticalAlign:"middle"
  }
});

//共通スタイルのボタン
export const DefaultButton = styled("button")({
  backgroundColor:"#4378b6", 
  padding:"6px 16px",
  borderRadius: "8px",
  color:"white",
  outline:"none !important",
  border: "solid 1px #4378b6"
});

export const DefaultBackButton = styled("button")({
  backgroundColor:"#ffffff", 
  padding:"6px 16px",
  borderRadius: "8px",
  border: "solid 1px #4378b6",
  color:"#4378b6",
  outline:"none !important",
});








 