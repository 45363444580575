import { ContainerClient } from "@azure/storage-blob";
import * as h from "history";
import * as Cookies from "js-cookie";
import moment, { MomentInput } from "moment";
import 'moment/locale/ja';
import neatCsv from "neat-csv";
import React, { useCallback, useContext, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { AppContext } from "./App";
import { ApplicationPaths, QueryParameterNames } from './components/api-authorization/ApiAuthorizationConstants';
import authService from './components/api-authorization/AuthorizeService';
import { useFakeApi } from "./fakeapi";
import { APIArgs, APIResponse, ApiMethod, IAppContext, ICheckInfo, ISasData, ScreenDetailed, ScreenDetails, UserData } from "./react-app-env";
import { resolve } from "dns";
import * as crypto from 'crypto'

export const APP_DISP_NAME = "リモテスト";

const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };

export const FONT_SIZE = {
    title: "2.4rem", formText: "1.4rem", tableBody: "1.6rem", tablePagenation: "1.4rem", mainText: "1.6rem", button: "1.6rem", sideBarText: "1.4rem"
    , sideBarBtn: "1.2rem", richEditorRoot: "1.7rem", richEditorText: "1.6rem", choiceText: "1.8rem", questionHeader: "2rem", mobileBtnText: "2.6vw"
    , mobileTableText: "2.8vw", responsive_12px: "3.2vw", responsive_14px: "3.7vw", responsive_16px: "4.27vw", responsive_18px: "4.8vw", responsive_20px: "5.3vw", responsive_24px: "6.4vw"
}

moment.suppressDeprecationWarnings = true;


//APIの応答メッセージ
export const ResponseMessages = {

    //-----/api/l-learner------------
    Error_GetLearner: "受験者情報の取得に失敗しました",

    //-----/api/l-exam------------
    Error_GetExam: "試験情報の取得に失敗しました",
    Error_PostExam: "試験情報の登録に失敗しました",
    Error_PutExam: "試験情報の更新に失敗しました",

    //-----/api/l-question------------
    Error_GetQuestion: "試験問題の取得に失敗しました",
    Error_PostQuestion: "試験問題の保存に失敗しました",
    Error_GetQuestion_Answer: "解答情報の取得に失敗しました",
    Error_PostQuestion_Answer: "解答情報の登録に失敗しました",

    //-----/api/l-reception------------
    Error_GetReception_BadCode: "認証コードが不正です。",
    Error_GetReception_Expired: "認証コードの有効期限が切れています。",
    Error_GetReception_Accepted: "受付済みのため、パスワードは設定されませんでした。",
    Error_GetReception_InValidPassword: "入力されたパスワードが条件を満たしていません。",

    //-----/api/l-screen_cheat------------
    Error_PostScreenCheat: "画面接続不正の登録に失敗しました",
}

// react-app-envに置くとエラーになるのでこちらに置く
export enum ExtCode {
    /**
     * 周囲撮影
     */
    "env_record" = "env_record",
    /**
     * 退席
     */
    "leave" = "leave",
    /**
     * 前説動画
     */
    "prebrief" = "prebrief",
    /**
     * 受験録画
     */
    "record" = "record",
    /**
     * 本人写真
     */
    "self_photo" = "self_photo",
    /**
     * 試験会場対応
     */
    "place" = "place"
}

export enum InLogsCode {
    "action" = "action",
    "send_sync" = "send_sync",
    "get_sync" = "get_sync",
    "display_changed" = "display_changed"
}

/**
 * 時間のフォーマット
format('LT');   // 11:31
format('LTS');  // 11:31:44
format('L');    // 2020/10/26
format('l');    // 2020/10/26
format('LL');   // 2020年10月26日
format('ll');   // 2020年10月26日
format('LLL');  // 2020年10月26日 11:31
format('lll');  // 2020年10月26日 11:31
format('LLLL'); // 2020年10月26日 月曜日 11:31
format('llll'); // 2020年10月26日(月) 11:31
https://momentjs.com/docs/#/displaying/
 * @param d 
 * @param format 
 */
export function dateFormat(d: MomentInput, format: string) {
    if (!d) {
        return "";
    }
    const m = moment(d);
    if (false === m.isValid()) {
        console.error("dateFormatの入力の形式が不正です：値＝" + (d?.toString()));
        return "";
    }
    return moment(d).format(format);
}

/**
 * csvパーサー
 * オプションの詳細は以下参照
 * 例：headersに文字列の配列を指定でヘッダー指定、falseでヘッダー行なし
 * https://github.com/mafintosh/csv-parser#options
 * @param data 
 * @param options 
 */
export function parseCsv<Row = neatCsv.Row>(data: string | Buffer, options?: neatCsv.Options) {
    return neatCsv<Row>(data, options);
}

function toCsvLineItem(s: string) {
    // "null" "undefined" に変換されるのを回避する
    if (s === null || s === undefined) {
        s = "";
    }
    // それ以外は既定の文字列変換後にエスケープ処理
    const v = String(s).replace(`"`, `""`);
    // 引用符で囲んで返す
    return `"${v}"`;
}

/**
 * CSV変換
 * @param rows 
 * @param option noHeaderでヘッダー出力なし、colsで出力する列と順序を指定
 */
export async function toCsv(rows: { [key: string]: string }[], option?: {
    noHeader?: boolean;
    cols?: string[]
}) {

    if (typeof rows !== 'object' || Array.isArray(rows) !== true) {
        return Promise.reject("引数が配列ではありません");
    }

    return Promise.resolve()
        .then(() => {

            let cols: string[];
            if (option?.cols) {
                // 指定されていれば指定された項目を出力
                cols = option.cols;
            } else {
                // 全ての行の全てのキーを取得
                const allKeys = new Set<string>();
                rows.forEach((row) => {
                    Object.keys(row).forEach((key) => {
                        allKeys.add(key);
                    });
                });
                cols = Array.from(allKeys).sort();
            }

            const bodyLines = rows.map((row) => {
                return cols.map((col) => {
                    return toCsvLineItem(row[col]);
                }).join(",");
            }).join("\n");

            if (option?.noHeader) {
                return bodyLines;
            } else {
                const headerLine = cols.map(toCsvLineItem).join(",");
                return headerLine + "\n" + bodyLines;
            }
        });
}

/**
 * utf-8 BOM 付きでファイルを保存する
 * @param content 
 * @param fileName 
 */
export function saveTextFile(content: string, fileName: string) {
    const encoder = new TextEncoder();
    // BOMを付加する
    const buffer = encoder.encode('\ufeff' + content);
    const blob = new Blob([buffer], { type: "text/plain;charset=utf-8" });
    // if (window.navigator.msSaveBlob) {
    //     window.navigator.msSaveBlob(blob, fileName);
    // } else
    {
        var a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        //a.target   = '_blank';
        a.download = fileName;
        document.body.appendChild(a) //  FireFox specification
        a.click();
        document.body.removeChild(a) //  FireFox specification
    }
}

/**
 * テキストファイルを読む
 * @param file 
 * @param encoding 
 */
export function readTextFile(file: Blob, encoding: string = "utf-8") {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const decoder = new TextDecoder(encoding)
            const buffer = e.target?.result as ArrayBuffer;
            const string = buffer ? decoder.decode(buffer) : "";
            resolve(string);
        }
        reader.onabort = (e) => { reject(e); };
        reader.onerror = (e) => { reject(e); };
        reader.readAsArrayBuffer(file);
    });
}

export async function getUser() {
    return authService.getUser().then((user: UserData) => {
        return user;
    })
}

/**
 * useCommonの戻り値の型
 */
export interface IUseCommon {
    getUser: () => Promise<UserData>;
    params: { [key: string]: string };
    history: h.History<any>;
    queryParams: URLSearchParams;
    go: (location: h.LocationDescriptor<any>) => void;
    logout: () => void;
    appContext: IAppContext;
    setAppContext: (action: (current: IAppContext) => Partial<IAppContext>) => void;
    withLoading: <T>(p: Promise<T>) => Promise<T>;
    api: <TArgs = APIArgs, TResponse = any>(path: string, method: ApiMethod, args?: TArgs) => Promise<TResponse>;
    backgroud_api: <TArgs = APIArgs, TResponse = any>(path: string, method: ApiMethod, args?: TArgs) => Promise<TResponse>;
}

/**
 * Hookを使用する関数ラップしたカスタムHook
 */
export function useCommon(): IUseCommon {
    const history = useHistory();
    const params = useParams<{ [key: string]: string }>();
    const { appContext, setAppContext } = useContext(AppContext);
    const go = useCallback(history.push, [history.push]);
    const withLoading = useCallback(<T = any>(p: Promise<T>) => {
        setAppContext((c) => ({ backdropIsopen: true }));
        return p.then((res) => {
            setAppContext((c) => ({ backdropIsopen: false }));
            return res;
        }, (err) => {
            setAppContext((c) => ({ backdropIsopen: false }));
            return Promise.reject(err);
        });
    }, [setAppContext]);
    const _api = useCallback(async <TArgs = APIArgs, TResponse = any>(path: string, method: ApiMethod, args?: TArgs) => {
        // expired時に再ログインする
        const redirectUrl = await checkExpiredAndRedirect();
        if (redirectUrl) {
            go(redirectUrl);
            return {} as TResponse;
        }
        return withLoading(api<TArgs, TResponse>(path, method, args));
    }, [go, withLoading]);
    const _backgroud_api = useCallback(async <TArgs = APIArgs, TResponse = any>(path: string, method: ApiMethod, args?: TArgs) => {
        // expired時に再ログインする
        const redirectUrl = await checkExpiredAndRedirect();
        if (redirectUrl) {
            go(redirectUrl);
            return {} as TResponse;
        }
        return api<TArgs, TResponse>(path, method, args);
    }, [go]);
    const historyDepData = useMemo(() => {
        return {
            queryParams: new URLSearchParams(history.location.search),
            logout: () => go(logoutPath),
        };
    }, [go, history.location.search]);

    let cmn = {
        getUser: getUser,

        params: params,

        history: history,
        queryParams: historyDepData.queryParams,
        go: go,
        logout: historyDepData.logout,

        appContext: appContext,
        setAppContext: setAppContext,

        withLoading: withLoading,
        api: _api,
        backgroud_api: _backgroud_api
    };

    // appcontextがfake modeならapiをfakeに置き換える
    cmn = useFakeApi(cmn);

    return cmn;
}

async function checkExpiredAndRedirect() {
    const oUser = await authService.userManager?.getUser();
    if (oUser && oUser.expired) {
        var link = document.createElement("a");
        link.href = window.location.href;
        const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
        const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(returnUrl)}`;
        return redirectUrl;
    }
    return null;
}

function toQuery(args: any) {
    const parts: string[] = [];
    for (let p in args) {
        try {
            const v = args[p];
            parts.push(encodeURIComponent(p) + "=" + encodeURIComponent(v));
        } catch (e) {
            console.log(e);
        }
    }
    return "?" + (parts.join("&"));
}

function toBody<TArgs = APIArgs>(args: TArgs, headers: Headers) {
    let hasFile = false;
    for (let p in args) {
        if (typeof (args[p]) === "object") {
            const name = (args[p] as Object).constructor.name;
            console.log(name);
            if (name == "Blob" || name == "File") {
                hasFile = true;
            }
        }
    }
    if (!hasFile) {
        // json
        headers.append("Content-Type", "application/json; charset=utf-8");
        return JSON.stringify(args);
    } else {
        // formdata
        const formData = new FormData();
        for (var p in args) {
            try {
                formData.append(p, args[p] as unknown as string);
            } catch (e) {
            }
        }
        return formData;
    }
}

/**
 * API呼び出しの本体
 * @param path 
 * @param method 
 * @param args 
 */
async function api<TArgs = APIArgs, TResponse = any>(path: string, method: ApiMethod, args?: TArgs) {
    const headers = new Headers();

    const token = await authService.getAccessToken();
    if (token) {
        headers.append('Authorization', `Bearer ${token}`);
    } else {
        console.warn("no token");
    }

    const hasQuery = args && (method === "GET" || method === "DELETE");
    const url = hasQuery ? path + toQuery(args) : path;

    const hasBody = args && (method === "POST" || method === "PUT" || method === "PATCH");
    const body = hasBody ? toBody(args, headers) : undefined;

    const response = await fetch(url, {
        method: method,
        cache: "no-cache",
        credentials: 'same-origin', // include, *same-origin, omit
        headers: headers,
        body: body
    });
    if (!response.ok) {
        return Promise.reject(`error status code : ${response.status} ${response.statusText}`);
    }
    return await response.json() as Promise<TResponse>;
}


/**
 * パスワード検証
 * @param password 
 */
export const validatePasswordFunc = (password: string) => {
    let correctFlag = true;
    let errorMessage = "";

    if (password.length < 6 || password.length > 100) {
        errorMessage += "パスワードは「最小6文字」で入力してください\n"
        correctFlag = false;
    }

    // // 数字が存在するかチェック 
    // if (password.match(/[0-9]/) === null) {
    //     errorMessage += "パスワードは「数字を最低1文字」含めてください\n"
    //     correctFlag = false;
    // }

    // // 英大文字が存在するかチェック 
    // if (password.match(/[A-Z]/) === null) {
    //     errorMessage += "パスワードは「英大文字を最低1文字」含めてください\n"
    //     correctFlag = false;
    // }

    // // 英小文字が存在するかチェック 
    // if (password.match(/[a-z]/) === null) {
    //     errorMessage += "パスワードは「英子文字を最低1文字」含めてください\n"
    //     correctFlag = false;
    // }

    // // 記号が存在するかチェック 
    // if (password.match(/[!@#$%^&*()_\+\-=\[\]{};:?,.]/) === null) {
    //     errorMessage += "パスワードは「英数字以外の文字を最低1文字」含めてください"
    //     correctFlag = false;
    // }

    return { correctFlag, errorMessage }
}

export function alertError(title: string, details: string) {
    const ary: string[] = [];
    if (title && title !== "undefined") {
        ary.push(title);
    }
    if (details && details !== "undefined") {
        ary.push(details);
    }
    var message = ary.join("\n");
    if (message.trim().length > 0) {
        alert(message);
    } else {
        console.trace("empty alert");
    }
}

export function useInterval(before: Function, callback: () => any, delay: number) {
    const savedCallback = React.useRef<() => any>();
    React.useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);
    React.useEffect(() => {
        function tick() {
            if (savedCallback.current) {
                savedCallback.current();
            }
        }
        if (delay !== null) {
            before();
            const id = setInterval(tick, delay);
            return () => { clearInterval(id); }
        }
    }, [delay]);
}

export function arrayBufferToBase64(buffer: ArrayBufferLike) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

interface Window1 {
    getScreenDetails: () => Promise<ScreenDetails>;
}
declare var window: Window & Window1;

/**
 * スクリーン数、解像度チェック
 * @returns 
 */
export async function checkScreen(): Promise<[boolean, ScreenDetailed[]]> {

    if ('getScreenDetails' in window == false) {
        return [false, []];
    }

    const screenDetails: ScreenDetails = await window.getScreenDetails();
    console.log(screenDetails);

    if (!screenDetails || !screenDetails.currentScreen) {
        return [false, []];
    }

    const screens = screenDetails.screens ?? [screenDetails.currentScreen];

    // 複数スクリーン不可
    if (screens.length == 1) {
    } else {
        return [false, screens];
    }

    // 解像度チェック
    if (screens[0].height >= 720 && screens[0].width >= 1280) {
    } else {
        return [false, screens];
    }

    // ok
    return [true, screens];
}

/**
 * スクリーン数チェック
 * @returns 
 */
export async function checkScreenCount() {

    if ('getScreenDetails' in window == false) {
        return 0;
    }

    const screenDetails: ScreenDetails = await window.getScreenDetails();

    if (!screenDetails || !screenDetails.currentScreen) {
        return 0;
    }

    const screens = screenDetails.screens ?? [screenDetails.currentScreen];

    return screens.length;
}

declare type OnProgress = {
    (progress: { loadedBytes: number, allBytes: number }): void;
}
/**
 * 回線速度チェック　戻り値はbps
 * @param getSasData 
 * @returns 
 */
export async function checkSpeed(
    getSasData: () => Promise<ISasData>,
    onUploadProgress?: OnProgress,
    onDownloadProgress?: OnProgress) {
    try {
        const thousand = 1000.0;
        ///*
        const startAt_download = Date.now();
        const res_download = await fetch("/dummyfile_10MB.txt");
        if (!res_download.ok) {
            // status error
            const { status, statusText, type } = res_download;
            console.log({ status, statusText, type });
        }
        const blob = await res_download.blob();
        // ms to second
        const timeSec_download = (Date.now() - startAt_download) / thousand;
        const sp_download = Math.floor(8 * blob.size / timeSec_download);
        console.log("download ok");
        // upload to blob storage
        const sasData = await getSasData();
        const client = new ContainerClient(sasData.sas);
        const startAt_upload = Date.now();
        const name = "dummyfile" + startAt_upload;
        const res_upload = await client.uploadBlockBlob(name, blob, blob.size, {
            onProgress: (p) => {
                if (onUploadProgress) {
                    onUploadProgress({
                        loadedBytes: p.loadedBytes,
                        allBytes: blob.size
                    });
                }
            }
        });
        if (res_upload.response.errorCode) {
            console.log(res_upload.response.errorCode);
        }
        // ms to second
        const timeSec_upload = (Date.now() - startAt_upload) / thousand;
        const sp_upload_az = Math.floor(8 * blob.size / timeSec_upload);
        console.log("upload ok"); 

        // delete
        await client.deleteBlob(name);
        console.log("delete ok");
        
        // download2
        /*const startAt_download2 = Date.now();
        const res_download2 = await client.getBlockBlobClient(name).download(
            undefined,
            undefined,
            {
                onProgress: (p) => {
                    if (onDownloadProgress) {
                        onDownloadProgress({
                            loadedBytes: p.loadedBytes,
                            allBytes: blob.size
                        });
                    }
                }
            }); 
        if (res_download2.errorCode) {
            console.log(res_download2.errorCode);
        }

        // ms to second
        const timeSec_download2 = (Date.now() - startAt_download2) / thousand;
        const sp_download_az = Math.floor(blob.size / timeSec_download2);
        console.log("download2 ok");
        */

        let sp_download_az: number = 0;
        await download(onDownloadProgress).then((value: number) => {
            sp_download_az = value;
        })

        return {
            speedInfo: {
                size: blob.size,
                sp_download,
                sp_upload_az,
                sp_download_az: sp_download_az,
            },
            clientIP: sasData.clientIP 
        };
    }
    catch (err) {
        console.error(err);
        return {
            speedInfo: null,
            clientIP: ""
        }
    }
}
// 速度測定のためのダウンロード処理
async function download(onDownloadProgress?: OnProgress): Promise<number>
{
    return new Promise((resolve) => {
        const thousand = 1000.0;
        const url = 'https://remotest.blob.core.windows.net/public/dummyfile_50MB.txt?sp=r&st=2024-06-19T06:55:01Z&se=2027-10-01T14:55:01Z&spr=https&sv=2022-11-02&sr=b&sig=zbwmaYiGwcKxehRpDvA%2FE1I%2BdftZlXr8u4DQo9Uvs1U%3D';
        const xhr = new XMLHttpRequest();
        let downloadSize = 0;
        let sp_download_az = 0;

        const startAt_download2 = Date.now();
        xhr.responseType = 'blob';
        xhr.onprogress = e => {
            if (e.lengthComputable) {
                if (onDownloadProgress) {
                    onDownloadProgress({
                        loadedBytes: e.loaded,
                        allBytes: e.total
                    });
                }
                downloadSize = e.total;
            }
        };
        xhr.onload = e => {
            if (xhr.status == 200) {
                const timeSec_download2 = (Date.now() - startAt_download2) / thousand;
                resolve(sp_download_az = Math.floor(8 * e.total / timeSec_download2));
                console.log("download2 ok");
            }
        };
        xhr.open("GET", url);
        xhr.send();
    })
}

/**
 * 音声入力チェック
 * @param callback 
 */
export async function checkMicInput(callback: (intervalId: number, deviceId: string, vol: number) => void) {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const deviceId = stream.getAudioTracks()[0].getSettings().deviceId;
    // AudioContextオブジェクトを作成する
    const audioContext = new AudioContext();
    // MediaStreamAudioSourceNodeオブジェクトを作成する
    const sourceNode = audioContext.createMediaStreamSource(stream);
    // AnalyserNodeオブジェクトを作成する
    const analyserNode = audioContext.createAnalyser();
    // AnalyserNodeオブジェクトを接続する
    sourceNode.connect(analyserNode);
    // FFTサイズを設定する
    analyserNode.fftSize = 2048;
    // Uint8Arrayを作成する
    const dataArray = new Uint8Array(analyserNode.frequencyBinCount);
    // ループ処理を開始する
    const id = window.setInterval(() => {
        // 分析データを取得する
        analyserNode.getByteFrequencyData(dataArray);
        // 音量を計算する
        const volume = dataArray.reduce((acc, val) => acc + val, 0) / dataArray.length;
        callback(id, deviceId ?? "", volume);
    }, 100);
}

export async function saveDeviceCheckResult(common: IUseCommon, result: ICheckInfo) {
    const res = await common.api<ICheckInfo, APIResponse<number>>("/api/l-devicecheck", "POST", result);
    if (res.errorCode == 20000) {
        // 保存に成功したのでクッキーに保存する
        const expireDate = new Date();
        expireDate.setMonth(expireDate.getMonth() + 1);
        console.log(expireDate.toLocaleString());
        Cookies.set("AKOConformanceId", result.checkid, {
            expires: expireDate
        });
        return res.value;
    } else {
        throw res;
    }
}

// 受験者ID暗号化
export function encodeBase64(data: string): string {
    const ALGORITHM = 'aes-256-cbc';
    // 鍵
    const KEY = Buffer.from("12345678901234567890123456789012");

    var encUserName = "";

    var isSlash = true;
    // URLに含んでも問題ない文字列になるまで繰り返す
    while (isSlash) {
        // 16byteのランダム値を生成してIVとする
        const iv = crypto.randomBytes(16);
        // 暗号器作成
        const cipher = crypto.createCipheriv(ALGORITHM, KEY, iv);
        // dataをバイナリにして暗号化
        const encData = cipher.update(Buffer.from(data));
        // 末端処理 ＆ 先頭にivを付与し、バイナリをbase64(文字列)にして返す
        encUserName = Buffer.concat([iv, encData, cipher.final()]).toString('base64');
        if (!encUserName.includes('/') && !encUserName.includes('+') && !encUserName.includes('%2B')) {
            isSlash = false;
            break;
        }
    }
    return encUserName;
}