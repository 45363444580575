import React from 'react';
import { Box } from '@material-ui/core';
import { Question, Choice, hiddenFlags, PostQuestionArgs, Exam } from "../../../react-app-env";
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import TextField from '@material-ui/core/TextField';

import * as commons from "../../../common";
import { DefaultButton, ButtonContainer, DefaultBackButton } from '../../StylesUI/CommonLayouts';
import { BorderAll } from '@material-ui/icons';
import '../../CounterStyles.module.css';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';


const useStyles = makeStyles((theme: Theme) =>
({
    questionRoot: {
        "& #questionHeaderNum": {
            margin: "0",
            padding: "1vw 4.27vw",
            backgroundColor: "#b7cde6",
            color: "#003f71",
            fontSize: commons.FONT_SIZE.responsive_18px,
            fontWeight: "bold"
        },
        "& #sectionText": {
            fontSize: commons.FONT_SIZE.responsive_16px
        }
    },
    rowContainer: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        "& .choiceTextStyle":{
            fontSize: commons.FONT_SIZE.responsive_16px,
            lineHeight:commons.FONT_SIZE.responsive_18px,
            width:"100%",
            whiteSpace:"pre-wrap",
            wordBreak: "break-all",
            color:"black",
            border:"none",
            resize: "none",
            //marginTop:"1.6vw",
            outline: "none",
            //paddingBottom: "3vw",
            //paddingLeft: "2vw"
        },
        "& .MuiInput-underline.Mui-disabled:before":{
            borderBottomStyle:"solid"
        },
        "& .MuiInputBase-multiline":{
            padding:"8px 0 6px"
        }
    },
    leftElementStyle: {
        flexBasis: "75%"
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "space-between",
        "& Button": {
            padding: "1.8vw",
            fontSize: commons.FONT_SIZE.responsive_12px,
            backgroundColor: "#0c326f",
            color: "white",
            border: "solid 0.5px white",
            borderRadius: 0,
            margin: 0
        },
        "& button:hover": {
            backgroundColor: "#003f71",
        },
        "& .nextBtn": {
            flexBasis: "27%"
        },
        "& #openBtn": {
            flexBasis: "46%"
        },
        "& #backBtn": {
            flexBasis: "27%"
        },
        "& .MuiSvgIcon-root":{
          fontSize: commons.FONT_SIZE.responsive_16px,
        }
    },
    endButtonContainer: {
        display: "flex",
        justifyContent: "flex-end",
        "& Button": {
            fontSize: commons.FONT_SIZE.responsive_12px,
            padding: "1.3vw",
            width: "50%",
            backgroundColor: "#0c326f",
        },
        "& button:hover": {
            backgroundColor: "#003f71",
        },
    },
    headerTextStyle: {
        fontSize: commons.FONT_SIZE.responsive_16px,
        marginTop: "0.13vw",
        backgroundColor: "#b7cde6",
        fontWeight: "bold",
        color: "#003f71",
        "& h3": {
            fontSize: commons.FONT_SIZE.responsive_18px
        }
    },
    choicesListStyle: {
        paddingLeft: "2vw",
        "& li": {
            marginBottom: "4vw",
        },
        "& h4": {
            cursor: "pointer"
        },
        "& svg":{
            fontSize:commons.FONT_SIZE.responsive_16px
        }
    }
}));


function hasReadOnlyFlag(exam: Exam, questionId: number) {
    try {
        const examQuestion = exam.questions.find(q => q.id === questionId);
        if (!examQuestion) return false;
        return examQuestion.readonlyFlag;
    } catch (err) {
        return false;
    }
}

export function QuestionContents(props: { activeItemKey: number; isOpenedList: boolean, remainingTime: number, examValue: Exam, isTested: boolean, selectedChoices: number[], answerText: string, question: Question, hiddenFlags: hiddenFlags, headerText: string, handleReturnClick: any, handleNextClick: any, handleEndClickNoEndView: any, handleEndClickExsistEndView: any, handleEndClickAfterTested: any, handleChange: any, handleTextChange: any, getParentHeadNum: any, switchOpenList: any }) {

    const isReadOnly = hasReadOnlyFlag(props.examValue, props.question.id);

    // -----汎用関数定義-----
    function orderByAsc(a: { viewOrder: number }, b: { viewOrder: number }) {
        if (a.viewOrder < b.viewOrder) return -1;
        if (a.viewOrder > b.viewOrder) return 1;
        return 0;
    }


    const handleReturnClick = () => {
        const answerTextData = props.answerText ?? '';
        const data: PostQuestionArgs = {
            id: props.question.id,
            choices: props.selectedChoices.map(id => (
                { choiceId: id }
            )),
            answerText: answerTextData
        }
        props.handleReturnClick(data);
    }

    const handleNextClick = () => {
        const answerTextData = props.answerText ?? '';
        const data: PostQuestionArgs = {
            id: props.question.id,
            choices: props.selectedChoices.map(id => (
                { choiceId: id }
            )),
            answerText: answerTextData
        }
        props.handleNextClick(data);
    }



    //試験終了処理
    const handleEndClick = () => {

        if (props.isTested) {
            props.handleEndClickAfterTested();
        }
        else if (props.examValue.endViewFlag) {

            const answerTextData = props.answerText ?? '';
            const data: PostQuestionArgs = {
                id: props.question.id,
                choices: props.selectedChoices.map(id => (
                    { choiceId: id }
                )),
                answerText: answerTextData
            };
            props.handleEndClickExsistEndView(data);

        }
        else {

            const answerTextData = props.answerText ?? '';
            const data: PostQuestionArgs = {
                id: props.question.id,
                choices: props.selectedChoices.map(id => (
                    { choiceId: id }
                )),
                answerText: answerTextData
            }
            props.handleEndClickNoEndView(data);

        }


    }




    //選択肢のナンバリング種別の切り替え
    const switchNumberingType = () => {
        let type = ""
        switch (props.question.choiceNumberingType) {

            case 0: type = "decimal"; break;

            case 1: type = "myKatakana"; break;

            case 2: type = "lower-roman"; break;

            case 3: type = "upper-roman"; break;

            case 4: type = "none"; break;

        }

        return type;

    }

    // 選択肢のナンバリング種別によるスタイルの切り替え
    const getChoiceButtonMargin = () => {
        switch (props.question.choiceNumberingType) {
            case 0: case 1: case 2: case 3:
                return "2.2rem"
            case 4:
                return "0"
            default:
                break;
        }
    }

    const switchNextBtnText = () => {
        return props.hiddenFlags.end ? <ArrowForwardIosIcon /> : ""
    }

    const switchNextBtnAction = () => {
        if(props.hiddenFlags.end) handleNextClick(); 
    }

    //文字列を指定の文字長さで改行し、整形する関数
    const shapeChoiceText = (textArray:string[] ,thresholdLength:number) => {
        // let textArray = text.split(/\n/);
        let resultText = "";
        var i = 0;

        if(textArray.length == 1){
            resultText = textArray[0];
        }else{

            for (const oneLineText of textArray) {
    
                const textLength = oneLineText.length;
                var idx = 0;
                var arrList = [];
    
                while(idx + thresholdLength < textLength){
                    arrList.push(oneLineText.slice(idx ,idx + thresholdLength));
                    idx = idx + thresholdLength;
                }
    
                if(idx != 0 && textLength != idx) arrList.push(oneLineText.slice(idx));
    
                if(idx == 0){
                    i == 0 ? resultText = oneLineText : resultText = resultText + '\n' + oneLineText 
                }else{
                    i == 0 ? resultText = arrList.join('\n') : resultText = resultText + '\n' + arrList.join('\n')
                }
                
                i++;
                
    
            }

        }
        console.log(textArray);

        return resultText;

        
    }

    //文字列の行数を返す関数
    const getRowsNum = (text:string) => {
        let lineNum = text.match(/\n/g);
        let rowsNum = 1;
        if(lineNum != null){
            rowsNum = lineNum.length + 1;
        }
        return rowsNum
    }

    const setHeightSize = (rows:number) =>{
        const rowHeight = 5;
        let rowsHeight = rowHeight * rows;
        return rowsHeight + "vw"
    };

    const lbToBr = (txt:string) => {

    }




    //-----スタイルの宣言-------
    const classNames = useStyles();



    return (

        <div className={classNames.questionRoot}>

            <ButtonContainer className={classNames.buttonContainer} >



                {props.hiddenFlags.return ? <DefaultBackButton id="backBtn" disabled></DefaultBackButton>
                    : <DefaultBackButton id="backBtn" onClick={handleReturnClick}><ArrowBackIosIcon /><span>&nbsp;前へ</span></DefaultBackButton>}

                {/* 意見を受けて、試しにモバイル側の問題一覧表示ボタンを非表示 */}
                {/* <DefaultButton id="openBtn" onClick={props.switchOpenList}><FolderOpenIcon />&nbsp;<span>{props.isOpenedList ? "問題一覧を閉じる" : "問題一覧を開く"}</span></DefaultButton> */}
                <DefaultButton id="openBtn" ></DefaultButton>
                {props.hiddenFlags.next ? <DefaultButton className="nextBtn" disabled></DefaultButton>
                    : <DefaultButton className="nextBtn" onClick={switchNextBtnAction}><span hidden={switchNextBtnText() == ""}>次へ&nbsp;</span> {switchNextBtnText()} </DefaultButton>}

            </ButtonContainer>

            <Box marginBottom={1} className={classNames.headerTextStyle}>
                <span style={props.question.sectionFlag ? {border: "solid 1px", marginLeft: "5px", padding: "0px 7px", width: "fit-content"} : {marginLeft: "5px"}}>{props.headerText}</span>
            </Box>
            <div>

                <Box marginBottom={4} padding={"2vw 4.27vw"}>
                    {/*<div hidden={props.question.sectionFlag} style={{fontSize:"16px",fontWeight:"bold"}} > 解答欄</div>*/}
                    <ul className={classNames.choicesListStyle} style={{ listStyleType: switchNumberingType(), fontSize: commons.FONT_SIZE.responsive_18px }}>
                        {props.question?.sectionFlag === false ?
                            props.question.answerType > 1 ?
                              props.question.answerType === 2 ?
                                <input type="text" pattern="[0-9]+" inputMode="numeric" maxLength={8}
                                    style={{ marginLeft: "4vw" }}
                                    disabled={isReadOnly || props.isTested || props.examValue.startStatus === 2}
                                    value={props.answerText ?? ""}
                                    onChange={(event) => props.handleTextChange(event.target.value)} />
                                :
                                <input type="text"
                                    style={{ marginLeft: "4vw" }}
                                    disabled={isReadOnly || props.isTested || props.examValue.startStatus === 2}
                                    value={props.answerText ?? ""}
                                    onChange={(event) => props.handleTextChange(event.target.value)} />
                            :
                                props.question?.choices?.sort(orderByAsc).map((choice: Choice) => (
                                <div className={classNames.rowContainer} key={choice.id}>
                                    <Box>
                                        {
                                            props.question.answerType === 0 ?

                                                <Radio
                                                    style={{ paddingBottom: "23px", marginRight: getChoiceButtonMargin() }}
                                                    disabled={isReadOnly || props.isTested || props.examValue.startStatus === 2}
                                                    onClick={() => { props.handleChange(choice.id) }}
                                                    value={choice.id}
                                                    checked={props.selectedChoices.filter(s => s === choice.id).length > 0}
                                                    icon={<RadioButtonUncheckedIcon style={{ color: (isReadOnly || props.isTested || props.examValue.startStatus === 2) ? "#bdbdbd" : "#f50057" }} />}
                                                />
                                                :
                                                <Checkbox
                                                    style={{ paddingBottom: "23px", marginRight: getChoiceButtonMargin() }}
                                                    disabled={isReadOnly || props.isTested || props.examValue.startStatus === 2}
                                                    onClick={() => { props.handleChange(choice.id) }}
                                                    value={choice.id}
                                                    checked={props.selectedChoices.filter(s => s === choice.id).length > 0}
                                                    icon={<CheckBoxOutlineBlank style={{ color: (isReadOnly || props.isTested || props.examValue.startStatus === 2) ? "#bdbdbd" : "#f50057" }} />}
                                                />
                                        }
                                    </Box>
                                    <li key={choice.id}>
                                        <div className={classNames.rowContainer}>
                                            <div>&nbsp;</div>
                                            <div className="choiceTextStyle" onClick={(isReadOnly || props.isTested || props.examValue.startStatus === 2) ?
                                                () => { } :
                                                () => { props.handleChange(choice.id) }}>
                                                {choice.bodyText}   
                                            </div>
                                        </div>
                                    </li>
                                </div>
                            )) : <div></div>
                        }
                    </ul>
                    <ButtonContainer className={classNames.endButtonContainer} >
                        <DefaultButton hidden={props.hiddenFlags.end} onClick={handleEndClick}>{props.isTested ? "問題の表示を終了する" :
                        props.examValue.endViewFlag ? "解答終了確認へ" : "試験を終了する"}</DefaultButton>
                    </ButtonContainer>
                </Box>
            </div>

        </div>

    );
}