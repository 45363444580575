import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as common from "../../common";
import { Layout } from "../Layout";
import { makeTutorialCommonStyles } from '../Tutorial';
import usePCDeviceCheck from './PCDeviceCheckHook';
import { DefaultButton } from '../StylesUI/CommonLayouts';

const useStyles2 = makeTutorialCommonStyles();

const useStyles = makeStyles((theme: Theme) =>
({
    "@global": {
        ".MuiLinearProgress-root": {
            height: "12px", /* ★★　中山変更　★★ */
            borderRadius: "6px" /* ★★　中山変更　★★ */
        },
        ".MuiLinearProgress-colorPrimary": {
            backgroundColor: "rgb(221, 223, 238)" /* ★★　中山変更　★★ */
        },
        ".column2 > div": {
            width: "240px"
        }
    },
    mainContainer: {
        //display: "flex",
        //justifyContent: "center",
        "& .warningText": {
            color: "#c80e49"
        }
    },
    examNameStyle: {
        color: "#003f71",
        fontSize: common.FONT_SIZE.title,
        fontWeight: "bold",
        textAlign: "center"
    },
    subjectStyle: {
        marginRight: "25px",
        "& .discription": {
            fontSize: "1.4rem",
            marginBottom: "0"
        },
        "& .discription-red": {
            fontSize: "1.4rem",
            color: "#c80e49",
            marginBottom: "0"
        }
    },
    buttonContainer: {
        margin: "20px auto",
        textAlign: "center",
        "& h2": {
            fontSize: common.FONT_SIZE.title,
            fontWeight: "bold",
            color: "#00468b"
        },
        "& button": {
            fontSize: common.FONT_SIZE.button,
            padding: "0 2.8rem",
            minWidth: "120px",
            marginTop: "25px",
            height: "40px",
        },
        "& button:hover": {
            backgroundColor: "#003f71",
        },
        "& button:disabled": {
            backgroundImage: "none",
            backgroundColor: "#cccccc",
        }
    },
    pcTitleStyle: {
        margin: "20px auto",
        textAlign: "center",
        "& h2": {
            fontSize: common.FONT_SIZE.title,
            fontWeight: "bold",
            marginBottom: "4rem",
            color: "#00468b"
        },
        "& p": {
            fontSize: common.FONT_SIZE.mainText,
            margin: 0
        },
        "& #qrCode": {
            marginTop: "2rem",
            marginBottom: "2rem"
        },
        "& #Title": {
            marginTop: "4rem",
            marginBottom: "2rem"
        }
    },
    tblStyle: {
        tableLayout: "fixed",
        width: "80%",
        borderTop: "solid 1px #bebdc3",
        margin: "40px auto",
        fontSize: common.FONT_SIZE.mainText,
        "& caption": {
            captionSide: "top",
            textAlign: "center",
            fontSize: common.FONT_SIZE.mainText,
            fontWeight: "bold",
            position: "relative", /* ★★　中山変更　★★ */
            height: "calc(24px + 1.5rem)" /* ★★　中山変更　★★ */
        },
        "& td,th": {
            position: "relative",
            padding: "16px 20px",
            borderBottom: "solid 1px #bebdc3",
            color: "#00468b",
            fontWeight: "bold",
            textAlign: "center" /* ★★　中山変更　★★ */
        },
        "& td": {
            // width: "60%"
        },
        "& th": {
            color: "#4b4960",
            textAlign: "center", /* ★★　中山変更　★★ */
            fontWeight: "normal",
            whiteSpace: "nowrap",
            //width: "40%",
            backgroundColor: "#f0f0f0" /* ★★　中山変更　★★ */
        },
        "& th + td": {
            /* ★★　中山変更　★★ */
            textAlign: "left"
        },
        "& span": {
            display: "inline-block",
            verticalAlign: "middle"
        },
        "& #qrCode": {
            paddingLeft: "15rem"
        }
    },
    pcWarningTextStyle: {
        fontSize: common.FONT_SIZE.mainText,
        marginBottom: "0",
        marginTop: "5px",
        color: "#c80e49",
        textAlign: "center"
    },
    loadingStyle: {
        margin: "auto"
    },
    modalText: {
        "& .warning": {
            fontWeight: "bold",
            color: "red",
            fontSize: common.FONT_SIZE.responsive_14px,
            margin: "1rem auto 3rem",
            textAlign: "center"
        }
    }
}));

export function PCDeviceCheck() {
    const classNames = useStyles();
    const classNames2 = useStyles2();

    // イベントハンドラと状態変数
    const {
        isChecking,
        isValidScreen,
        isValidSpeed,
        isScreenChecked,
        isSpeedChecked,
        screens,
        up_progress_val,
        dl_progress_val,
        sp_download_mb,
        sp_upload_mb,
        onReturnDeviceCheck,
        onSaveDeviceCheckResult,
        onStartScreenCheck,
        onStartSpeedCheck
    } = usePCDeviceCheck();

    return (
        <Layout
            viewType={"pc"}
            width={"900px"}
            mainStyle={{
                margin: "140px auto"
            }}>
            <div className={classNames.mainContainer}>
                <div
                    className={classNames2.headerNav + " " + classNames2.imgBtn}>
                    <button onClick={onReturnDeviceCheck}>
                        <span>
                            <img
                                alt='デバイス適合確認のトップへ戻る'
                                src="/static/images/ico_setting.png" />
                        </span>
                        デバイス適合確認のトップへ戻る
                    </button>
                </div>
                <div>
                    <h1>スクリーン検証</h1>
                    <div
                        className={classNames.buttonContainer + " " + classNames2.imgBtn}>
                        <button
                            onClick={onStartScreenCheck}>
                            <span>
                                <img
                                    alt='スクリーン確認開始'
                                    src="/static/images/ico_desktop.png" />
                            </span>
                            <br />スクリーン確認開始
                        </button>
                    </div>
                    <table
                        hidden={!isScreenChecked}
                        className={classNames.tblStyle}>
                        <caption
                            className={classNames2.checkIcon + " "
                                + (isValidScreen ? classNames2.conf : classNames2.noconf)}>
                            スクリーン判定結果&nbsp;：&nbsp; {
                                isValidScreen ? "適合" : "不適合"
                            }
                        </caption>
                        <tr>
                            <th>スクリーン数</th>
                            <th>解像度（横）</th>
                            <th>解像度（縦）</th>
                        </tr>
                        <tr>
                            <td>
                                {screens.length}
                            </td>
                            <td>
                                {screens.length == 0 ? "" : screens[0].width} px
                            </td>
                            <td>
                                {screens.length == 0 ? "" : screens[0].height} px
                            </td>
                        </tr>
                    </table>
                </div>
                <div>
                    <h1>速度検証</h1>
                    <div
                        className={classNames.buttonContainer + " " + classNames2.imgBtn}>
                        <button onClick={onStartSpeedCheck}>
                            <span>
                                <img
                                    alt='速度確認開始'
                                    src="/static/images/ico_dashboard.png" />
                            </span>
                            <br />速度確認開始
                        </button>
                    </div>
                    <table className={classNames.tblStyle}>
                        <caption
                            className={
                                isSpeedChecked ? (
                                    classNames2.checkIcon + " " + (
                                        isValidSpeed ? classNames2.conf : classNames2.noconf
                                    )) : ""
                            }>
                            {
                                isSpeedChecked ? (
                                    "速度確認結果 : " +
                                    (isValidSpeed ? "適合" : "不適合")
                                ) : ""
                            }
                        </caption>
                        <tr>
                            <th>アップロード（動画サーバー）</th>
                            <td hidden={isSpeedChecked}> <LinearProgress variant="determinate" value={up_progress_val} /></td>
                            <td hidden={!isSpeedChecked}>{sp_upload_mb} Mbps</td>
                        </tr>
                        <tr>
                            <th>ダウンロード（動画サーバー）</th>
                            <td hidden={isSpeedChecked}> <LinearProgress variant="determinate" value={dl_progress_val} /></td>
                            <td hidden={!isSpeedChecked}>{sp_download_mb} Mbps</td>
                        </tr>
                    </table>
                </div>
                <div
                    className={classNames.buttonContainer}
                    hidden={isChecking}>
                    <DefaultButton onClick={onSaveDeviceCheckResult}>
                        検証結果をサーバーに保存
                    </DefaultButton>
                </div>
            </div>

            {/* 
            <div className={classNames.mainContainer}>
                <div className={classNames.buttonContainer}>
                    <DefaultButton onClick={onReturnDeviceCheck}>
                        デバイス適合確認のトップへ戻る
                    </DefaultButton>
                </div>

                <div>
                    <h1>スクリーン検証</h1>
                    <div className={classNames.buttonContainer}>
                        <DefaultButton onClick={onStartScreenCheck}>
                            スクリーン確認開始
                        </DefaultButton>
                    </div>
                    <table hidden={!isScreenChecked} className={classNames.tblStyle}>
                        <tr>
                            <th>スクリーン判定結果</th>
                            <th>スクリーン数</th>
                            <th>解像度（横）</th>
                            <th>解像度（縦）</th>
                        </tr>
                        <tr>
                            <td>
                                {
                                    isValidScreen ? "適合" : "不適合"
                                }
                            </td>
                            <td>
                                {screens.length}
                            </td>
                            <td>
                                {screens.length == 0 ? "" : screens[0].width} px
                            </td>
                            <td>
                                {screens.length == 0 ? "" : screens[0].height} px
                            </td>
                        </tr>
                    </table>
                </div>

                <div>
                    <h1>速度検証</h1>
                    <div className={classNames.buttonContainer}>
                        <DefaultButton onClick={onStartSpeedCheck}>
                            速度確認開始
                        </DefaultButton>
                    </div>
                    <table className={classNames.tblStyle}>
                        <caption>速度確認結果&nbsp;：&nbsp;
                            {isSpeedChecked ?
                                (isValidSpeed ? "適合" : "不適合"
                                ) : ""
                            }
                        </caption>
                        <tr>
                            <th>アップロード（動画サーバー）</th>
                            <td hidden={isSpeedChecked}> <LinearProgress variant="determinate" value={up_progress_val} /></td>
                            <td hidden={!isSpeedChecked}>{sp_upload_mb} Mbps</td>
                        </tr>
                        <tr>
                            <th>ダウンロード（動画サーバー）</th>
                            <td hidden={isSpeedChecked}> <LinearProgress variant="determinate" value={dl_progress_val} /></td>
                            <td hidden={!isSpeedChecked}>{sp_download_mb} Mbps</td>
                        </tr>
                    </table>
                </div>

                <div
                    className={classNames.buttonContainer}
                    hidden={isChecking}
                >
                    <DefaultButton onClick={onSaveDeviceCheckResult}>
                        検証結果をサーバーに保存
                    </DefaultButton>
                </div>
                <div className={classNames.buttonContainer}>
                    <DefaultButton onClick={onReturnDeviceCheck}>
                        デバイス適合確認のトップへ戻る
                    </DefaultButton>
                </div>
            </div> */}
        </Layout>
    );
}

