
import React from 'react';
import { useQRCode } from 'react-hook-qrcode';



export function QrCode(props: { text: string }) {

  const [inputRef]: any = useQRCode({
    text: props.text,
    options: {
      level: 'H',
      margin: 3,
      scale: 1,
      width: 200,
      color: {
        dark: '#3FB811',
        light: '#DDDDDD',
      },
    },
  });

  return (
    <span style={{ display: "block" }}>
      <canvas ref={inputRef} />
    </span>
  );
}