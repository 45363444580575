import * as signalR from "@microsoft/signalr";
import React, { useContext, useEffect, useRef } from "react";
import { AppContext } from "./App";
import { useCommon } from "./common";
import authService from './components/api-authorization/AuthorizeService';
import { Exam, IAppContext, Question, UserData, learner } from "./react-app-env";

/***********************************
 *  SignalRを利用した同期処理
 ***********************************/

const SEND_SYNC_EVENT_TYPE = "send_sync_data";

function debugLog(message: any) {
    if (window.sessionStorage["akaoni_debug"]) {
        console.log(message);
    }
}

function debugError(message: any) {
    if (window.sessionStorage["akaoni_debug"]) {
        console.error(message);
    }
}

/**
 * 同期メッセージ送信
 * @param sender_label 
 * @param data 
 */
export function sendSync(sender_label: string, data: any) {
    document.dispatchEvent(
        new CustomEvent(SEND_SYNC_EVENT_TYPE, {
            detail: JSON.stringify({ sender_label, data })
        }));
}

/**
 * SignalR接続インスタンス作成・更新
 * @param isFakeMode 
 * @returns 
 */
function updateSyncConnection(isFakeMode: boolean,
    appContext: IAppContext,
    setAppContext: (action: (currentContext: IAppContext) => Partial<IAppContext>) => void
) {
    const conn = new signalR.HubConnectionBuilder()
        .withUrl(isFakeMode ? "/anon_synchub" : "/synchub", {
            accessTokenFactory: isFakeMode ? undefined : async () => {
                const token = await authService.getAccessToken();
                return token as string;
            }
        })
        .withAutomaticReconnect()
        .build();
    conn.onclose((err) => {
        debugLog("conn close");
        debugError(err);
        // 接続が閉じたら再作成をトリガー
        setAppContext(c => {
            return {
                ...c,
                signalr_conn: undefined
            };
        });
    });
    conn.onreconnecting((err) => {
        debugLog("conn reconnecting");
        debugError(err);
    });
    conn.onreconnected((id) => {
        debugLog("conn reconnected: " + id);
    });
    // 作成した接続をappContextに登録
    setAppContext(c => {
        return {
            ...c,
            signalr_conn: conn
        };
    });
}

/**
 * 同期処理初期化フック
 */
export async function useSetupSync() {
    const { appContext, setAppContext, getUser } = useCommon();
    const isFakeMode = !!appContext.fakeapi_mode && !!appContext.fake_data;
    const [user, setUser] = React.useState<UserData>();

    React.useEffect(() => {
        getUser().then((u) => {
            setUser(u);
        });
    }, []);

    React.useEffect(() => {
        if (!appContext.signalr_conn
            || appContext.signalr_conn.state == signalR.HubConnectionState.Disconnected) {
            // 接続がなければ作成
            updateSyncConnection(isFakeMode, appContext, setAppContext);
        }
        if (isFakeMode && appContext.signalr_conn && !appContext.signalr_conn.baseUrl.endsWith("/anon_synchub")) {
            // fakemodeで接続がfakemodeになっていなければ更新
            updateSyncConnection(true, appContext, setAppContext);
        }
    }, [isFakeMode, !!user, !!appContext.signalr_conn]);

    React.useEffect(() => {
        return () => {
            debugLog("=========== call finalize code");
            // if (signalr_conn.state == signalR.HubConnectionState.Connected) {
            //     debugLog("try conn stop");
            //     signalr_conn.stop()
            //         .then(() => {
            //             debugLog("conn stop success");
            //         })
            //         .catch((err) => {
            //             debugLog("conn stop err");
            //             debugError(err);
            //         });
            // }
        };
    }, []);
}

/**
 * 各画面で同期の受信を設定する
 * @param receiver_label 
 * @param isMobile 
 * @param callback 
 */
export function useSync(receiver_label: string, isMobile: boolean, callback: (label: string, data: any) => any) {

    // チュートリアル用疑似試験モード判定
    const { appContext, setAppContext } = useContext(AppContext);
    const isFakeMode = appContext.fakeapi_mode && appContext.fake_data;

    // コールバック設定（最新の引数が反映されるようにrefを使う）
    const callbackRef = useRef(callback);
    callbackRef.current = callback;

    const signalr_conn = appContext.signalr_conn as signalR.HubConnection;

    // 同期トークン格納変数
    let send_token = "";

    // 同期メッセージ送信ハンドラ
    async function handler(ev: Event, retry: number = 0) {
        try {
            debugLog("======= invoke SendMessage =======");
            const _ev = ev as CustomEvent<string>;
            // {sender_label, data} 形式
            const send_data = JSON.parse(_ev.detail);
            await signalr_conn.invoke(
                "SendMessage",
                send_token,
                JSON.stringify(send_data),
                isFakeMode ? JSON.stringify(appContext.fake_data) : undefined);
        } catch (err) {
            debugLog("SendMessage err retry: " + retry);
            debugError(err);
            if (retry < 3) {
                await initSync(retry + 1);
                await handler(ev, retry + 1);
            }
        }
    }

    // 同期メッセージ受信ハンドラ
    function onReceiveMessage(token: string, json: string, context_json: string) {
        // 受信データの取り出し
        const { sender_label, data } = JSON.parse(json);
        debugLog("======= on ReceiveMessage =======");
        debugLog({ route: sender_label + " to " + receiver_label, data });

        if (!isFakeMode && token !== send_token) {
            // 同期token取り直し
            signalr_conn.invoke("GetSyncToken")
                .then((t) => {
                    send_token = t;
                    debugLog("GetSyncToken: " + send_token);
                }).catch((err) => {
                    debugLog("GetSyncToken error");
                });
        }

        // fake data 同期
        if (isFakeMode && context_json) {
            syncFakeData(setAppContext, isMobile, context_json);
        }

        // コールバックを呼び出す
        callbackRef.current(sender_label, data);
    }

    // 同期初期化関数
    async function initSync(retry: number = 0) {
        try {

            // 接続開始
            if (signalr_conn.state != signalR.HubConnectionState.Connected) {
                debugLog("try conn start");
                await signalr_conn.start();
                debugLog("conn started");
            }

            if (isFakeMode) {
                send_token = appContext.fake_synctoken ?? send_token;
                if (!send_token) {
                    debugError("send_tokenが空です");
                }
                // token登録
                try {
                    debugLog("RegisterSyncToken: " + send_token);
                    await signalr_conn.invoke("RegisterSyncToken", send_token);
                } catch (err) {
                    debugLog("RegisterSyncToken error");
                }
            } else {
                // 同期トークン取得
                const method = isMobile ? "GetSyncToken" : "UpdateSyncToken";
                try {
                    send_token = await signalr_conn.invoke(method);
                    debugLog(method + ": " + send_token);
                } catch (err) {
                }
            }
            // 同期メッセージ送信イベントにハンドラ設定
            document.removeEventListener(SEND_SYNC_EVENT_TYPE, handler);
            document.addEventListener(SEND_SYNC_EVENT_TYPE, handler);
            // 同期メッセージ受信イベントにハンドラ設定     
            signalr_conn.off("ReceiveMessage");
            signalr_conn.on("ReceiveMessage", onReceiveMessage);
        } catch (err) {
            // リトライ
            if (retry < 3) {
                await initSync(retry + 1);
            }
        }
    }

    // 初期処理
    useEffect(() => {
        // async関数を実行        
        initSync();
        return () => {
            // 以下、接続の後始末
            try {
                // if (signalr_conn.state == signalR.HubConnectionState.Connected) {
                //     debugLog("try conn stop");
                //     signalr_conn.stop()
                //         .then(() => {
                //             debugLog("conn stop success");
                //         })
                //         .catch((err) => {
                //             debugLog("conn stop err");
                //             debugError(err);
                //         });
                // }
                signalr_conn.off("ReceiveMessage");
            } catch (err) {

            }
            document.removeEventListener(SEND_SYNC_EVENT_TYPE, handler);
        };
    }, [isFakeMode, signalr_conn?.connectionId]);
}

/**
 * チュートリアル試験モードでの模擬データの同期処理
 * @param setAppContext 
 * @param isMobile 
 * @param context_json 
 */
function syncFakeData(
    setAppContext: (action: (currentContext: IAppContext) => Partial<IAppContext>) => void,
    isMobile: boolean,
    context_json: string) {
    const sender_fake_data = JSON.parse(context_json) as {
        user: learner;
        exam: Exam;
        questions: Question[];
    };
    setAppContext(c => {
        let fake_data = c.fake_data;
        if (fake_data) {

            // statusをつぎに動かすとき同期
            // 0:受験前 1:受験開始後 2:受験終了後         
            if (fake_data.exam.startStatus <=
                sender_fake_data.exam.startStatus) {

                // 開始時のみ  
                fake_data.exam.startSeconds =
                    sender_fake_data.exam.startSeconds;
                fake_data.user.executionStartDatetime
                    = sender_fake_data.user.executionStartDatetime;

                // 開始と終了時
                fake_data.exam.endSeconds = sender_fake_data.exam.endSeconds;
                fake_data.exam.startStatus = sender_fake_data.exam.startStatus;
                fake_data.user.executionEndDatetime
                    = sender_fake_data.user.executionEndDatetime;
                fake_data.user.startStatus
                    = sender_fake_data.user.startStatus;
            }

            // 回答状態はモバイルからの情報に同期
            if (!isMobile) {
                fake_data.questions.forEach(q => {
                    sender_fake_data.questions.forEach(sq => {
                        if (q.id == sq.id) {
                            // 選択状態をコピー
                            q.choices = sq.choices.slice();
                        }
                    });
                });
            }

            // 残り時間 PC=>Mobileのみ同期
            if (isMobile) {
                fake_data.exam.endSeconds = sender_fake_data.exam.endSeconds;
            }

            // 退席
            fake_data.user.leaveFlag = sender_fake_data.user.leaveFlag;
        } else {
            fake_data = sender_fake_data;
        }
        return {
            ...c,
            fake_data: fake_data
        };
    });
}
