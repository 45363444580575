import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import * as common from "../../common";
import Modal from '@material-ui/core/Modal';
import { DefaultButton, ButtonContainer, DefaultBackButton } from './CommonLayouts';
import { isMobile } from 'react-device-detect';

// 引数で生成するCSSを定義してuseStyles関数を生成
const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: "flex",
        justifyContent: "center",
        "& button": {
            fontSize: isMobile ? common.FONT_SIZE.responsive_16px : common.FONT_SIZE.mainText,
            minWidth: "120px",
        },
        "& button:hover": {
            backgroundColor: "#003f71",
        },
        "& .backBtn:hover": {
            backgroundColor: "#003f71",
            color: "white"
        },
    },
    paper: {
        position: 'absolute',
        minWidth: isMobile ? "95%" : 420,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        "& #simple-modal-title": {
            fontWeight: "bold",
            margin: "3rem auto",
            textAlign: "center"
        }
    },

}));

export interface CustomModalProps {
    isOpen: boolean;
    setIsOpenFunc: any;
    onOKFunc: any;
    onCloseFunc?: any;
    isNotice?: boolean;
    children: JSX.Element;
}

export function CustomModal(props: CustomModalProps) {

    // -----スタイルの宣言-----
    const classNames = useStyles();

    //----定数の定義-------
    const isNotice = props.isNotice === undefined ? false : props.isNotice;
    const css_top = 50;
    const css_left = 50;

    //----ハンドラの定義-------
    function handleClose() {
        if (props.onCloseFunc !== undefined) {
            props.onCloseFunc();
        }
        props.setIsOpenFunc(false);
    }
    return (
        <Modal
            open={props.isOpen}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={{
                top: `${css_top}%`,
                left: `${css_left}%`,
                transform: `translate(-${css_top}%, -${css_left}%)`,
            }} className={classNames.paper}>
                {props.children}
                <ButtonContainer className={classNames.buttonContainer} >
                    <DefaultButton
                        style={{
                            marginLeft: "0rem",
                            marginRight: "0",
                        }}
                        onClick={() => {
                            props.onOKFunc();
                            handleClose();
                        }}>
                        {isNotice ? "OK" : "はい"}
                    </DefaultButton>
                    <DefaultBackButton
                        style={{
                            marginLeft: "3rem",
                            marginRight: "0",
                            display: isNotice ? "none" : "inline-block"
                        }}
                        className="backBtn"
                        onClick={handleClose}>
                        いいえ
                    </DefaultBackButton>
                </ButtonContainer>
            </div>
        </Modal>
    );
}
