import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { isMobile } from 'react-device-detect';
import * as common from "../common";
import { initFakeData } from '../fakeapi';
import { Layout } from "./Layout";
import { DefaultButton } from './StylesUI/CommonLayouts';
import { v4 as uuidv4 } from 'uuid';
import { useLocation } from "react-router-dom";

/**
 * チュートリアル系画面の共通スタイル（中山さんデザイン）
 * @returns 
 */
export function makeTutorialCommonStyles() {
    return makeStyles((th: Theme) =>
    ({
        "@global": {
            "#mainCon h1": {
                padding: "8px",
                margin: "0 0 20px",
                borderTop: "solid 4px #76a6d4",
                borderBottom: "solid 1px #d0d0d0",
                background: "linear-gradient(to bottom, rgb(240, 240, 240) 0%, rgb(255, 255, 255) 100%)",
                color: "#333",
                fontSize: "2rem",
                fontWeight: "bold",
                textAlign: "left"
            },
            "#qrCode": {
                textAlign: "center"
            }
        },
        icon: {
            display: "inline-block",
            marginRight: "8px",
            "& img": {
                width: "32px",
                height: "32px",
            }
        },
        column2: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            "& > div": {
                margin: 0,
                width: "220px",
                textAlign: "center"
            }
        },
        imgBtn: {
            marginBottom: "40px !important",
            "&$headerNav": {
                marginBottom: "0 !important",
            },
            "& button": {
                minwidth: "initial !important",
                height: "auto !important",
                padding: "0 !important",
                margin: "0 !important",
                border: "none !important",
                backgroundColor: "transparent !important",
                color: "#4378b6",
                fontWeight: "bold",
            },
            "& button:hover": {
                backgroundColor: "transparent"
            },
            "& button:focus": {
                outline: "none"
            },
            "& button > span": {
                display: "inline-block",
                marginBottom: "1rem",
                padding: "8px",
                borderRadius: "12px",
            },
            "& button:hover > span": {
                backgroundColor: "#76a6d4"
            },

            "& button:hover > span > img": {
                filter: "brightness(0) invert(1)"
            },
            "& button > span > img": {
                width: "128px",
                height: "128px"
            }
        },
        headerNav: {
            "& button": {
                fontSize: "1.2rem"
            },
            "& button > span": {
                marginRight: "4px",
                padding: "4px",
                borderRadius: "4px"
            },
            "& button > span > img": {
                width: "32px",
                height: "32px"
            }
        },
        checkIcon: {
            "&::after": {
                content: "\"\"",
                position: "absolute",
                display: "inline-block",
                marginLeft: "4px",
                width: "24px",
                height: "24px"
            }
        },
        noconf: {
            color: "#c5003f",
            "&::after": {
                background: "transparent url(/static/images/ico_no.png) no-repeat left top / contain",
            }
        },
        conf: {
            color: "#32b16c",
            "&::after": {
                background: "transparent url(/static/images/ico_ok.png) no-repeat left top / contain"
            }
        },
    }));
}

const useStyles2 = makeTutorialCommonStyles();

const useStyles = makeStyles((theme: Theme) =>
({
    mainContainer: {
        //display: "flex",
        //justifyContent: "center",
        "& .warningText": {
            color: "#c80e49"
        }
    },
    examNameStyle: {
        color: "#003f71",
        fontSize: common.FONT_SIZE.title,
        fontWeight: "bold",
        textAlign: "center"
    },
    subjectStyle: {
        marginRight: "25px",
        "& .discription": {
            fontSize: "1.4rem",
            marginBottom: "0"
        },
        "& .discription-red": {
            fontSize: "1.4rem",
            color: "#c80e49",
            marginBottom: "0"
        }
    },
    buttonContainer: {
        margin: "20px auto",
        textAlign: "center",
        "& h2": {
            fontSize: common.FONT_SIZE.title,
            fontWeight: "bold",
            color: "#00468b"
        },
        "& button": {
            fontSize: common.FONT_SIZE.button,
            padding: "0 2.8rem",
            minWidth: "120px",
            marginTop: "25px",
            height: "40px",
        },
        "& button:hover": {
            backgroundColor: "#003f71",
        },
        "& button:disabled": {
            backgroundImage: "none",
            backgroundColor: "#cccccc",
        }
    },
    mobileButtonContainer: {
        paddingTop: common.FONT_SIZE.responsive_24px,
        textAlign: "center",
        //backgroundColor: "#003f71",
        "& button": {
            fontSize: common.FONT_SIZE.responsive_16px,
            minWidth: "50vw",
            borderRadius: "5.3vw"
        },
        "& button:hover": {
            backgroundColor: "#003f71",
        },
        "& button:disabled": {
            backgroundImage: "none",
            backgroundColor: "#cccccc",
        }
    },
    pcTitleStyle: {
        margin: "20px auto",
        textAlign: "center",
        "& h2": {
            fontSize: common.FONT_SIZE.title,
            fontWeight: "bold",
            marginBottom: "4rem",
            color: "#00468b"
        },
        "& p": {
            fontSize: common.FONT_SIZE.mainText,
            margin: 0
        },
        "& #qrCode": {
            marginTop: "2rem",
            marginBottom: "2rem"
        },
        "& #Title": {
            marginTop: "4rem",
            marginBottom: "2rem"
        }
    },
    mobileTitleStyle: {
        textAlign: "center",
        backgroundColor: "white",
        padding: "8.5vw 2.1vw",
        "& h2": {
            fontSize: common.FONT_SIZE.responsive_24px,
            fontWeight: "bold",
            color: "#00468b",
            marginBottom: common.FONT_SIZE.responsive_18px
        },
        "& p": {
            fontSize: common.FONT_SIZE.responsive_14px,
            margin: 0
        },
        "& li": {
            fontSize: common.FONT_SIZE.responsive_14px,
            margin: 0,
            textAlign: "left"
        },
        "& ul": {
            paddingLeft: "20px"
        }
    },
    mobileTblStyle: {
        margin: "0 auto",
        marginTop: common.FONT_SIZE.responsive_24px,
        fontSize: common.FONT_SIZE.responsive_16px,
        backgroundColor: "#003f71",
        tableLayout: "fixed",
        width: "auto",
        //width: "100%",
        "& td,th,a": {
            position: "relative",
            color: "#accb39",
            fontWeight: "bold",
            textAlign: "left",
            //textAlign: "center"
        },
        "& td": {
            fontSize: common.FONT_SIZE.responsive_18px,
            padding: "2.1vw 0 4.27vw 6vw"
            //padding:"2.1vw 0 4.27vw"
        },
        "& th": {
            color: "#b7cde6",
            textAlign: "left",
            //textAlign: "center",
            fontWeight: "normal",
            whiteSpace: "nowrap",
            paddingTop: "2.1vw",
        },
        "& span": {
            display: "inline-block",
            verticalAlign: "middle"
        },
        "& .MuiSvgIcon-fontSizeSmall": {
            fontSize: common.FONT_SIZE.responsive_16px,
        },
        "& tbody": {
            padding: "6.4vw",
        }
    },
    tblStyle: {
        tableLayout: "fixed",
        width: "80%",
        borderTop: "solid 1px #bebdc3",
        margin: "40px auto",
        fontSize: common.FONT_SIZE.mainText,
        "& td,th": {
            position: "relative",
            padding: "16px 20px",
            borderBottom: "solid 1px #bebdc3",
            color: "#00468b",
            fontWeight: "bold",
        },
        "& td": {
            width: "75%"
        },
        "& th": {
            color: "#4b4960",
            textAlign: "left",
            fontWeight: "normal",
            whiteSpace: "nowrap",
            width: "25%"
        },
        "& span": {
            display: "inline-block",
            verticalAlign: "middle"
        },
        "& #qrCode": {
            paddingLeft: "15rem"
        }
    },
    mobileHeaderCon: {
        padding: "7.47vw 2.1vw",
        background: "radial-gradient(ellipse at center bottom, rgba(0,137,190,1) 0%,rgba(5,49,113,1) 100%)",
    },
    mobileHeaderTbl: {
        fontSize: common.FONT_SIZE.responsive_16px,
        margin: "0 auto",
        width: "auto",
        "& td,th": {
            position: "relative",
            padding: "2.1vw 2.6vw",
            color: "white",
            fontWeight: "bold",
        },
        "& td": {
            fontSize: common.FONT_SIZE.responsive_18px,
        },
        "& th": {
            color: "#b7cde6",
            textAlign: "left",
            fontWeight: "normal",
            whiteSpace: "nowrap",
        },
        "& span": {
            display: "inline-block",
            verticalAlign: "middle"
        },
        "& .MuiSvgIcon-fontSizeSmall": {
            fontSize: common.FONT_SIZE.responsive_16px,
        }
    },
    pcWarningTextStyle: {
        fontSize: common.FONT_SIZE.mainText,
        marginBottom: "0",
        marginTop: "5px",
        color: "#c80e49",
        textAlign: "center"
    },
    mobileWarningTextStyle: {
        padding: "0 2.6vw",
        fontSize: common.FONT_SIZE.responsive_16px,
        color: "#c80e49;",
        marginTop: common.FONT_SIZE.responsive_16px,
        textAlign: "center",
        "& .offHours": {
            margin: 0
        }
    },
    loadingStyle: {
        margin: "auto"
    },
    modalText: {
        "& .warning": {
            fontWeight: "bold",
            color: "red",
            fontSize: common.FONT_SIZE.responsive_14px,
            margin: "1rem auto 3rem",
            textAlign: "center"
        }
    }
}));

export function Tutorial() {
    const classNames = useStyles();
    const classNames2 = useStyles2();
    const {
        go,
        appContext,
        setAppContext
    } = common.useCommon();

    const [isMobileFlag, setIsMobileFlag] = React.useState<boolean>(false);
    React.useEffect(() => {
        setIsMobileFlag(isMobile);
    }, []);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const param_token = searchParams.get("token");
    return (
        <Layout
            viewType={isMobileFlag ? "mobile" : "pc"}
            width={isMobileFlag ? "90%" : "900px"}>

            <div className={classNames.mainContainer}>
                <h1>チュートリアル</h1>
                <div className={classNames2.column2 + " " + classNames2.imgBtn}>
                    {/*  <!-- ↓デバイス適合確認 --> */}
                    <div className={isMobileFlag ? classNames.mobileButtonContainer : classNames.buttonContainer}>
                        <button onClick={() => {
                            // 適合確認は非fake mode
                            setAppContext(c => {
                                return {
                                    ...c,
                                    fakeapi_mode: false,
                                    fake_data: undefined,
                                    fake_synctoken: undefined
                                };
                            });
                            go("/devicecheck");
                        }}>
                            <span>
                                <img alt='デバイス適合確認'
                                    src="/static/images/ico_setting.png" />
                            </span>
                            <br />デバイス適合確認
                        </button>
                    </div>
                    {/*  <!-- ↓画面操作テスト？で合ってますか？ --> */}
                    <div hidden={isMobile && !param_token}>
                        <button onClick={() => {
                            // fake mode 開始
                            let token = "";
                            if (isMobile) {
                                // mobile側ならPC側から渡ったパラメータ
                                token = param_token ?? "";
                            } else {
                                // PC側ならsync_token生成
                                token = uuidv4();
                            }
                            setAppContext(c => {
                                // 接続を切り替える
                                let signalr_conn: any;
                                if (c.signalr_conn?.baseUrl.endsWith("/synchub")) {
                                    try {
                                        c.signalr_conn.stop();
                                    } catch (err) {

                                    }
                                    signalr_conn = undefined;
                                } else {
                                    signalr_conn = c.signalr_conn;
                                }
                                return {
                                    ...c,
                                    fakeapi_mode: true,
                                    fake_data: initFakeData(),
                                    fake_synctoken: token,
                                    signalr_conn
                                };
                            });
                            go("/tutorial/home");
                        }} >
                            <span>
                                <img alt='画面操作テスト'
                                    src="/static/images/ico_pencil-line.png" />
                            </span>
                            <br />画面操作テスト
                        </button>
                    </div>
                    {/*  <!-- ↓画面操作ガイド　guide.htmlのページに移動 --> */}
                    <div>
                        <button onClick={() => {
                            go("/tutorial-guide");
                        }}>
                            <span>
                                <img alt='画面操作ガイド'
                                    src="/static/images/ico_book.png" />
                            </span>
                            <br />画面操作ガイド
                        </button>
                    </div>
                </div>
            </div>
            <div hidden={!isMobile || !!param_token}>
                モバイル端末の画面操作の説明には、<br />
                PC側で表示されたQRコードからアクセスしてください。
            </div>
            {/* 
            <div className={classNames.mainContainer}>
                <h1>チュートリアル</h1>
                <div className={isMobileFlag ? classNames.mobileButtonContainer : classNames.buttonContainer}>
                    <DefaultButton onClick={() => {
                        // 適合確認は非fake mode
                        setAppContext(c => {
                            return {
                                ...c,
                                fakeapi_mode: false,
                                fake_data: undefined,
                                fake_synctoken: undefined
                            };
                        });
                        go("/devicecheck");
                    }} >
                        デバイス適合確認
                    </DefaultButton>
                    <div>&nbsp;</div>
                    <div hidden={isMobile && !param_token}>
                        <DefaultButton onClick={() => {
                            // fake mode 開始
                            let token = "";
                            if (isMobile) {
                                // mobile側ならPC側から渡ったパラメータ
                                token = param_token ?? "";
                            } else {
                                // PC側ならsync_token生成
                                token = uuidv4();
                            }
                            setAppContext(c => {
                                // 接続を切り替える
                                let signalr_conn: any;
                                if (c.signalr_conn?.baseUrl.endsWith("/synchub")) {
                                    try {
                                        c.signalr_conn.stop();
                                    } catch (err) {

                                    }
                                    signalr_conn = undefined;
                                } else {
                                    signalr_conn = c.signalr_conn;
                                }
                                return {
                                    ...c,
                                    fakeapi_mode: true,
                                    fake_data: initFakeData(),
                                    fake_synctoken: token,
                                    signalr_conn
                                };
                            });
                            go("/tutorial/home");
                        }} >
                            画面操作の説明
                        </DefaultButton>
                    </div>
                    <div hidden={!isMobile || !!param_token}>
                        モバイル端末の画面操作の説明には、<br />
                        PC側で表示されたQRコードからアクセスしてください。
                    </div>
                </div>
            </div> */}
        </Layout>
    );
}