import React from 'react';
import { isMobile } from 'react-device-detect';

import { makeStyles, Theme } from '@material-ui/core/styles';
import * as common from "../common";
import { Layout } from "./Layout";
import { QrCode } from './QrCode';
import { makeTutorialCommonStyles } from './Tutorial';

const useStyles2 = makeTutorialCommonStyles();

const useStyles = makeStyles((theme: Theme) =>
({
    mainContainer: {
        //display: "flex",
        //justifyContent: "center",
        "& .warningText": {
            color: "#c80e49"
        }
    },
    examNameStyle: {
        color: "#003f71",
        fontSize: common.FONT_SIZE.title,
        fontWeight: "bold",
        textAlign: "center"
    },
    subjectStyle: {
        marginRight: "25px",
        "& .discription": {
            fontSize: "1.4rem",
            marginBottom: "0"
        },
        "& .discription-red": {
            fontSize: "1.4rem",
            color: "#c80e49",
            marginBottom: "0"
        }
    },
    buttonContainer: {
        margin: "20px auto",
        textAlign: "center",
        "& h2": {
            fontSize: common.FONT_SIZE.title,
            fontWeight: "bold",
            color: "#00468b"
        },
        "& button": {
            fontSize: common.FONT_SIZE.button,
            padding: "0 2.8rem",
            minWidth: "120px",
            marginTop: "25px",
            height: "40px",
        },
        "& button:hover": {
            backgroundColor: "#003f71",
        },
        "& button:disabled": {
            backgroundImage: "none",
            backgroundColor: "#cccccc",
        }
    },
    mobileButtonContainer: {
        paddingTop: common.FONT_SIZE.responsive_24px,
        textAlign: "center",
        //backgroundColor: "#003f71",
        "& button": {
            fontSize: common.FONT_SIZE.responsive_16px,
            minWidth: "50vw",
            borderRadius: "5.3vw"
        },
        "& button:hover": {
            backgroundColor: "#003f71",
        },
        "& button:disabled": {
            backgroundImage: "none",
            backgroundColor: "#cccccc",
        }
    },
    pcTitleStyle: {
        margin: "20px auto",
        textAlign: "center",
        "& h2": {
            fontSize: common.FONT_SIZE.title,
            fontWeight: "bold",
            marginBottom: "4rem",
            color: "#00468b"
        },
        "& p": {
            fontSize: common.FONT_SIZE.mainText,
            margin: 0
        },
        "& #qrCode": {
            marginTop: "2rem",
            marginBottom: "2rem"
        },
        "& #Title": {
            marginTop: "4rem",
            marginBottom: "2rem"
        }
    },
    mobileTitleStyle: {
        textAlign: "center",
        backgroundColor: "white",
        padding: "8.5vw 2.1vw",
        "& h2": {
            fontSize: common.FONT_SIZE.responsive_24px,
            fontWeight: "bold",
            color: "#00468b",
            marginBottom: common.FONT_SIZE.responsive_18px
        },
        "& p": {
            fontSize: common.FONT_SIZE.responsive_14px,
            margin: 0
        },
        "& li": {
            fontSize: common.FONT_SIZE.responsive_14px,
            margin: 0,
            textAlign: "left"
        },
        "& ul": {
            paddingLeft: "20px"
        }
    },
    mobileTblStyle: {
        margin: "0 auto",
        marginTop: common.FONT_SIZE.responsive_24px,
        fontSize: common.FONT_SIZE.responsive_16px,
        backgroundColor: "#003f71",
        tableLayout: "fixed",
        width: "auto",
        //width: "100%",
        "& td,th,a": {
            position: "relative",
            color: "#accb39",
            fontWeight: "bold",
            textAlign: "left",
            //textAlign: "center"
        },
        "& td": {
            fontSize: common.FONT_SIZE.responsive_18px,
            padding: "2.1vw 0 4.27vw 6vw"
            //padding:"2.1vw 0 4.27vw"
        },
        "& th": {
            color: "#b7cde6",
            textAlign: "left",
            //textAlign: "center",
            fontWeight: "normal",
            whiteSpace: "nowrap",
            paddingTop: "2.1vw",
        },
        "& span": {
            display: "inline-block",
            verticalAlign: "middle"
        },
        "& .MuiSvgIcon-fontSizeSmall": {
            fontSize: common.FONT_SIZE.responsive_16px,
        },
        "& tbody": {
            padding: "6.4vw",
        }
    },
    tblStyle: {
        tableLayout: "fixed",
        width: "80%",
        borderTop: "solid 1px #bebdc3",
        margin: "40px auto",
        fontSize: common.FONT_SIZE.mainText,
        "& td,th": {
            position: "relative",
            padding: "16px 20px",
            borderBottom: "solid 1px #bebdc3",
            color: "#00468b",
            fontWeight: "bold",
        },
        "& td": {
            width: "75%"
        },
        "& th": {
            color: "#4b4960",
            textAlign: "left",
            fontWeight: "normal",
            whiteSpace: "nowrap",
            width: "25%"
        },
        "& span": {
            display: "inline-block",
            verticalAlign: "middle"
        },
        "& #qrCode": {
            paddingLeft: "15rem"
        }
    },
    mobileHeaderCon: {
        padding: "7.47vw 2.1vw",
        background: "radial-gradient(ellipse at center bottom, rgba(0,137,190,1) 0%,rgba(5,49,113,1) 100%)",
    },
    mobileHeaderTbl: {
        fontSize: common.FONT_SIZE.responsive_16px,
        margin: "0 auto",
        width: "auto",
        "& td,th": {
            position: "relative",
            padding: "2.1vw 2.6vw",
            color: "white",
            fontWeight: "bold",
        },
        "& td": {
            fontSize: common.FONT_SIZE.responsive_18px,
        },
        "& th": {
            color: "#b7cde6",
            textAlign: "left",
            fontWeight: "normal",
            whiteSpace: "nowrap",
        },
        "& span": {
            display: "inline-block",
            verticalAlign: "middle"
        },
        "& .MuiSvgIcon-fontSizeSmall": {
            fontSize: common.FONT_SIZE.responsive_16px,
        }
    },
    pcWarningTextStyle: {
        fontSize: common.FONT_SIZE.mainText,
        marginBottom: "0",
        marginTop: "5px",
        color: "#c80e49",
        textAlign: "center"
    },
    mobileWarningTextStyle: {
        padding: "0 2.6vw",
        fontSize: common.FONT_SIZE.responsive_16px,
        color: "#c80e49;",
        marginTop: common.FONT_SIZE.responsive_16px,
        textAlign: "center",
        "& .offHours": {
            margin: 0
        }
    },
    loadingStyle: {
        margin: "auto"
    },
    modalText: {
        "& .warning": {
            fontWeight: "bold",
            color: "red",
            fontSize: common.FONT_SIZE.responsive_14px,
            margin: "1rem auto 3rem",
            textAlign: "center"
        }
    }
}));

export function DeviceCheck() {
    const classNames = useStyles();
    const classNames2 = useStyles2();
    const {
        go
    } = common.useCommon();

    const [isMobileFlag, setIsMobileFlag] = React.useState<boolean>(false);
    React.useEffect(() => {
        setIsMobileFlag(isMobile);
    }, []);

    return (
        <Layout
            viewType={isMobileFlag ? "mobile" : "pc"}
            width={isMobileFlag ? "90%" : "900px"}>
            <div className={classNames.mainContainer}>
                <div className={classNames2.headerNav + " " + classNames2.imgBtn}>
                    <button onClick={() => { go("/tutorial") }}>
                        <span>
                            <img alt="チュートリアルのトップへ戻る"
                                src="/static/images/ico_return.png" />
                        </span>
                        チュートリアルのトップへ戻る
                    </button>
                </div>
                <h1>デバイス適合確認</h1>
                <div className={isMobileFlag ? classNames.mobileButtonContainer : classNames.buttonContainer}>
                    <p>デバイスが本システムに適合しているかの確認を行います。</p>
                    <div className={classNames2.imgBtn}>
                        <button
                            onClick={() => {
                                go(isMobileFlag ? "/mobile/devicecheck" : "/pc/devicecheck")
                            }}
                        >
                            <span>
                                <img alt='デバイス適合確認開始'
                                    src="/static/images/ico_setting.png" />
                            </span>
                            <br />
                            デバイス適合確認開始
                        </button>
                    </div>
                </div>
                <div hidden={isMobile} className={isMobileFlag ? classNames.mobileButtonContainer : classNames.buttonContainer}>
                    <hr />
                    <p>
                        <span className={classNames2.icon}>
                            <img alt="QRCode"
                                src="/static/images/ico_mobile.png" />
                        </span>
                        モバイル端末の適合確認は以下のQRコードのURLからアクセスしてください。
                    </p>
                    <p id="qrCode">
                        <QrCode text={
                            window.location.origin + "/mobile/devicecheck"
                        } />
                    </p>
                </div>

                {/* <div className={classNames.buttonContainer}>
                    <DefaultButton onClick={() => { go("/tutorial") }}>
                        チュートリアルのトップへ戻る
                    </DefaultButton>
                </div>
                <h1>デバイス適合確認</h1>
                <div>
                    <p>
                        デバイスが本システムに適合しているかの確認を行います。
                    </p>
                </div>
                <div className={isMobileFlag ? classNames.mobileButtonContainer : classNames.buttonContainer}>
                    <DefaultButton onClick={() => {
                        go(isMobileFlag ? "/mobile/devicecheck" : "/pc/devicecheck")
                    }} >
                        デバイス適合確認開始
                    </DefaultButton>
                    <div hidden={isMobile}>
                        <hr />
                        <p>
                            モバイル端末の適合確認は以下のQRコードのURLからアクセスしてください。
                        </p>
                        <p id="qrCode">
                            <QrCode text={
                                window.location.origin + "/mobile/devicecheck"
                            } />
                        </p>
                    </div>
                </div> */}
            </div>
        </Layout>
    );
}