
import React from 'react';
import { Box } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import TextField from '@material-ui/core/TextField';

import { Question, Choice, hiddenFlags, PostQuestionArgs, Exam } from "../../../react-app-env";
import { RichEditor } from '../../RichEditor/RichEditor'
import { DefaultButton, ButtonContainer, DefaultBackButton } from '../../StylesUI/CommonLayouts';
import * as common from "../../../common";
import '../../CounterStyles.module.css';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';

const useStyles = makeStyles((theme: Theme) =>
({
    questionRoot: {
        "& #questionHeaderNum": {
            margin: "0 0 12px",
            padding: " 4px 16px",
            backgroundColor: "#003f71",
            color: "#ffffff",
            fontSize: common.FONT_SIZE.questionHeader,
            fontWeight: "bold"
        },
        "& #parentQuestionHeaderNum": {
            backgroundColor: "#003f71",
            color: "#ffffff",
            fontSize: common.FONT_SIZE.questionHeader,
            fontWeight: "bold",
            border: "solid 1px",
            padding: "0px 10px",
            width: "fit-content"
        }
    },
    choiceContainer: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        //width: "80%",
        "& svg": {
            fontSize: "24px"
        },
        "& .choiceTextStyle": {
            whiteSpace: "pre-wrap",
            wordBreak: "break-all",
            lineHeight: "2rem",
            width:"100%",
            fontSize:common.FONT_SIZE.choiceText,
            color: "black",
            paddingLeft: "5px",
            paddingBottom: "15px"
        },
        "& .MuiInput-underline.Mui-disabled:before":{
            borderBottomStyle:"solid"
        }
    },
    headerContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        fontSize: common.FONT_SIZE.choiceText,
        marginBottom: "10px",
        "& .MuiAccordionDetails-root": {
            padding: 0
        },
        "& #accordionSubjectText": {
            flexBasis: '90%',
            fontSize: common.FONT_SIZE.mainText
        }
    },
    leftElementStyle: {
        flexBasis: "75%"
    },
    buttonContainer: {
        flexBasis: "75%",
        marginTop: "12px",
        "& button": {
            fontSize: common.FONT_SIZE.button,
            minWidth:"26%",
            padding: "0 2.8rem",
            height: "40px"
        },
        "& button:hover": {
            backgroundColor: "#003f71",
            outline:0
        },
        "& .backBtn:hover": {
            backgroundColor: "#003f71",
            color: "white",
            outline:0
        },
        "& span": {
            paddingBottom: "2px"
        }
    },
    choicesListStyle: {
        "& li": {
            //marginBottom: "15px",
        },
        "& h4": {
            fontSize: "1.8rem",
        }
    },
    questionSizeContainer: {
        display: "flex",
        justifyContent: "flex-start",
        fontSize: common.FONT_SIZE.mainText,
        marginBottom: "12px"
    },
    expandText: {
        fontSize: common.FONT_SIZE.mainText,
        fontWeight: "bold",
        color: "#003f71",
        flexBasis: '10%',
        minWidth: "39px ",
        textAlign: "right"
    }

}));





export function QuestionContents(props: { activeItemKey: number; isLoading: boolean, isExpanded: boolean, remainingTime: number, examValue: Exam, isTested: boolean, selectedChoices: number[], answerText: string, question: Question, hiddenFlags: hiddenFlags, handleReturnClick: any, handleNextClick: any, handleChange: any, handleTextChange: any, handleEndClickNoEndView: any, handleEndClickExsistEndView: any, handleEndClickAfterTested: any, handleExpandChange: any, handleEndByViewDevice: any, getQuestionHeadnum: any }) {

    // -----汎用関数定義-----
    function orderByAsc(a: { viewOrder: number }, b: { viewOrder: number }) {
        if (a.viewOrder < b.viewOrder) return -1;
        if (a.viewOrder > b.viewOrder) return 1;
        return 0;
    }


    const handleReturnClick = () => {
        const answerTextData = props.answerText ?? '';
        const data: PostQuestionArgs = {
            id: props.question.id,
            choices: props.selectedChoices.map(id => (
                { choiceId: id }
            )),
            answerText: answerTextData
        }
        props.handleReturnClick(data);
    }

    const handleNextClick = () => {
        if (props.question.page === props.examValue.pageMax - 2 && props.examValue.endViewFlag && props.isTested) {
            handleEndClick();
        }
        const answerTextData = props.answerText ?? '';
        const data: PostQuestionArgs = {
            id: props.question.id,
            choices: props.selectedChoices.map(id => (
                { choiceId: id }
            )),
            answerText: answerTextData
        }
        props.handleNextClick(data);
    }



    //試験終了処理
    const handleEndClick = () => {
        
        if (props.isTested) {
            props.handleEndClickAfterTested();
        }
        /*else if (props.examValue.choiceDeviceFlag) {
            props.handleEndByViewDevice();
        }*/
        else if (props.examValue.endViewFlag) {

            const answerTextData = props.answerText ?? '';
            const data: PostQuestionArgs = {
                id: props.question.id,
                choices: props.selectedChoices.map(id => (
                    { choiceId: id }
                )),
                answerText: answerTextData
            };
            props.handleEndClickExsistEndView(data);

        }
        else {

            const answerTextData = props.answerText ?? '';
            const data: PostQuestionArgs = {
                id: props.question.id,
                choices: props.selectedChoices.map(id => (
                    { choiceId: id }
                )),
                answerText: answerTextData
            }
            props.handleEndClickNoEndView(data);

        }


    }


    //選択肢のナンバリング種別の切り替え
    const switchNumberingType = () => {
        let type = ""
        switch (props.question.choiceNumberingType) {

            case 0: type = "decimal"; break;

            case 1: type = "myKatakana"; break;

            case 2: type = "lower-roman"; break;

            case 3: type = "upper-roman"; break;

            case 4: type = "none"; break;

        }

        return type;
    }

    // 選択肢のナンバリング種別によるスタイルの切り替え
    const getChoiceButtonMargin = () => {
        switch (props.question.choiceNumberingType) {
            case 0: case 1: case 2: case 3:
                return "2.2rem"
            case 4:
                return "0"
            default:
                break;
        }
    }

    //アコーディオンを表示するかどうかを判定
    const isHiddenAccordion = (question: Question) => {
        let flag = false;

        if (question.sectionFlag) flag = true;
        else if (question.parentQuestionId === 0) flag = true;
        else flag = false;

        if (question.parentText === "" && question.parentHtml === null) flag = true;

        return flag;
    }


    //表示するsubjectを返す
    const getSubject = (question: Question) => {
        let subject = "";

        if (question.sectionFlag) subject = question.subjectText;
        else if (question.parentQuestionId === 0) subject = question.subjectText;
        else subject = question.parentText;

        return subject;
    }


    //アコーディオン内に表示するセクション問題本文を返す
    const getSubjectBody = (question: Question) => {
        let subjectBody = "";

        if (question.sectionFlag) subjectBody = question.bodyHtml;
        else if (question.parentQuestionId === 0) subjectBody = question.bodyHtml;
        else subjectBody = question.parentHtml;

        return subjectBody;
    }




    const endButtonText = () => {
        if (props.question.page === props.examValue.pageMax - 2 && props.examValue.endViewFlag) {
            //return ((props.examValue.choiceDeviceFlag || props.isTested) ? "問題の表示を終了する" : "解答終了確認へ")
            return props.isTested ? "問題の表示を終了する" : props.examValue.endViewFlag ? "解答終了確認へ" : "試験を終了する"
        } else {
            return "次へ"
        }
    }

    function hasReadOnlyFlag(exam: Exam, questionId: number) {
        try {
            const examQuestion = exam.questions.find(q => q.id === questionId);
            if (!examQuestion) return false;
            return examQuestion.readonlyFlag;
        } catch (err) {
            return false;
        }
    }

    //-----条件フラグ関係------
    const isFinishedTest = (props.isTested || props.examValue.startStatus === 2);
    //const isReadOnly = hasReadOnlyFlag(props.examValue, props.activeItemKey);
    const isReadOnly = hasReadOnlyFlag(props.examValue, props.question.id);



    //-----スタイルの宣言-------
    const classNames = useStyles();


    //条件式
    //const endBtnText_pc = props.examValue.endViewFlag ? "解答終了確認へ" : "解答を終了する";
    const endBtnText_pc = props.examValue.endViewFlag ? "解答終了確認へ" : "試験を終了する";

    return (


        <div className={classNames.questionRoot}>
            {
                props.isLoading

                    ? <div>Loading...</div>

                    :
                    <div>

                        <Grid container spacing={0} className={classNames.headerContainer} >

                            <Grid item sm={12} md={12} lg={10} >

                                <Accordion hidden={isHiddenAccordion(props.question)} expanded={props.isExpanded} onChange={props.handleExpandChange}>

                                    <AccordionSummary expandIcon={<ExpandMoreIcon style={{ fontSize: "3rem" }} />}>

                                        <Typography id="accordionSubjectText" >{getSubject(props.question) + "..."}</Typography>
                                        <span className={classNames.expandText}>{props.isExpanded ? "閉じる" : "開く"}</span>

                                    </AccordionSummary>

                                    <AccordionDetails>

                                        <RichEditor key={props.question.id} defaultValue={getSubjectBody(props.question)} readOnly={true} />

                                    </AccordionDetails>

                                </Accordion>

                            </Grid>

                        </Grid>

                        <div>

                            <Grid container spacing={0} className={classNames.questionSizeContainer}>

                                <Grid item sm={12} md={12} lg={10} >
                                    <h2 id="questionHeaderNum"><section id={props.question.sectionFlag ? "parentQuestionHeaderNum" : ""}>{props.getQuestionHeadnum(props.question)}</section></h2>
                                    <RichEditor key={props.question.id} defaultValue={props.question.bodyHtml} readOnly={true} />
                                </Grid>


                            </Grid>

                            <Grid item sm={12} md={12} lg={10} >
                                <Box marginBottom={4} marginTop={4} hidden={(props.examValue.choiceDeviceFlag && !props.isTested)} >
                                    {/*<h3 hidden={props.question.sectionFlag} > 解答欄</h3>*/}
                                    <ul className={classNames.choicesListStyle} style={{ listStyleType: switchNumberingType() ,fontSize:"2rem"}}>
                                        {props.question?.sectionFlag === false ?
                                            props.question.answerType > 1 ?
                                                props.question.answerType === 2 ?
                                                    <input type="text" pattern="[0-9]+" inputMode="numeric" maxLength={8}
                                                        disabled={isReadOnly || props.isTested || props.examValue.startStatus === 2}
                                                        value={props.answerText ?? ""}
                                                        onChange={(event) => props.handleTextChange(event.target.value)} />
                                                    :
                                                    <input type="text"
                                                        disabled={isReadOnly || props.isTested || props.examValue.startStatus === 2}
                                                        value={props.answerText ?? ""}
                                                        onChange={(event) => props.handleTextChange(event.target.value)} />
                                                :
                                                props.question?.choices?.sort(orderByAsc).map((choice: Choice) => (
                                                    <div className={classNames.choiceContainer} key={choice.id}>
                                                        <Box>
                                                            {
                                                                props.question.answerType === 0 ?

                                                                    <Radio
                                                                        style={{ paddingBottom: "18px", marginRight: getChoiceButtonMargin() }}
                                                                        disabled={isReadOnly || props.isTested || props.examValue.startStatus === 2}
                                                                        onClick={() => { props.handleChange(choice.id) }}
                                                                        value={choice.id}
                                                                        checked={props.selectedChoices.filter(s => s === choice.id).length > 0}
                                                                        icon={<RadioButtonUncheckedIcon style={{ color: (isReadOnly || props.isTested || props.examValue.startStatus === 2) ? "#bdbdbd" : "#f50057" }} />}
                                                                    />

                                                                    :

                                                                    <Checkbox
                                                                        style={{ paddingBottom: "18px", marginRight: getChoiceButtonMargin() }}
                                                                        disabled={isReadOnly || isFinishedTest}
                                                                        onClick={() => { props.handleChange(choice.id) }}
                                                                        value={choice.id}
                                                                        checked={props.selectedChoices.filter(s => s === choice.id).length > 0}
                                                                        icon={<CheckBoxOutlineBlank style={{ color: (isReadOnly || props.isTested || props.examValue.startStatus === 2) ? "#bdbdbd" : "#f50057" }} />}
                                                                    />

                                                            }
                                                        </Box>
                                                        <li key={choice.id}>
                                                            <div className={classNames.choiceContainer}>
                                                                <Box></Box>
                                                                <div className="choiceTextStyle" onClick={isReadOnly || isFinishedTest ? () => { } : () => { props.handleChange(choice.id) }}>
                                                                    {choice.bodyText}   
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </div>
                                                )) : <div></div>
                                        }
                                    </ul>
                                </Box>
                            </Grid>
                        </div>
                        <Grid container spacing={0} className={classNames.questionSizeContainer}>
                            <Grid item sm={12} md={12} lg={10}>

                                <ButtonContainer className={classNames.buttonContainer} style={{ justifyContent: "space-between" }}>

                                    <DefaultBackButton className="backBtn" style={{ marginRight: "8px", visibility: props.hiddenFlags.return ? "hidden" : "visible" }} onClick={handleReturnClick}><ArrowBackIosIcon /><span>&nbsp;前へ</span></DefaultBackButton>

                                    <DefaultButton hidden={props.hiddenFlags.next} onClick={handleNextClick} ><span>{endButtonText()}&nbsp;</span><span hidden={endButtonText() == "試験を終了する"}><ArrowForwardIosIcon style={{ paddingBottom: "0.2rem" }} /></span></DefaultButton>

                                    {/*<DefaultButton hidden={props.hiddenFlags.end || (!props.isTested && props.examValue.choiceDeviceFlag) } onClick={handleEndClick}>{(props.examValue.choiceDeviceFlag || props.isTested) ? "問題の表示を終了する" : endBtnText_pc }</DefaultButton>*/}
                                    <DefaultButton hidden={props.hiddenFlags.end /*|| (!props.isTested && props.examValue.choiceDeviceFlag)*/} onClick={handleEndClick}>{props.isTested ? "問題の表示を終了する" : endBtnText_pc}</DefaultButton>

                                </ButtonContainer>

                            </Grid>
                        </Grid>


                    </div>}
        </div>

    );
}