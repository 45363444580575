import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { isMobile } from 'react-device-detect';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormHelperText from '@material-ui/core/FormHelperText';

import { useCommon, dateFormat, validatePasswordFunc, alertError } from "../common";
import { APIResponse, reception } from '../react-app-env.d';
import { Layout } from "./Layout";
import { DefaultButton } from './StylesUI/CommonLayouts';
import * as commons from "../common";
import { hiddenFlags } from '../react-app-env';
import { common } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        fontSize: isMobile ? commons.FONT_SIZE.responsive_14px : commons.FONT_SIZE.mainText,
        flexDirection: 'column',
        alignItems: 'center',
        "& h1": {
            fontSize: isMobile ? commons.FONT_SIZE.responsive_14px : commons.FONT_SIZE.mainText,
            marginBottom: "16px"
        },
        "& .description":{
            textAlign:"center"
        }
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    formText: {
        fontSize: isMobile ? commons.FONT_SIZE.responsive_14px : commons.FONT_SIZE.mainText,
    },
    formLabel: {
        fontSize: isMobile ? commons.FONT_SIZE.responsive_12px : commons.FONT_SIZE.formText,
    },
    timeTable: {
        fontSize: isMobile ? commons.FONT_SIZE.responsive_14px : commons.FONT_SIZE.mainText,
    },
    title: {
        marginTop: isMobile ? commons.FONT_SIZE.responsive_24px : 0 ,
        width: "100%",
        textAlign: "center",
        fontSize: isMobile ? commons.FONT_SIZE.responsive_18px :commons.FONT_SIZE.title,
        fontWeight: "bold",
        color: "#00468b"
    },
    validate: {
        fontSize: isMobile ? commons.FONT_SIZE.responsive_14px : commons.FONT_SIZE.mainText,
        color: "red",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "center",
        "& button": {
            width: "240px",
            minWidth: "120px",
            margin: theme.spacing(3, 0, 2),
            fontSize: isMobile ? commons.FONT_SIZE.responsive_14px : commons.FONT_SIZE.mainText,
        },
        "& button:hover": {
            backgroundColor: "#003f71",
            outline:0
        },
        "& button:disabled": {
            backgroundImage: "none",
            backgroundColor: "#cccccc",
        }
    },
    passwordForm: {
        "& .MuiFormHelperText-root.Mui-error": {
            fontSize: commons.FONT_SIZE.formText
        },
        "& .MuiInputLabel-outlined": {
            fontSize: isMobile ? commons.FONT_SIZE.responsive_12px : commons.FONT_SIZE.formText,
        },
        "& .MuiInputLabel-animated": {
            fontSize: isMobile ? commons.FONT_SIZE.responsive_12px : commons.FONT_SIZE.formText,
        },
        "& .MuiSvgIcon-root":{
            fontSize: isMobile ? commons.FONT_SIZE.responsive_14px : "1.5rem"
        }
    },
    acceptErrorText: {
        marginTop: isMobile ? commons.FONT_SIZE.responsive_24px : 0 ,
        fontSize: isMobile ? commons.FONT_SIZE.responsive_14px : commons.FONT_SIZE.mainText,
        color: "#00468b",
        textAlign: "center"
    },
    notShowEye: {
        "& input[type=password]::-ms-reveal": {
            display: "none",
            width: 0,
            height: 0
        },
        "& input[type=password]::-ms-clear": {
            display: "none",
            width: 0,
            height: 0
        }
    }
}));

//---interface----
interface validatePasswordValue {
    flag: boolean;
    message: string;
}

export default function Reception() {
    const classes = useStyles();
    const common = useCommon();


    //----共通関数の定義-------
    const {
        api  // API呼び出し
    } = common;


    //----汎用関数--------
    const validatePassword = (password: string) => {
        const { correctFlag, errorMessage } = validatePasswordFunc(password);
        let errorFlag = !correctFlag;
        setIsError({ flag: errorFlag, message: errorMessage });
    }

    // 認証コードを取得
    const code = common.queryParams?.get("code") ?? "";

    const [state, setState] = React.useState({
        codeIsValid: false,
        view: "setpass" as "setpass" | "confirm",
        reception: null as null | reception
    });

    const [isError, setIsError] = React.useState({ flag: false, message: "" });
    const [isBeforeAcceptTime, setIsBeforeAcceptTerm] = React.useState(false);
    const [isEndedAcceptTime, setIsEndedAcceptTime] = React.useState(false);
    const [examName, setExamName] = React.useState("");

    React.useEffect(() => {
        common.api("api/l-reception", "GET", {
            code: code
        }).then((res: APIResponse<reception>) => {
            // decodeされて返ってきた受験者IDを保存する
            if (typeof (res?.value?.id) === "string" && res.value.id.length > 0) {
                sessionStorage["reception-code-user-name"] = res.value.id;
                setExamName(res?.value?.examName);
            }
            switch (res.errorCode) {
                case 20000:
                    // 成功
                    setState({ ...state, codeIsValid: true, view: "setpass" });
                    break;
                case 20800:
                    // 受付済み。home にリダイレクト
                    common.go("/");
                    break;
                case 40100:
                    // 検証失敗
                    alert(commons.ResponseMessages.Error_GetReception_BadCode);
                    break;
                case 40101:
                    alert(commons.ResponseMessages.Error_GetReception_Expired);
                    break;
                case 40106:
                    alert("受付コードと別の試験の受験者IDでログイン済みです。いったんログアウトします。");
                    common.logout();
                    break;
                default:
                    console.error(res);
                    if (res.errorCode === 40104) {
                        setIsBeforeAcceptTerm(true)
                    }
                    else if (res.errorCode === 40105) {
                        setIsEndedAcceptTime(true)
                    }
                    else if (res.errorDetail) {
                        alertError(res.errorTitle, res.errorDetail);
                    }
                    // // test
                    // setState({ ...state, view: "confirm", reception: res.value });
                    break;
            }
        }).catch((err) => {
            console.error(err);
            alert("サーバーとの通信でエラーが発生しました。");
        });
    }, [code]);

    return (
        <Layout viewType="auto" width={isMobile ? "85%" : "780px"} isMobileReceptView={isMobile}>

            { (isEndedAcceptTime || isBeforeAcceptTime)

                ?

                <Grid container spacing={1} justify="center">
                    <Grid item className={classes.acceptErrorText} >
                        {isBeforeAcceptTime ? <span>受付期間前です。<br />受付期間内に再度接続して下さい。</span>
                            : "受付期間は終了しています。"}
                    </Grid>
                </Grid>

                :


                <Container component="main" >
                    <CssBaseline />
                    <h1 className={classes.title}>{examName} 受付</h1>
                    <SetPasswordForm
                        hidden={state.view !== "setpass"}
                        code={code}
                        disabled={!state.codeIsValid}
                        onSetPass={(reception) => {
                            setState({ ...state, view: "confirm", reception: reception });
                        }}
                        validatePassword={validatePassword}
                        isError={isError}
                    />
                    <ConfirmForm
                        hidden={state.view !== "confirm"}
                        onConfirm={() => {
                            common.go("/");
                        }}
                        reception={state.reception}
                    />
                </Container>

            }
        </Layout>);
}

function ConfirmForm(props: {
    hidden: boolean;
    onConfirm: () => void;
    reception: null | reception;
}) {
    const classes = useStyles();
    return (
        <div className={classes.paper} hidden={props.hidden}>
            <Grid container spacing={1} justify="center" style={{ marginBottom: "16px" }}>
                <Grid item>
                    パスワードが設定されました。
                </Grid>
                <Grid item>
                    試験期間中に試験サイトへアクセスし、試験を開始してください。
                </Grid>
                <Grid item>
                    ※パスワードは忘れないよう記録してください。
                </Grid>
            </Grid>

            <Grid container className={classes.timeTable} justify="center" spacing={3}>
                <Grid item>
                    試験開始時間
                </Grid>
                <Grid item>
                    {dateFormat(props.reception?.executeStart ?? "", "lll")}
                </Grid>
            </Grid>

            <Grid container className={classes.timeTable} justify="center" spacing={3}>
                <Grid item>
                    試験終了時間
                </Grid>
                <Grid item>
                    {dateFormat(props.reception?.executeEnd ?? "", "lll")}
                </Grid>
            </Grid>

            <div className={classes.buttonContainer}>
                <DefaultButton
                    onClick={(e) => {
                        e.preventDefault();
                        props.onConfirm();
                    }}>
                    確認
                </DefaultButton>
            </div>
            {/*<Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={(e) => {
                    e.preventDefault();
                    props.onConfirm();
                }}
            >確認</Button>*/}
        </div>
    );
}

function SetPasswordForm(props: {
    hidden: boolean;
    code: string;
    disabled: boolean;
    onSetPass: (reception: reception) => void;
    validatePassword: any;
    isError: validatePasswordValue
}) {
    const classes = useStyles();
    const common = useCommon();
    const [state, setState] = React.useState({
        password: "",
        password_confirm: ""
    });
    const code = props.code;
    const [showPassword, setShowPassword] = React.useState(false)

    const onSubmitHandler = React.useCallback((e: React.FormEvent) => {
        e.preventDefault();
        if (state.password !== state.password_confirm) {
            alert("確認用のパスワードが一致しませんでした。")
            return;
        }
        common.api("api/l-reception", "PUT", {
            code: code,
            password: state.password,
            encUserName: commons.encodeBase64(sessionStorage["reception-code-user-name"])
        }).then((res: APIResponse<reception>) => {
            switch (res.errorCode) {
                case 20000:
                    // 成功
                    props.onSetPass(res.value);
                    break;
                case 20800:
                    // 受付済み。
                    alert(commons.ResponseMessages.Error_GetReception_Accepted);
                    props.onSetPass(res.value);
                    break;
                case 40000:
                    // パスワード設定失敗
                    alert(commons.ResponseMessages.Error_GetReception_InValidPassword);
                    break;
                case 40100:
                    // 検証失敗
                    alert(commons.ResponseMessages.Error_GetReception_BadCode);
                    break;
                case 40101:
                    alert(commons.ResponseMessages.Error_GetReception_Expired);
                    break;
                default:
                    console.error(res);
                    if (res.errorDetail) {
                        alertError(res.errorTitle, res.errorDetail);
                    }
                    break;
            }
        }).catch((err) => {
            console.error(err);
            alert("サーバーとの通信でエラーが発生しました。");
        });
    }, [props, code, state, common]);

    const handleInput = (password: string) => {
        setState({ ...state, password: password })
        props.validatePassword(password);
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };


    return (
        <div className={classes.paper} hidden={props.hidden}>
            <Typography component="h1" variant="h5" className="description">
                試験サイトログイン用のパスワードを設定してください<br/>
                パスワードは「最小6文字」で入力してください
            </Typography>

            <Grid container spacing={1}>
                <Grid item xs={12} className={classes.passwordForm}>
                    <form className={classes.form} onSubmit={onSubmitHandler}>
                        <InputLabel htmlFor="outlined-adornment-password">パスワード</InputLabel>
                        <OutlinedInput
                            disabled={props.disabled}
                            required
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            value={state.password}
                            onChange={(e) => { handleInput(e.target.value) }}
                            error={props.isError.flag}
                            autoComplete="current-password"
                            label="パスワード"
                            fullWidth
                            classes={{ input: classes.formText, root: classes.formLabel }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            labelWidth={100}
                            className={classes.notShowEye}
                        />
                        <FormHelperText error={props.isError.flag}>{props.isError.message.split("\n").map((line, key) => <span key={key}>{line}<br /></span>)}</FormHelperText>
                    </form>
                </Grid>
                <Grid item xs={12} className={classes.passwordForm}>
                    <form className={classes.form} onSubmit={onSubmitHandler}>
                        <InputLabel htmlFor="outlined-adornment-password">パスワード（確認用）</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            disabled={props.disabled}
                            error={state.password !== state.password_confirm}
                            required
                            fullWidth
                            name="password_confirm"
                            label="パスワード（確認用）"
                            autoComplete="current-password"
                            onChange={(e) => { setState({ ...state, password_confirm: e.target.value }); }}
                            type={showPassword ? 'text' : 'password'}
                            classes={{ input: classes.formText, root: classes.formLabel }}
                            labelWidth={100}
                            className={classes.notShowEye}
                        />
                        <FormHelperText error={state.password !== state.password_confirm} hidden={state.password === state.password_confirm}>{<span> パスワードと確認用パスワードが一致しません </span>}</FormHelperText>
                    </form>
                </Grid>
            </Grid>
            <div className={classes.buttonContainer}>
                <DefaultButton
                    disabled={props.disabled || props.isError.flag}
                    onClick={onSubmitHandler}>
                    登録
                </DefaultButton>
            </div>

        </div>
    );
}