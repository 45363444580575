import AppBar from '@material-ui/core/AppBar/AppBar';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import MediaQuery from "react-responsive";
import React from 'react';
import Box from '@material-ui/core/Box';
import {
  CustomView,
  isMobile
} from "react-device-detect";
import { Link as RouterLink } from 'react-router-dom';
import { AppContext } from '../App';
import { getUser } from '../common';
import { UserData } from '../react-app-env';
import { LoginMenu } from './api-authorization/LoginMenu';
import * as common from "../common";
import * as Cookies from "js-cookie";
import { Style } from 'util';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';

const useStyles = makeStyles((theme: Theme) =>
({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  centerPlacement: {
    margin: "0 auto"
  },
  examName: {
    padding: "16px 40px 16px",
    fontSize: "1.8rem",
    fontFamily: "'-apple-system', 'BlinkMacSystemFont', 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    color: "white"
  },
  examNameMobile: {
    paddingTop: "6.9vw",
    paddingBottom: "6.9vw",
    paddingLeft: "4.2vw",
    verticalAlign: "middle",
    fontSize: common.FONT_SIZE.responsive_18px,
    fontWeight: "bold",
    fontFamily: "Georgia, 'Times New Roman', serif;",
    color: "white",
    height: "18vw",
    minHeight: "70px",
    //width:"90%",
    whiteSpace: "pre-wrap",
    overflowWrap: "break-word",
    "& a:hover, & a:visited": {
      color: "white",
      textDecoration: "none"
    }
  },
  mobileMain: {
    "& .mobileLoadingText": {
      fontSize: common.FONT_SIZE.responsive_18px,
      fontWeight: "bold",
      margin: "5.3vw 0 0 5.3vw"
    }
  },
  pcMain: {
    "& .mobileLoadingText": {
      fontSize: common.FONT_SIZE.title,
      fontWeight: "bold",
      margin: "2rem 0 0 2rem"
    }
  },
  mobileReceptionTitle: {
    paddingTop: "6.9vw",
    paddingBottom: "6.9vw",
    paddingLeft: "4.2vw",
    verticalAlign: "middle",
    fontWeight: "bold",
    fontFamily: "Georgia, 'Times New Roman', serif",
    color: "white",
    fontSize: common.FONT_SIZE.responsive_18px,
    height: "18vw",
    minHeight: "70px",
    }
})
);

export function Layout(props: { mainStyle?: CSSProperties, viewType: "auto" | "pc" | "mobile", children: any, width?: "auto" | string, headerRemainingTime?: any, headerSlider?: any, isMobileReceptView?: boolean, isTutorial?: boolean, allowResync?: boolean, handleResync?: any, fullScreen?: any }) {
  const classes = useStyles();
  const { appContext, setAppContext } = React.useContext(AppContext);

  const isMobileReceptView = props.isMobileReceptView === undefined ? false : props.isMobileReceptView;
  const isTutorial = props.isTutorial === undefined ? false : props.isTutorial;
  const allowResync = props.allowResync === undefined ? false : props.allowResync;
  const handleResync = props.handleResync === undefined ? {} : props.handleResync;

  const viewType = React.useMemo(() => {
    if (props.viewType === "auto") {
      return isMobile ? "mobile" : "pc";
    } else {
      return props.viewType;
    }
  }, [props.viewType]);

  const examName = appContext.examName ?? "";

  const cmn = common.useCommon();

  React.useEffect(() => {

    getUser().then((user: UserData) => {

      if (user) {

        setAppContext(c => ({
          // 試験名設定
          examName: user.executionName,
          // ログインしてたら必ずfake mode 解除
          fakeapi_mode: false,
          fake_data: undefined,
          fake_synctoken: undefined
        }));

        // 機器適合チェックID更新
        const checkid = Cookies.get("AKOConformanceId");
        if (typeof (checkid) === "string" && checkid.length > 1) {
          cmn.backgroud_api("/api/l-devicecheck", "PUT", {
            deviceCheckId: checkid
          }).catch((err) => console.error(err));
        }

      }
    });
  }, [setAppContext]);


  let mainStyle = {};
  let questionFlag = false;
  switch (props.width) {
    case undefined:
      mainStyle = {};
      break;
    case "auto":
      mainStyle = {};
      break;
    case "question":
      mainStyle = { margin: "70px auto 0px", width: "100%", height: "100%" };
      questionFlag = true;
      break;
    default:
      mainStyle = { margin: "140px auto", width: props.width, minWidth: "900px" }
      break;
  }

  const parentContainer = (viewType === "mobile" || props.width === "question") ? { height: "100%" } : {}
  const pcQuestionContainer = props.width === "question" ? { height: "100%", backgroundColor: "white" } : { backgroundColor: "#e1e4e8", paddingBottom: "10px" }
  const pcMainQuestion = { margin: "70px auto 0px", width: "100%", minWidth: "900px" };

  const isExsistRemainingTime = props.headerRemainingTime === undefined ? false : true;
  const isExsistSlider = props.headerSlider === undefined ? false : true;


  return (
    <div style={parentContainer}>
      <CustomView style={pcQuestionContainer} condition={viewType === "pc"} >
        <AppBar position="static" style={{ minWidth: "900px", position: "sticky", top: 0, height: "70px" }}>
          <Toolbar>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <div id="siteTitle">
                  <Link component={RouterLink} color="inherit" to="/" >
                    {common.APP_DISP_NAME}
                  </Link>
                </div>
              </Grid>

              <MediaQuery query="(min-width: 901px)">
                <Grid item>
                  <LoginMenu isMobile={false} shouldShowUserName={!isTutorial} isTutorial={isTutorial} allowResync={allowResync} handleResync={handleResync} />
                </Grid>
              </MediaQuery>
              <MediaQuery query="(max-width: 900px)">
                <Grid item>
                  <LoginMenu isMobile={false} shouldShowUserName={false} isTutorial={isTutorial} allowResync={allowResync} handleResync={handleResync} />
                </Grid>
              </MediaQuery>

            </Grid>
          </Toolbar>

          <Toolbar style={{ background: "linear-gradient(to right, rgba(0,98,197,1) 0%,rgba(94,167,219,1) 100%)" }}>
            <Grid container text-align="center" justify="space-between" alignItems="center">
              <Grid item className={classes.examName} hidden={isTutorial}>
                {examName}
              </Grid>
              <Grid item >
                <Grid container>
                    <Grid item hidden={!isExsistRemainingTime} style={{ marginRight: "10px" }}>
                        {props.headerRemainingTime}
                    </Grid>
                    <Grid>
                        {props.fullScreen}
                    </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
          {isExsistSlider ? props.headerSlider : <div></div>}
        </AppBar>


        {/* メイン */}
        {questionFlag ?
          <div style={pcMainQuestion}>
            {props.children}
          </div>
          :
          <div id="main" className={classes.pcMain}
            style={{
              ...mainStyle,
              ...props.mainStyle
            }}>
            <div id="mainCon">
              {props.children}
            </div>
          </div>
        }

      </CustomView>
      <CustomView condition={viewType === "mobile"} style={{ backgroundColor: "white", height: "100%" }}>
        <Container style={{ padding: 0 }}>
          <AppBar position="static" style={{ position: "sticky", top: 0 }}>

            <Toolbar>
              <Grid container justify="space-between" alignItems="center" hidden={!isMobileReceptView} >
                <Grid item className={classes.mobileReceptionTitle}>
                  <div >
                    <Link component={RouterLink} color="inherit" to="/" >
                      {common.APP_DISP_NAME}
                    </Link>
                  </div>
                </Grid>
                <Grid item>
                  <LoginMenu isMobile={true} shouldShowUserName={!isTutorial} isTutorial={isTutorial} />
                </Grid>
              </Grid>
              <Grid container justify="space-between" wrap="nowrap" alignItems="center" hidden={isMobileReceptView}>
                <Grid item style={{ maxWidth: "80%" }} >
                  <Box display="flex" alignItems="center" className={classes.examNameMobile}>
                    <Link component={RouterLink} color="inherit" to="/" style={{ fontSize: examName.length > 79 ? common.FONT_SIZE.responsive_12px : common.FONT_SIZE.responsive_18px }}>
                      {isTutorial ? common.APP_DISP_NAME : examName}
                    </Link>
                  </Box>
                </Grid>
                <Grid item>
                  <LoginMenu isMobile={true} shouldShowUserName={!isTutorial} isTutorial={isTutorial} />
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          {/* メイン */}
          <div className={classes.mobileMain} >
            {props.children}
          </div>
        </Container>
      </CustomView>
      {/* 処理中表示用オーバーレイ */}
      <Backdrop className={classes.backdrop} open={appContext.backdropIsopen} >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
